import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ArrowRightBorder: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    sx={{
      ...sx,
      width: '24px',
      height: '24px',
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99935 3.33341C8.37249 3.33341 6.87282 3.46759 5.6691 3.62656C4.58579 3.76963 3.7689 4.58652 3.62583 5.66984C3.46685 6.87355 3.33268 8.37322 3.33268 10.0001C3.33268 11.6269 3.46685 13.1266 3.62583 14.3303C3.7689 15.4136 4.58579 16.2305 5.6691 16.3736C6.87282 16.5326 8.37249 16.6667 9.99935 16.6667C11.6262 16.6667 13.1259 16.5326 14.3296 16.3736C15.4129 16.2305 16.2298 15.4136 16.3729 14.3303C16.5318 13.1266 16.666 11.6269 16.666 10.0001C16.666 8.37322 16.5318 6.87355 16.3729 5.66984C16.2298 4.58652 15.4129 3.76963 14.3296 3.62656C13.1259 3.46759 11.6262 3.33341 9.99935 3.33341ZM5.45088 1.97424C3.62022 2.21602 2.21529 3.62095 1.97351 5.45161C1.80729 6.71015 1.66602 8.28448 1.66602 10.0001C1.66602 11.7157 1.80729 13.29 1.97351 14.5485C2.21529 16.3792 3.62022 17.7841 5.45088 18.0259C6.70942 18.1921 8.28374 18.3334 9.99935 18.3334C11.715 18.3334 13.2893 18.1921 14.5478 18.0259C16.3785 17.7841 17.7834 16.3792 18.0252 14.5485C18.1914 13.29 18.3327 11.7157 18.3327 10.0001C18.3327 8.28448 18.1914 6.71015 18.0252 5.45161C17.7834 3.62095 16.3785 2.21602 14.5478 1.97424C13.2893 1.80803 11.715 1.66675 9.99935 1.66675C8.28374 1.66675 6.70942 1.80803 5.45088 1.97424Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.16074 6.49408C7.83531 6.81951 7.83531 7.34715 8.16074 7.67259L10.4882 10L8.16074 12.3274C7.83531 12.6528 7.83531 13.1805 8.16074 13.5059C8.48618 13.8314 9.01382 13.8314 9.33926 13.5059L12.2559 10.5893C12.5814 10.2638 12.5814 9.73618 12.2559 9.41074L9.33926 6.49408C9.01382 6.16864 8.48618 6.16864 8.16074 6.49408Z"
      fill="currentColor"
    />
  </SvgIcon>
);
