import { YOUTUBE_OR_VIMEO_REGEX } from 'appConstants/validators';
import { socialsValidation } from 'modules/layout/containers';
import {
  COLOR_BG,
  COLOR_DARK_PURPLE,
  COLOR_GOLD,
  COLOR_GREEN_DARK,
  COLOR_GREEN_DEFAULT,
  COLOR_NEUTRALS_1,
  COLOR_NEUTRALS_4,
  COLOR_NEUTRALS_5,
  COLOR_NEUTRALS_8,
  COLOR_PRIMARY_2,
  COLOR_SECONDARY_3,
} from 'theme/colors';
import * as yup from 'yup';

export interface IEditGameFormInputs {
  avatar: File | null;
  name?: string;
  email?: string;
  backgroundColor?: string;
  description?: string;

  site?: string;
  twitter?: string;
  telegram?: string;
  instagram?: string;
  discord?: string;
  medium?: string;

  photos?: Array<{ url: string; display: boolean | null }>;

  // urls
  video1?: string;
  video2?: string;
  video3?: string;
  // base64 data urls
  videoCover1?: string;
  videoCover2?: string;
  videoCover3?: string;

  developer?: string;
  releaseStatus?: string;
  platform?: string;
  genre?: string;
}

export const gameValidationSchema = socialsValidation.concat(
  yup.object().shape({
    name: yup.string().min(1).max(50).required(),
    email: yup.string().email().max(50),
    backgroundColor: yup.string(),
    description: yup.string().min(0).max(500),

    // photos
    video1: yup
      .string()
      .test(
        'emptyOrMatches',
        'Must be a YouTube or Vimeo video link',
        (value) => !value || YOUTUBE_OR_VIMEO_REGEX.test(value),
      )
      .test(
        'requiredWhenCover',
        'Link is required for cover',
        (value, context) => !(context.parent.videoCover1 && !value),
      ),
    video2: yup
      .string()
      .test(
        'emptyOrMatches',
        'Must be a YouTube or Vimeo video link',
        (value) => !value || YOUTUBE_OR_VIMEO_REGEX.test(value),
      )
      .test(
        'requiredWhenCover',
        'Link is required for cover',
        (value, context) => !(context.parent.videoCover2 && !value),
      ),
    video3: yup
      .string()
      .test(
        'emptyOrMatches',
        'Must be a YouTube or Vimeo video link',
        (value) => !value || YOUTUBE_OR_VIMEO_REGEX.test(value),
      )
      .test(
        'requiredWhenCover',
        'Link is required for cover',
        (value, context) => !(context.parent.videoCover3 && !value),
      ),

    // covers
    videoCover1: yup
      .string()
      .test('requiredWhenVideo', 'Cover is required for video', (value, context) => !(context.parent.video1 && !value)),
    videoCover2: yup
      .string()
      .test('requiredWhenVideo', 'Cover is required for video', (value, context) => !(context.parent.video2 && !value)),
    videoCover3: yup
      .string()
      .test('requiredWhenVideo', 'Cover is required for video', (value, context) => !(context.parent.video3 && !value)),

    developer: yup.string().min(0).max(64),
    releaseStatus: yup.string().min(0).max(64),
    platform: yup.string().min(0).max(64),
    genre: yup.string().min(0).max(64),
  }),
);

export const gameOverviewFields: { name: keyof IEditGameFormInputs; label: string; placeholder: string }[] = [
  {
    name: 'developer',
    label: 'Developer',
    placeholder: 'BLIZZARD',
  },
  {
    name: 'releaseStatus',
    label: 'Release Status',
    placeholder: 'In development',
  },
  {
    name: 'platform',
    label: 'Platform',
    placeholder: 'Desktop',
  },
  {
    name: 'genre',
    label: 'Genre',
    placeholder: 'First person shooter',
  },
];

export const videoFields: {
  name: keyof IEditGameFormInputs;
  placeholder: string;
  coverName: keyof IEditGameFormInputs;
}[] = [
  {
    name: 'video1',
    placeholder: 'https://www.youtube.com/eu3984y93hr9...',
    coverName: 'videoCover1',
  },
  {
    name: 'video2',
    placeholder: 'https://vimeo.com/791855851',
    coverName: 'videoCover2',
  },
  {
    name: 'video3',
    placeholder: 'https://www.youtube.com/',
    coverName: 'videoCover3',
  },
];

export const backgroundColorsVariants = [
  { value: COLOR_NEUTRALS_8, label: 'White' },
  { value: COLOR_SECONDARY_3, label: 'Yellow' },
  { value: COLOR_PRIMARY_2, label: 'Purple' },
  { value: COLOR_NEUTRALS_1, label: 'Deep Purple' },
  { value: COLOR_GREEN_DARK, label: 'Green' },
  { value: COLOR_BG, label: 'Dark' },
];

export const backgroundCardColor = {
  [COLOR_NEUTRALS_8]: COLOR_NEUTRALS_5,
  [COLOR_SECONDARY_3]: COLOR_GOLD,
  [COLOR_PRIMARY_2]: COLOR_DARK_PURPLE,
  [COLOR_NEUTRALS_1]: COLOR_NEUTRALS_4,
  [COLOR_GREEN_DARK]: COLOR_GREEN_DEFAULT,
  [COLOR_BG]: COLOR_BG,
};
