import { baseApi } from 'store/api/apiRequestBuilder';
import { wrap } from 'store/utils';
import { call, put, takeLatest } from 'typed-redux-saga';

import { setCollectionCover } from '../actions';
import actionTypes from '../actionTypes';
import { updateSingleCollection } from '../reducer';

export function* setCollectionCoverSaga({ payload }: { payload: ReturnType<typeof setCollectionCover>['payload'] }) {
  const { data: cover } = yield* call(baseApi.setCollectionCover, payload);
  yield* put(updateSingleCollection({ cover }));
}

export function* watchSetCollectionCover() {
  yield* takeLatest(actionTypes.SET_COLLECTION_COVER, wrap(setCollectionCoverSaga));
}
