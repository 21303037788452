import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Edit: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 16 16"
    sx={{
      width: '16px',
      height: '16px',
      ...sx,
    }}
  >
    <path
      d="M2 14.6667C2 14.2985 2.29848 14 2.66667 14H13.3333C13.7015 14 14 14.2985 14 14.6667C14 15.0349 13.7015 15.3333 13.3333 15.3333H2.66667C2.29848 15.3333 2 15.0349 2 14.6667Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1381 2.08084C10.8777 1.82049 10.4556 1.82049 10.1953 2.08084L9.6095 2.66661L12 5.05713L12.5858 4.47137C12.8461 4.21102 12.8461 3.78891 12.5858 3.52856L11.1381 2.08084ZM4.6095 7.66661L8.66669 3.60942L11.0572 5.99994L7.00002 10.0571L4.6095 7.66661ZM3.66669 8.60942L3.33333 8.94277V11.3333H5.72386L6.05721 10.9999L3.66669 8.60942ZM9.25245 1.13803C10.0335 0.356984 11.2998 0.356984 12.0809 1.13803L13.5286 2.58575C14.3096 3.3668 14.3096 4.63313 13.5286 5.41418L6.66667 12.2761C6.41662 12.5262 6.07748 12.6666 5.72386 12.6666H3.33333C2.59695 12.6666 2 12.0697 2 11.3333V8.94277C2 8.58915 2.14048 8.25001 2.39052 7.99996L9.25245 1.13803Z"
      fill="currentColor"
    />
  </SvgIcon>
);
