import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const User: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 20"
    sx={{
      ...sx,
      width: '20px',
      height: '20px',
    }}
  >
    <g clipPath="url(#clip0_1269_3242)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 10.8344C6.77834 10.8344 4.16667 13.4461 4.16667 16.6677V18.3344C4.16667 18.7946 3.79357 19.1677 3.33333 19.1677C2.8731 19.1677 2.5 18.7946 2.5 18.3344V16.6677C2.5 12.5256 5.85786 9.16772 10 9.16772C14.1421 9.16772 17.5 12.5256 17.5 16.6677V18.3344C17.5 18.7946 17.1269 19.1677 16.6667 19.1677C16.2064 19.1677 15.8333 18.7946 15.8333 18.3344V16.6677C15.8333 13.4461 13.2217 10.8344 10 10.8344Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 9.16756C11.8409 9.16756 13.3333 7.67518 13.3333 5.83423C13.3333 3.99328 11.8409 2.5009 10 2.5009C8.15905 2.5009 6.66667 3.99328 6.66667 5.83423C6.66667 7.67518 8.15905 9.16756 10 9.16756ZM10 10.8342C12.7614 10.8342 15 8.59565 15 5.83423C15 3.0728 12.7614 0.834229 10 0.834229C7.23858 0.834229 5 3.0728 5 5.83423C5 8.59565 7.23858 10.8342 10 10.8342Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1269_3242">
        <rect width="20" height="20" fill="white" transform="translate(0 0.000976562)" />
      </clipPath>
    </defs>
  </SvgIcon>
);
