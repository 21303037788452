import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Share: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 14 14"
    sx={{
      ...sx,
      width: '14px',
      height: '14px',
    }}
  >
    <path
      d="M2.19156e-05 7.00811C-0.0059318 5.51219 1.20193 4.29469 2.69787 4.28873C3.38681 4.286 4.05092 4.54589 4.55499 5.01552L8.59142 3.19306C8.56337 3.04214 8.54799 2.88917 8.5454 2.73569C8.54065 1.22945 9.7578 0.00457096 11.264 -0.000207901C12.7703 -0.00498772 13.9951 1.21219 13.9999 2.71843C14.0047 4.22466 12.7875 5.44954 11.2813 5.45432C10.3814 5.45716 9.538 5.01591 9.02724 4.275L5.23492 5.98734C5.50428 6.63777 5.50532 7.36836 5.23784 8.01955L9.02492 9.74064C9.87769 8.50727 11.5688 8.19877 12.8022 9.05154C14.0356 9.90431 14.3441 11.5954 13.4913 12.8288C12.6385 14.0622 10.9474 14.3707 9.71399 13.5179C8.97948 13.01 8.54166 12.1735 8.54305 11.2805C8.54551 11.1268 8.5611 10.9736 8.58967 10.8226L4.56373 8.99312C3.47297 10.0174 1.75844 9.96341 0.734213 8.87265C0.260183 8.36779 -0.00254528 7.70062 2.19156e-05 7.00811Z"
      fill="currentColor"
    />
  </SvgIcon>
);
