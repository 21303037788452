import { FC } from 'react';
import { SvgIconProps } from '@mui/material';

import {
  Add,
  AddDouble,
  AddTripple,
  ArrowDown,
  ArrowLeft,
  ArrowRightBorder,
  Backward,
  Bell,
  Bid,
  Bomb,
  Checkbox,
  CheckIcon,
  Chrome,
  Clock,
  Close,
  // Collection,
  Compass,
  Copy,
  Cross,
  CrossInRound,
  CrossInRoundThin,
  Cup,
  // Dollar,
  Donut,
  Doughnut,
  Download,
  DownloadCloud,
  Eth,
  Eye,
  Facebook,
  FacebookRounded,
  FastBackward,
  Filter,
  Fire,
  GalleryOneMount,
  GalleryTwoMount,
  Gaming,
  Heart,
  Help,
  Instagram,
  LightBulb,
  Lightning,
  Link,
  LinkedIn,
  Loading,
  LoadingSimple,
  Logo,
  Logout,
  Loop,
  MinusRounded,
  Mounts,
  Nerd,
  Note,
  Pause,
  Pinterest,
  Play,
  PlayForward,
  PlusIcon,
  Previous,
  Radio,
  Record,
  Refresh,
  Repair,
  Rocket,
  SelectArrowDown,
  Share,
  SingleNote,
  Slippers,
  Stop,
  Tent,
  ThreeDots,
  Twitter,
  Usdt,
  User,
  UserAdd,
  VolumeMute,
  Warning,
  Weth,
} from './components';

/* eslint-disable @typescript-eslint/no-shadow */
export enum IconKey {
  ArrowDown = 'ArrowDown',
  Copy = 'Copy',
  Close = 'Close',
  CheckIcon = 'CheckIcon',
  SelectArrowDown = 'SelectArrowDown',
  ArrowRightBorder = 'ArrowRightBorder',
  ArrowLeft = 'ArrowLeft',
  PlusIcon = 'PlusIcon',
  Add = 'Add',
  AddDouble = 'AddDouble',
  AddTripple = 'AddTripple',
  Bell = 'Bell',
  Bid = 'Bid',
  Bomb = 'Bomb',
  Chrome = 'Chrome',
  Clock = 'Clock',
  Compass = 'Compass',
  Cross = 'Cross',
  CrossInRound = 'CrossInRound',
  CrossInRoundThin = 'CrossInRoundThin',
  Cup = 'Cup',
  Doughnut = 'Doughnut',
  Donut = 'Donut',
  Download = 'Download',
  DownloadCloud = 'DownloadCloud',
  Facebook = 'Facebook',
  FacebookRounded = 'FacebookRounded',
  Filter = 'Filter',
  Fire = 'Fire',
  GalleryOneMount = 'GalleryOneMount',
  GalleryTwoMount = 'GalleryTwoMount',
  Gaming = 'Gaming',
  Heart = 'Heart',
  Help = 'Help',
  Instagram = 'Instagram',
  LightBulb = 'LightBulb',
  Lightning = 'Lightning',
  Link = 'Link',
  LinkedIn = 'LinkedIn',
  Loading = 'Loading',
  LoadingSimple = 'LoadingSimple',
  Logout = 'Logout',
  Loop = 'Loop',
  MinusRounded = 'MinusRounded',
  Mounts = 'Mounts',
  Nerd = 'Nerd',
  Pinterest = 'Pinterest',
  Refresh = 'Refresh',
  Repair = 'Repair',
  Share = 'Share',
  Slippers = 'Slippers',
  Tent = 'Tent',
  Twitter = 'Twitter',
  User = 'User',
  Warning = 'Warning',
  Checkbox = 'Checkbox',
  Backward = 'Backward',
  FastBackward = 'FastBackward',
  Note = 'Note',
  Pause = 'Pause',
  Play = 'Play',
  PlayForward = 'PlayForward',
  Previous = 'Previous',
  Record = 'Record',
  SingleNote = 'SingleNote',
  Stop = 'Stop',
  VolumeMute = 'VolumeMute',
  Logo = 'Logo',
  Eth = 'Eth',
  Usdt = 'Usdt',
  Weth = 'Weth',
  UserAdd = 'UserAdd',
  Radio = 'Radio',
  ThreeDots = 'ThreeDots',
  Rocket = 'Rocket',
  Eye = 'Eye',
  // Dollar = 'Dollar',
  // ShoppingTagIcon = 'ShoppingTagIcon',
}

/* eslint-enable @typescript-eslint/no-shadow */
export const Icon: Record<IconKey, FC<SvgIconProps>> = {
  Add,
  AddDouble,
  AddTripple,
  ArrowDown,
  ArrowRightBorder,
  ArrowLeft,
  Bell,
  Bid,
  Bomb,
  CheckIcon,
  Chrome,
  Clock,
  Close,
  Compass,
  Copy,
  Cross,
  CrossInRound,
  CrossInRoundThin,
  Cup,
  Doughnut,
  Donut,
  Download,
  DownloadCloud,
  Facebook,
  FacebookRounded,
  Filter,
  Fire,
  GalleryOneMount,
  GalleryTwoMount,
  Gaming,
  Heart,
  Help,
  Instagram,
  LightBulb,
  Lightning,
  Link,
  LinkedIn,
  Loading,
  LoadingSimple,
  Logout,
  Loop,
  MinusRounded,
  Mounts,
  Nerd,
  Pinterest,
  PlusIcon,
  Refresh,
  Repair,
  SelectArrowDown,
  Share,
  Slippers,
  Tent,
  Twitter,
  User,
  Warning,
  Backward,
  FastBackward,
  Note,
  Pause,
  Play,
  PlayForward,
  Previous,
  Record,
  SingleNote,
  Stop,
  VolumeMute,
  Checkbox,
  Eth,
  Usdt,
  Weth,
  Logo,
  UserAdd,
  Radio,
  ThreeDots,
  Rocket,
  Eye,
  // ShoppingTagIcon,
  // Dollar,
};
