import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { Plus } from 'components/Icon/components/Plus';
import { imagesTypes } from 'modules/layout/containers/FileUploader';
import { COLOR_GRADIENT_ACCENT, COLOR_NEUTRALS_7, COLOR_PRIMARY_3 } from 'theme/colors';
import { getEncodeMedia } from 'utils';

export interface VideoInputProps {
  value?: string;
  error?: string;
  onChange?: (value: string) => void;
}

export const VideoInput = ({ value, error, onChange }: VideoInputProps) => {
  const theme = useTheme();

  const handleDrop = useCallback(
    async (acceptedFiles) => {
      if (!acceptedFiles.length) {
        // Error handling is based on fileRejections from useDropzone
        return;
      }
      const currentFile = acceptedFiles[0];
      const dataUrl = await getEncodeMedia(currentFile);
      onChange?.(dataUrl);
    },
    [onChange],
  );

  const handleClear = useCallback(() => {
    onChange?.('');
  }, [onChange]);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop: handleDrop,
    accept: imagesTypes,
    multiple: false,
    // maxSize: MAX_FILE_SIZE,
    maxFiles: 1,
  });

  return (
    <>
      <Box
        {...getRootProps({})}
        sx={{
          height: 175,
          background: theme.themeColors.colorModalBackground,
          border: `1px dashed ${
            fileRejections.length > 0 || error ? COLOR_PRIMARY_3 : theme.themeColors.colorIconButtonBorder
          }`,
          cursor: 'pointer',
          borderRadius: '16px',
          overflow: 'hidden',
          marginBottom: 1,
        }}
      >
        <input {...getInputProps()} />
        {value ? (
          <Box
            component="img"
            src={value}
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: 'center',
            }}
          />
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Box
              sx={{
                justifyContent: 'center',
                width: 36,
                display: 'flex',
                alignItems: 'center',
                height: 36,
                borderRadius: '50%',
                background: COLOR_GRADIENT_ACCENT,
                marginBottom: 0.5,
              }}
            >
              <Plus sx={{ color: COLOR_NEUTRALS_7 }} />
            </Box>
            <Typography variant="body2" sx={{ color: theme.themeColors.colorTextDefault }}>
              Cover
            </Typography>
          </Box>
        )}
      </Box>
      <Typography className="s" sx={{ color: COLOR_PRIMARY_3, lineBreak: 'anywhere', marginBottom: 2 }}>
        {fileRejections?.[0]?.errors?.map((fileError) => fileError.message)?.join(', ') || error}
      </Typography>
      <Button variant="outlined" size="small" onClick={handleClear} sx={{ margin: 'auto' }}>
        Clear
      </Button>
    </>
  );
};
