import { baseApi } from 'store/api/apiRequestBuilder';
import { wrap } from 'store/utils';
import { call, put, takeLatest } from 'typed-redux-saga';
import { getToastMessage } from 'utils';

import { editSubcategory } from '../actions';
import actionTypes from '../actionTypes';
import { setSubcategory } from '../reducer';

export function* editSubcategorySaga({ payload }: { payload: ReturnType<typeof editSubcategory>['payload'] }) {
  const { data: subcategoryData } = yield* call(baseApi.editSubcategory, payload);
  getToastMessage('success', 'Subcategory has been updated');
  yield* put(setSubcategory(subcategoryData));
}

export function* watchEditSubcategory() {
  yield* takeLatest(actionTypes.EDIT_SUBCATEGORY, wrap(editSubcategorySaga));
}
