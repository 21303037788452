import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SingleNote: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    sx={{
      ...sx,
      width: '24px',
      height: '24px',
      path: {
        stroke: 'currentColor',
        fill: 'currentColor',
      },
    }}
  >
    <path
      d="M13.4851 1.34049C12.2228 1.02491 11 1.97963 11 3.28077V18H13V7.28077L18.5149 8.6595C19.7772 8.97508 21 8.02036 21 6.71922V4.78077C21 3.86304 20.3754 3.06307 19.4851 2.84049L13.4851 1.34049Z"
      fill="currentColor"
    />
    <path
      d="M13 18C13 20.7614 10.7614 23 8 23C5.23858 23 3 20.7614 3 18C3 15.2386 5.23858 13 8 13C10.7614 13 13 15.2386 13 18Z"
      fill="currentColor"
    />
  </SvgIcon>
);
