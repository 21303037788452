import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ShoppingTagIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    sx={{
      ...sx,
      width: '24px',
      height: '24px',
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.6785 2.64404C9.30362 2.01891 10.1515 1.66772 11.0355 1.66772H15.8333C17.214 1.66772 18.3333 2.78701 18.3333 4.16772V8.96552C18.3333 9.84958 17.9821 10.6974 17.357 11.3225L10.9344 17.7451C9.95812 18.7214 8.3752 18.7214 7.39889 17.7451L2.25591 12.6022C1.2796 11.6258 1.27961 10.0429 2.25592 9.06662L8.6785 2.64404ZM11.0355 3.33439C10.5935 3.33439 10.1696 3.50999 9.85701 3.82255L3.43443 10.2451C3.10899 10.5706 3.10899 11.0982 3.43443 11.4236L8.5774 16.5666C8.90284 16.8921 9.43048 16.8921 9.75592 16.5666L16.1785 10.144C16.4911 9.83147 16.6667 9.40755 16.6667 8.96552V4.16772C16.6667 3.70749 16.2936 3.33439 15.8333 3.33439H11.0355Z"
      fill="currentColor"
    />
    <path d="M2.98816 8.33427L4.16667 7.15576L12.8452 15.8343L11.6667 17.0128L2.98816 8.33427Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 8.33431C12.9602 8.33431 13.3333 7.96121 13.3333 7.50098C13.3333 7.04074 12.9602 6.66764 12.5 6.66764C12.0398 6.66764 11.6667 7.04074 11.6667 7.50098C11.6667 7.96121 12.0398 8.33431 12.5 8.33431ZM12.5 10.001C13.8807 10.001 15 8.88169 15 7.50098C15 6.12026 13.8807 5.00098 12.5 5.00098C11.1193 5.00098 10 6.12026 10 7.50098C10 8.88169 11.1193 10.001 12.5 10.001Z"
      fill="currentColor"
    />
  </SvgIcon>
);
