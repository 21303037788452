import { COLOR_NEUTRALS_3, COLOR_NEUTRALS_4, COLOR_NEUTRALS_5, COLOR_NEUTRALS_6 } from '../colors.constant';

export const colorsModalsLight = {
  colorModalsText: COLOR_NEUTRALS_5,
  colorModalsBorderBottom: `1px solid ${COLOR_NEUTRALS_6}`,
};

export const colorsModalsDark = {
  colorModalsText: COLOR_NEUTRALS_4,
  colorModalsBorderBottom: `1px solid ${COLOR_NEUTRALS_3}`,
};
