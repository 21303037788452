import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Link: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 21"
    sx={{
      width: '20px',
      height: '21px',
      ...sx,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8166 4.68391C14.9054 3.77268 13.428 3.77268 12.5168 4.68391L11.6004 5.60025C11.2099 5.99077 10.5767 5.99077 10.1862 5.60025C9.79567 5.20972 9.79567 4.57656 10.1862 4.18603L11.1025 3.26969C12.7948 1.57742 15.5385 1.57742 17.2308 3.26969C18.9231 4.96196 18.9231 7.70568 17.2308 9.39795L13.8975 12.7313C12.2052 14.4236 9.46148 14.4236 7.7692 12.7313C7.37868 12.3408 7.37868 11.7076 7.7692 11.3171C8.15973 10.9265 8.79289 10.9265 9.18342 11.3171C10.0946 12.2283 11.572 12.2283 12.4832 11.3171L15.8166 7.98374C16.7278 7.07251 16.7278 5.59513 15.8166 4.68391ZM10.8166 9.68391C9.90536 8.77268 8.42797 8.77268 7.51675 9.68391L4.18342 13.0172C3.27219 13.9285 3.27219 15.4058 4.18342 16.3171C5.09464 17.2283 6.57203 17.2283 7.48325 16.3171L8.40122 15.3991C8.79174 15.0086 9.42491 15.0086 9.81543 15.3991C10.206 15.7896 10.206 16.4228 9.81543 16.8133L8.89746 17.7313C7.20519 19.4236 4.46148 19.4236 2.7692 17.7313C1.07693 16.039 1.07693 13.2953 2.7692 11.603L6.10254 8.26969C7.79481 6.57742 10.5385 6.57742 12.2308 8.26969C12.6213 8.66022 12.6213 9.29338 12.2308 9.68391C11.8403 10.0744 11.2071 10.0744 10.8166 9.68391Z"
      fill="#currentColor"
    />
  </SvgIcon>
);
