import {
  COLOR_NEUTRALS_1,
  COLOR_NEUTRALS_3,
  COLOR_NEUTRALS_4,
  COLOR_NEUTRALS_6,
  COLOR_NEUTRALS_9,
  COLOR_PRIMARY_1,
} from 'theme/colors';

// ===== Borders
export const BORDER_MAIN = '1px solid #323232';
export const BORDER_PRIMARY_1 = `2px solid ${COLOR_PRIMARY_1};`;
export const BORDER_NEUTRALS_3 = `1px solid ${COLOR_NEUTRALS_3}`;
export const BORDER_NEUTRALS_3_BOLD = `2px solid ${COLOR_NEUTRALS_3}`;
export const BORDER_NEUTRALS_4 = `1px solid ${COLOR_NEUTRALS_4}`;
export const BORDER_NEUTRALS_9 = `1px solid ${COLOR_NEUTRALS_9}`;
export const BORDER_NEUTRALS_6 = `1px solid ${COLOR_NEUTRALS_6}`;
export const BORDER_NEUTRALS_1 = `1px solid ${COLOR_NEUTRALS_1}`;

// ===== Border-Radius

export const BORDER_RADIUS_DEFAULT = '16px';
export const BORDER_RADIUS_SMALL = '8px';
export const BORDER_RADIUS_MEDIUM = '12px';
export const BORDER_RADIUS_LARGE = '20px';
export const BORDER_RADIUS_BUTTON = '90px';
export const BORDER_RADIUS_EXTRA_SMALL = '4px';

// ===== Transitions
export const BUTTON_TRANSITION_DEFAULT_TIME = '0.4s ease-in-out';
export const TRANSITION_DEFAULT_TIME = 'all 0.25s ease-in-out';

export const BOX_SHADOW_DEFAULT = '0px 2px 23px -2px rgb(0 0 0 / 3%), 0px 10px 10px -5px rgb(0 0 0 / 4%)';
export const BOX_SHADOW_MAIN = '0px 4px 4px rgba(0, 0, 0, 0.25)';
