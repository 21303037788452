import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouteLink, useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { routes } from 'appConstants';
import { mockAva } from 'assets/images';
import { Avatar, Socials } from 'components';
import { EditCoverWrapper } from 'components/EditCoverWrapper';
import { BlueCheck, Edit } from 'components/Icon/components';
import { PictureIcon } from 'components/Icon/components/PictureIcon';
import { useShallowSelector } from 'hooks';
import { convertToFormRequestData } from 'modules/collection/pages/EditCollection/editCollection.helper';
import { chains } from 'services/WalletService/config';
import { editCollection, setCollectionCover } from 'store/collections/actions';
import userSelector from 'store/user/selectors';
import { COLOR_BG_35, COLOR_NEUTRALS_4, COLOR_PRIMARY_1 } from 'theme/colors';
import { FontWeights } from 'theme/Typography';
import { Chains, ICollection } from 'types';
import { shortenPhrase } from 'utils';
import { smartRound } from 'utils/accurateToFixed';

type CollectionHeaderProps = {
  collection: ICollection;
  id: string;
};

export const CollectionHeader: FC<CollectionHeaderProps> = ({ collection, id }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const userId = useShallowSelector(userSelector.getProp('user'));
  const navigate = useNavigate();
  const isAbleToEdit = Number(collection?.creator?.id) === Number(userId.id);
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

  const handleEditClick = () => {
    navigate(routes.edit.root.getPath(+id));
  };

  const handleEditCoverClick = useCallback(
    (file: File) => {
      dispatch(
        setCollectionCover({
          cover: file,
          id,
        }),
      );
    },
    [dispatch, id],
  );

  const isUpToMedium = useMediaQuery('(max-width:830px)');

  const blocks = [
    {
      title: 'Items',
      value: collection?.tokensCount ?? 0,
    },
    {
      title: 'Owners',
      value: collection?.ownersCount ?? 0,
    },
    {
      title: 'Floor price',
      value: `$${
        collection?.floorPrice && collection?.currency?.rate
          ? smartRound(+(collection.floorPrice ?? 0) * +(collection.currency.rate ?? 0))
          : 0
      }`,
    },
    {
      title: 'Total volume',
      value: `$${collection.volumeTraded ?? 0}`,
      subValue: `~${collection.network.nativeSymbol} ${collection.volumeTradedCrypto ?? 0}`,
    },
    {
      title: 'Creator earnings',
      value: `${collection.creatorRoyalty ?? 0}%`,
    },
  ];

  const { site, twitter, instagram, discord, telegram, medium } = collection;
  return (
    <Box sx={{ zIndex: 11 }}>
      <Box
        sx={{
          display: 'flex',
          marginBottom: 4,
        }}
      >
        {isAbleToEdit && (
          <Box sx={{ marginLeft: 'auto !important', minWidth: '160px', display: { xs: 'none', md: 'block' } }}>
            <Button
              onClick={handleEditClick}
              variant="outlined"
              size="small"
              sx={{
                whiteSpace: 'nowrap',
                ...(isUpToMedium && { span: { margin: 0 } }),
              }}
              endIcon={<Edit />}
            >
              {!isUpToMedium && 'Edit collection'}
            </Button>
          </Box>
        )}
        {isAbleToEdit && (
          <EditCoverWrapper onChange={handleEditCoverClick}>
            <Box sx={{ marginLeft: 2, minWidth: '160px', display: { xs: 'none', md: 'block' } }}>
              <Button
                type="submit"
                variant="outlined"
                size="small"
                sx={{
                  whiteSpace: 'nowrap',
                  ...(isUpToMedium && { span: { margin: 0 } }),
                }}
                endIcon={<PictureIcon />}
              >
                {!isUpToMedium && 'Edit cover'}
              </Button>
            </Box>
          </EditCoverWrapper>
        )}
      </Box>

      <Stack
        direction={{ xs: 'column', md: 'row' }}
        alignItems={{ xs: 'center', md: 'start' }}
        spacing={4}
        sx={{ marginBottom: 4 }}
      >
        <Avatar image={collection?.avatar || mockAva} size="xxl" isSquare />

        <Stack sx={{ alignItems: { xs: 'center', md: 'start' } }}>
          <Typography
            variant="h4"
            lineHeight="35px"
            sx={{ position: 'relative', maxWidth: '440px', marginBottom: 1, textAlign: { xs: 'center', md: 'start' } }}
          >
            {collection.name}
            {collection.isVerified && (
              <BlueCheck sx={{ position: 'absolute', bottom: '4px', verticalAlign: 'middle', marginLeft: 0.5 }} />
            )}
          </Typography>

          <Typography variant="body1" className="s" fontWeight={FontWeights.fontWeightSemiBold} sx={{ mb: 1 }}>
            <Box component="span" sx={{ color: COLOR_NEUTRALS_4 }}>
              {collection.isImported ? 'Added by' : 'Created by'}
            </Box>
            <RouteLink to={routes.profile.root.getPath(+(collection?.creator?.id || 0))}>
              <Box component="span" sx={{ color: COLOR_PRIMARY_1, ml: 1 }}>
                {collection.creator.displayName ||
                  (collection.creator.address && shortenPhrase(collection.creator.address, 6, 4))}
              </Box>
            </RouteLink>
          </Typography>
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: { xs: 4, md: 0 },
            }}
          >
            <Box
              component="img"
              src={chains[collection.network?.name as Chains].mainnet.img}
              sx={{ width: 20, height: 20, marginRight: 1 }}
            />
            {collection.network?.name}
          </Typography>

          {isAbleToEdit && (
            <EditCoverWrapper onChange={handleEditCoverClick}>
              <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <Button
                  type="submit"
                  onClick={handleEditClick}
                  variant="outlined"
                  size="small"
                  endIcon={<PictureIcon />}
                >
                  Edit cover
                </Button>
              </Box>
            </EditCoverWrapper>
          )}
        </Stack>
      </Stack>
      <Box>
        <Box sx={{ maxWidth: 837 }}>
          <Grid
            container
            spacing={2}
            sx={{
              border: `1px solid ${theme.themeColors.colorTextFieldBorderDefault}`,
              borderRadius: '8px',
              margin: 0,
              marginBottom: 3,
              '& > .MuiGrid-item': {
                padding: theme.spacing(1.5, 0),
                ...(isMobile
                  ? {
                      '&:nth-of-type(1),:nth-of-type(3)': {
                        borderRight: `1px solid ${theme.themeColors.colorTextFieldBorderDefault}`,
                      },
                      '&:nth-of-type(1),:nth-of-type(2),:nth-of-type(3),:nth-of-type(5)': {
                        borderBottom: `1px solid ${theme.themeColors.colorTextFieldBorderDefault}`,
                      },
                    }
                  : {
                      '&:not(:last-child)': {
                        borderRight: `1px solid ${theme.themeColors.colorTextFieldBorderDefault}`,
                      },
                    }),
              },
            }}
          >
            {blocks.map((block, index) => (
              <Grid
                key={block.title}
                item
                xs={(isMobile && index === 3 && 12) || (isMobile && 6) || 2.4}
                order={(isMobile && index === 3 && 4) || (isMobile && index === 4 && 3) || index}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  textAlign: 'center',
                  background: COLOR_BG_35,
                }}
              >
                <Typography
                  sx={{
                    textAlign: 'center',
                  }}
                  className="xl"
                >
                  {block.value}
                </Typography>
                {block.subValue && (
                  <Typography
                    sx={{
                      textAlign: 'center',
                      color: theme.themeColors.colorFooterText,
                      marginBottom: 0.5,
                    }}
                    className="xs"
                  >
                    {block.subValue}
                  </Typography>
                )}
                <Typography
                  sx={{
                    textAlign: 'center',
                    color: theme.themeColors.colorTextBody2,
                  }}
                  className="s"
                >
                  {block.title}
                </Typography>
              </Grid>
            ))}
          </Grid>

          <Typography
            className="s"
            sx={{ display: { xs: 'none', md: 'block' }, width: '100%', wordBreak: 'break-word' }}
          >
            {collection.description}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-end' } }}>
          <Socials {...{ site, twitter, instagram, discord, telegram, medium }} />
        </Box>

        {isAbleToEdit && (
          <Box sx={{ display: { xs: 'block', md: 'none' }, mb: 4 }}>
            <Button onClick={handleEditClick} variant="outlined" size="small" endIcon={<Edit />}>
              Edit collection
            </Button>
          </Box>
        )}

        <Typography className="s" sx={{ display: { xs: 'block', md: 'none' }, width: '100%', wordBreak: 'break-word' }}>
          {collection.description}
        </Typography>
      </Box>
    </Box>
  );
};
