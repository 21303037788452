const SEARCH_NFTS = 'SEARCH_NFTS' as const;
const GET_NFTS = 'GET_NFTS' as const;
const GET_MAX_PRICE = 'GET_MAX_PRICE' as const;

const GET_CATEGORIES = 'GET_CATEGORIES';
const GET_FAVORITE_TOKENS = 'GET_FAVORITE_TOKENS';
const GET_TRENDING_TOKENS = 'GET_TRENDING_TOKENS';

export default {
  SEARCH_NFTS,
  GET_NFTS,
  GET_MAX_PRICE,

  GET_CATEGORIES,
  GET_FAVORITE_TOKENS,
  GET_TRENDING_TOKENS,
};
