/* eslint-disable react/no-array-index-key */
import ReactPlayer from 'react-player';
import { Box, IconButton, Modal, useTheme } from '@mui/material';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Close } from 'components/Icon/components';
import { COLOR_BLACK_80 } from 'theme/colors';

export interface PreviewModalProps {
  media: {
    image: string;
    video?: string | undefined;
  }[];
  activeMediaIndex: number | null;
  setActiveMediaIndex: (index: number | null) => void;
}

export const PreviewModal = ({ media, activeMediaIndex, setActiveMediaIndex }: PreviewModalProps) => {
  const theme = useTheme();

  return (
    <Modal
      open={activeMediaIndex !== null}
      onClose={() => setActiveMediaIndex(null)}
      disableScrollLock
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        '.MuiBackdrop-root': {
          background: COLOR_BLACK_80,
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column-reverse', md: 'row' },
          alignItems: { xs: 'flex-end', md: 'flex-start' },
          maxWidth: '100%',
          maxHeight: '100%',
          paddingX: 1,
        }}
      >
        <Box
          component={Splide}
          // hasTrack={false}
          options={{
            type: 'fade',
            perMove: 1,
            gap: theme.spacing(7),
            pagination: false,
            start: activeMediaIndex ?? 0,
            rewind: true,
            // fixedWidth: isLargeScreen ? 480 : 328,
          }}
          sx={{
            width: 800,
            height: 'fit-content',
            maxWidth: '100%',
            maxHeight: '100%',
          }}
        >
          {media.map((resource, index) => (
            <Box
              component={SplideSlide}
              key={index}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {resource.video ? (
                <ReactPlayer
                  url={resource.video}
                  autoplay
                  controls
                  muted
                  playsinline
                  style={{ borderRadius: '12px', overflow: 'hidden' }}
                />
              ) : (
                <Box
                  component="img"
                  src={resource.image}
                  sx={{
                    width: 'initial',
                    height: 'auto',
                    maxWidth: 'min(800px, 100%)',
                    maxHeight: 'min(600px, 100%)',
                    objectFit: 'contain',
                    objectPosition: 'center',
                    borderRadius: '12px',
                  }}
                />
              )}
            </Box>
          ))}
        </Box>

        <IconButton onClick={() => setActiveMediaIndex(null)} sx={{ marginLeft: { md: 3 } }}>
          <Close sx={{ width: 40, height: 40 }} />
        </IconButton>
      </Box>
    </Modal>
  );
};
