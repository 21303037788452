// !NOTE: only types from MIME standard
// https://www.iana.org/assignments/media-types/media-types.xhtml

export const imagesTypes = {
  'image/gif': [],
  'image/jpeg': [],
  'image/jpg': [],
  'image/png': [],
  'image/webp': [],
  'image/svg+xml': [],
};

export const videosTypes = {
  'video/mp4': [],
  'video/webm': [],
  'video/ogg': [],
};

export const audioTypes = {
  'audio/mpeg': [],
  'audio/wav': [],
  'audio/ogg': [],
};

export const modelTypes = {
  'model/gltf-binary': [],
  'model/gltf+json': [],
  glb: [],
  gltf: [],
};
