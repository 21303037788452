import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Nerd: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 18"
    sx={{
      ...sx,
      width: '20px',
      height: '18px',
    }}
  >
    <path d="M11.7123 10.7161H5.60352V12.1168H11.7123V10.7161Z" fill="currentColor" />
    <path
      d="M14.3653 14.0427C13.4811 15.5485 11.7128 16.5991 9.62294 16.5991H8.37707C6.28724 16.5991 4.51892 15.5485 3.63476 14.0427C2.71041 13.9726 1.90662 13.4824 1.54492 12.782C1.987 15.7236 4.88062 17.9999 8.37707 17.9999H9.62294C13.1194 17.9999 16.013 15.7236 16.4551 12.782C16.0934 13.4824 15.2896 13.9726 14.3653 14.0427Z"
      fill="currentColor"
    />
    <path
      d="M15.4897 0H7.93417C4.39752 0 1.50391 2.5214 1.50391 5.60311V10.9961C1.78523 10.3658 2.38807 9.87548 3.11147 9.70039V5.60311C3.11147 3.29183 5.28168 1.40078 7.93417 1.40078H14.9271V9.70039C15.6505 9.9105 16.2533 10.4008 16.5346 10.9961V0.910506C16.5346 0.420233 16.0524 0 15.4897 0Z"
      fill="currentColor"
    />
    <path
      d="M7.89453 14.498C7.89453 15.1284 8.45718 15.6187 9.18058 15.6187C9.90399 15.6187 10.4666 15.1284 10.4666 14.498H7.89453Z"
      fill="currentColor"
    />
    <path
      d="M14.1638 14.7432C12.3151 14.7432 10.8281 13.4474 10.8281 11.8365C10.8281 10.2257 12.3151 8.92993 14.1638 8.92993C16.0125 8.92993 17.4995 10.2257 17.4995 11.8365C17.4995 13.4474 16.0125 14.7432 14.1638 14.7432ZM14.1638 10.3307C13.1993 10.3307 12.4357 10.9961 12.4357 11.8365C12.4357 12.677 13.1993 13.3424 14.1638 13.3424C15.1284 13.3424 15.892 12.677 15.892 11.8365C15.892 10.9961 15.1284 10.3307 14.1638 10.3307Z"
      fill="currentColor"
    />
    <path
      d="M3.8357 14.7432C1.987 14.7432 0.5 13.4474 0.5 11.8365C0.5 10.2257 1.987 8.92993 3.8357 8.92993C5.6844 8.92993 7.17139 10.2257 7.17139 11.8365C7.17139 13.4474 5.6844 14.7432 3.8357 14.7432ZM3.8357 10.3307C2.87116 10.3307 2.10756 10.9961 2.10756 11.8365C2.10756 12.677 2.87116 13.3424 3.8357 13.3424C4.80024 13.3424 5.56383 12.677 5.56383 11.8365C5.56383 10.9961 4.80024 10.3307 3.8357 10.3307Z"
      fill="currentColor"
    />
  </SvgIcon>
);
