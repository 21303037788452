import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const PictureIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 25 24"
    sx={{
      ...sx,
      width: '25px',
      height: '24px',
      fill: 'none',
    }}
  >
    <path
      d="M4.5 16L9.08579 11.4142C9.86683 10.6332 11.1332 10.6332 11.9142 11.4142L16.5 16M14.5 14L16.0858 12.4142C16.8668 11.6332 18.1332 11.6332 18.9142 12.4142L20.5 14M14.5 8H14.51M6.5 20H18.5C19.6046 20 20.5 19.1046 20.5 18V6C20.5 4.89543 19.6046 4 18.5 4H6.5C5.39543 4 4.5 4.89543 4.5 6V18C4.5 19.1046 5.39543 20 6.5 20Z"
      stroke="#E6E8EC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
