import { FC } from 'react';
import { Telegram } from '@mui/icons-material';
import { Stack, SxProps } from '@mui/material';
import { Discord, FacebookOutlined, Globe, Instagram, Mail, Medium, Twitter } from 'components/Icon/components';
import { COLOR_NEUTRALS_4 } from 'theme/colors';

export type SocialsProps = {
  email?: string;
  site?: string;
  instagram?: string;
  discord?: string;
  telegram?: string;
  medium?: string;
  twitter?: string;
  facebook?: string;
  sx?: SxProps;
};

export const Socials: FC<SocialsProps> = ({
  email,
  site,
  instagram,
  facebook,
  discord,
  telegram,
  medium,
  twitter,
  sx,
}) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{ alignItems: 'center', mb: { xs: 3, md: 0 }, a: { color: COLOR_NEUTRALS_4 }, ...sx }}
    >
      {email && (
        <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
          <Mail />
        </a>
      )}
      {site && (
        <a href={site} target="_blank" rel="noreferrer">
          <Globe />
        </a>
      )}
      {twitter && (
        <a href={`https://twitter.com/${twitter.replaceAll('@', '')}`} target="_blank" rel="noreferrer">
          <Twitter />
        </a>
      )}
      {instagram && (
        <a href={`https://www.instagram.com/${instagram.replaceAll('@', '')}/`} target="_blank" rel="noreferrer">
          <Instagram />
        </a>
      )}
      {facebook && (
        <a href={`https://facebook.com/${facebook}`} target="_blank" rel="noreferrer">
          <FacebookOutlined />
        </a>
      )}
      {discord && (
        <a href={discord} target="_blank" rel="noreferrer">
          <Discord />
        </a>
      )}
      {telegram && (
        <a href={telegram} target="_blank" rel="noreferrer">
          <Telegram />
        </a>
      )}
      {medium && (
        <a
          href={`https://medium.com/${medium.charAt(0) === '@' ? medium : `@${medium}`}`}
          target="_blank"
          rel="noreferrer"
        >
          <Medium />
        </a>
      )}
    </Stack>
  );
};
