import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Gaming: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 18"
    sx={{
      ...sx,
      width: '20px',
      height: '18px',
    }}
  >
    <path d="M10.7713 0H9.22656V3.01339H10.7713V0Z" fill="currentColor" />
    <path
      d="M16.8735 17.9999C14.6337 17.9999 12.8573 16.1517 12.78 13.8615H7.21907C7.18045 16.1517 5.36541 17.9999 3.12557 17.9999C1.65809 17.9999 0.460938 16.7544 0.460938 15.2276V7.71418C0.499555 4.66061 2.85525 2.20972 5.79021 2.20972H14.2475C17.1439 2.20972 19.5382 4.66061 19.5382 7.71418V15.2276C19.4996 16.7544 18.341 17.9999 16.8735 17.9999ZM7.14183 12.2142H12.8573C13.6683 12.2142 14.3248 12.8972 14.3248 13.741C14.3248 15.1874 15.4447 16.3928 16.8735 16.3928C17.4914 16.3928 17.9935 15.8704 17.9935 15.2276V7.71418C17.9935 5.58472 16.3329 3.81686 14.2475 3.81686H5.79021C3.70484 3.81686 2.04427 5.54454 2.04427 7.71418V15.2276C2.04427 15.8704 2.5463 16.3928 3.16419 16.3928C4.55443 16.3928 5.71297 15.2276 5.71297 13.741C5.67435 12.8972 6.33086 12.2142 7.14183 12.2142Z"
      fill="currentColor"
    />
    <path
      d="M14.826 7.07139C14.826 7.51336 14.4784 7.87497 14.0536 7.87497C13.6288 7.87497 13.2812 7.51336 13.2812 7.07139C13.2812 6.62943 13.6288 6.26782 14.0536 6.26782C14.4784 6.26782 14.826 6.62943 14.826 7.07139Z"
      fill="currentColor"
    />
    <path
      d="M16.2166 9.04014C16.2166 9.48211 15.869 9.84371 15.4442 9.84371C15.0194 9.84371 14.6719 9.48211 14.6719 9.04014C14.6719 8.59818 15.0194 8.23657 15.4442 8.23657C15.869 8.23657 16.2166 8.59818 16.2166 9.04014Z"
      fill="currentColor"
    />
    <path
      d="M12.4708 11.0491H11.3122C11.1964 11.0491 11.1191 10.9688 11.1191 10.8482V9.96432C11.1191 9.84378 11.1964 9.76343 11.3122 9.76343H12.4708C12.5866 9.76343 12.6639 9.84378 12.6639 9.96432V10.8482C12.6639 10.9688 12.5866 11.0491 12.4708 11.0491Z"
      fill="currentColor"
    />
    <path
      d="M8.87897 11.0491H7.72043C7.60458 11.0491 7.52734 10.9688 7.52734 10.8482V9.96432C7.52734 9.84378 7.60458 9.76343 7.72043 9.76343H8.87897C8.99482 9.76343 9.07206 9.84378 9.07206 9.96432V10.8482C9.07206 10.9688 8.95621 11.0491 8.87897 11.0491Z"
      fill="currentColor"
    />
    <path
      d="M5.36531 9.84382C5.09499 9.84382 4.86328 9.60275 4.86328 9.3215V7.11168C4.86328 6.83043 5.09499 6.58936 5.36531 6.58936C5.63564 6.58936 5.86735 6.83043 5.86735 7.11168V9.3215C5.86735 9.60275 5.63564 9.84382 5.36531 9.84382Z"
      fill="currentColor"
    />
    <path
      d="M6.9093 8.19639V8.23657C6.9093 8.51782 6.67759 8.75889 6.40727 8.75889H4.28328C4.01296 8.75889 3.78125 8.51782 3.78125 8.23657V8.19639C3.78125 7.91514 4.01296 7.67407 4.28328 7.67407H6.40727C6.67759 7.67407 6.9093 7.91514 6.9093 8.19639Z"
      fill="currentColor"
    />
  </SvgIcon>
);
