import { cardColors } from 'components/Card/Card.helper';
import { playerColors } from 'components/Player/Player.helper';
import { bannerColors } from 'modules/landing/components/Banner/Banner.helpers';
import { notificationMiniColors } from 'modules/landing/components/NotificationMini/NotificationMini.helper';
import { backgroundBoxColors } from 'modules/landing/containers/BackgroundBox/BackgroundBox.helpers';
import { nftDetailsBidColors } from 'modules/nft/containers/NftDetails/components/NftDetailsBid/NftDetailsBid.helper';
import { nftDetailsCreatorCollectionColors } from 'modules/nft/containers/NftDetails/components/NftDetailsCreatorCollection/NftDetailsCreatorCollection.helper';
import { nftDetailsHistoryColors } from 'modules/nft/containers/NftDetails/components/NftDetailsHistory/NftDetailsHistory.helper';

import { colorsFileUploaderDark, colorsFileUploaderLight } from './components/colorFileUploader';
import { colorsRadioAndCheckboxDark, colorsRadioAndCheckboxLight } from './components/colorRadioAndCheckbox';
import { colorsButtonDark, colorsButtonLight } from './components/colorsButton';
import {
  COLOR_BG,
  COLOR_BLACK,
  COLOR_DARK_GRAY,
  COLOR_DEEP_DARK,
  COLOR_GRADIENT_ACCENT,
  COLOR_GRAY_100,
  COLOR_NEUTRALS_1,
  COLOR_NEUTRALS_2,
  COLOR_NEUTRALS_3,
  COLOR_NEUTRALS_4,
  COLOR_NEUTRALS_5,
  COLOR_NEUTRALS_6,
  COLOR_NEUTRALS_6_LIGHT,
  COLOR_NEUTRALS_7,
  COLOR_NEUTRALS_8,
  COLOR_NEUTRALS_9,
  COLOR_PRIMARY_1,
  COLOR_PRIMARY_2,
  COLOR_PRIMARY_3,
  COLOR_PRIMARY_4,
  COLOR_WHITE_BASE,
} from './colors.constant';
import {
  colorsAdvantageCardDark,
  colorsAdvantageCardLight,
  colorsAvatarDark,
  colorsAvatarLight,
  colorsCountdownDark,
  colorsCountdownLight,
  colorsFollowingCardDark,
  colorsFollowingCardLight,
  colorsLikeButtonDark,
  colorsLikeButtonLight,
  colorsModalsDark,
  colorsModalsLight,
  colorsNftCardDark,
  colorsNftCardLight,
  colorsNftDetailsModalsDark,
  colorsNftDetailsModalsLight,
  colorsSellerCardDark,
  colorsSellerCardLight,
  colorsShareButtonDark,
  colorsShareButtonLight,
  colorsTopCollectionCardDark,
  colorsTopCollectionCardLight,
} from './components';

export const colorThemes = {
  light: {
    colorTextDefault: COLOR_NEUTRALS_2,
    colorTextBody2: COLOR_NEUTRALS_4,
    colorButtonSecondary: COLOR_NEUTRALS_6,
    colorButtonSecondaryText: COLOR_NEUTRALS_2,
    colorButtonSecondaryTextHover: COLOR_NEUTRALS_6,
    colorButtonSecondaryHover: COLOR_NEUTRALS_2,
    colorButtonSecondaryBorderHover: COLOR_NEUTRALS_4,
    colorTableText: COLOR_NEUTRALS_5,

    colorCircleDefault: COLOR_NEUTRALS_4,
    colorCircleHover: COLOR_PRIMARY_2,
    colorArrowsDefault: COLOR_NEUTRALS_4,
    colorArrowsHover: COLOR_PRIMARY_2,
    colorArrowsDeactive: COLOR_NEUTRALS_6,
    colorBurgerDefault: COLOR_NEUTRALS_4,
    colorBurgerHover: COLOR_PRIMARY_2,
    colorBurgerDeactive: COLOR_PRIMARY_2,
    colorDividerDefault: COLOR_NEUTRALS_6,
    colorTabItemTextDefault: COLOR_NEUTRALS_4,
    colorTabItemTextHover: COLOR_NEUTRALS_2,
    colorTabItemTextActive: COLOR_NEUTRALS_8,
    colorTabItemTextDeactive: COLOR_NEUTRALS_5,
    colorIconDefault: COLOR_NEUTRALS_4,
    colorIconDefaultHover: COLOR_NEUTRALS_4,
    colorIconActive: COLOR_NEUTRALS_2,
    colorSuccess: COLOR_PRIMARY_4,
    colorError: COLOR_PRIMARY_3,
    colorTextFieldBackground: COLOR_NEUTRALS_8,
    colorTextFieldPlaceholderDefault: COLOR_NEUTRALS_4,
    colorTextFieldColor: COLOR_NEUTRALS_3,
    colorTextFieldColorError: COLOR_PRIMARY_3,
    colorTextFieldBorderDefault: COLOR_NEUTRALS_6,
    colorTextFieldBorderHover: COLOR_NEUTRALS_5,
    colorTextFieldBorderFocus: COLOR_NEUTRALS_5,
    colorTextFieldBorderFocusBold: COLOR_NEUTRALS_9,
    colorTextFieldBorderError: COLOR_PRIMARY_3,
    colorTextFieldBorderSuccess: COLOR_PRIMARY_4,
    colorTextFieldCaretColor: COLOR_PRIMARY_2,
    colorSwitchThumb: COLOR_PRIMARY_2,
    colorSwitchTrack: COLOR_BG,
    colorModalBackground: COLOR_NEUTRALS_8,
    colorDialogBackground: COLOR_NEUTRALS_8,
    colorDialogText: COLOR_NEUTRALS_4,
    colorDialogTextChecked: COLOR_NEUTRALS_3,
    colorIconButtonIconDefault: COLOR_NEUTRALS_4,
    colorIconButtonIconHover: COLOR_PRIMARY_1,
    colorIconButtonIconDisabled: COLOR_NEUTRALS_3,
    colorIconButtonBackground: COLOR_NEUTRALS_3,
    colorIconButtonBoxShadow: '0px 8px 16px -8px rgba(15, 15, 15, 0.2)',
    colorIconButtonBorder: COLOR_NEUTRALS_3,
    colorIconButtonSquareBackground: COLOR_NEUTRALS_6,
    colorSliderRail: COLOR_NEUTRALS_3,
    colorSliderThumb: COLOR_PRIMARY_2,
    colorSliderThumbBorder: COLOR_NEUTRALS_7,
    colorSliderTrack: COLOR_GRADIENT_ACCENT,
    colorSliderTooltip: COLOR_NEUTRALS_1,
    colorFooterBackground: COLOR_NEUTRALS_6,
    colorFooterText: COLOR_NEUTRALS_3,
    colorFooterTitle: COLOR_NEUTRALS_3,
    colorFooterLinkText: COLOR_NEUTRALS_3,
    colorLogoText: COLOR_NEUTRALS_3,
    colorIconsBackground: COLOR_NEUTRALS_6_LIGHT,
    colorIcons: COLOR_NEUTRALS_3,
    colorPriceBoxBg: COLOR_NEUTRALS_6,
    colorSelectIconBorder: COLOR_NEUTRALS_6,
    colorSelectIcon: COLOR_NEUTRALS_4,
    colorMenuList: COLOR_NEUTRALS_8,
    colorMenuListBorder: COLOR_NEUTRALS_6,
    colorMenuItemSelected: COLOR_NEUTRALS_7,
    colorMenuItemText: COLOR_NEUTRALS_3,
    colorMenuItemBackgroundHover: COLOR_NEUTRALS_6,
    colorTrendingCategory: COLOR_PRIMARY_1,
    colorDivider: COLOR_NEUTRALS_6,
    colorDeepDivider: COLOR_NEUTRALS_4,
    colorBreadcrumbsActive: COLOR_BLACK,
    ...colorsFileUploaderLight,
    ...colorsNftCardLight,
    ...colorsLikeButtonLight,
    ...colorsAvatarLight,
    ...colorsCountdownLight,
    ...colorsButtonLight,
    ...colorsTopCollectionCardLight,
    ...colorsAdvantageCardLight,
    ...colorsSellerCardLight,
    colorBackground: COLOR_NEUTRALS_8,
    colorBackgroundSecondary: COLOR_NEUTRALS_6_LIGHT,
    ...colorsShareButtonLight,
    ...colorsLikeButtonLight,
    ...colorsNftCardLight,
    ...colorsModalsLight,
    colorWhatsNftBackground: COLOR_NEUTRALS_6_LIGHT,
    colorWhatsNftText: COLOR_NEUTRALS_4,
    colorAdvantagesBackground: COLOR_WHITE_BASE,
    colorToast: COLOR_NEUTRALS_8,
    colorCollectionSubtitleBackground: COLOR_NEUTRALS_6_LIGHT,
    colorTabBackground: COLOR_NEUTRALS_3,
    ...bannerColors.light,
    ...notificationMiniColors.light,
    ...colorsRadioAndCheckboxLight,
    ...backgroundBoxColors.light,
    colorHeader: COLOR_NEUTRALS_6_LIGHT,
    colorLandingHeader: COLOR_NEUTRALS_8,
    colorTabOutlinedBackground: COLOR_NEUTRALS_7,
    colorTabOutlinedTextActive: COLOR_NEUTRALS_3,
    colorScrollbarBackground: COLOR_NEUTRALS_6_LIGHT,
    colorTabPricingBackground: `${COLOR_NEUTRALS_8} !important`,
    colorStepCardText: COLOR_NEUTRALS_3,
    colorInputInnerButtonBackground: COLOR_NEUTRALS_7,
    colorChipBackground: COLOR_NEUTRALS_6,

    colorTextActivityTablePriceDollar: COLOR_NEUTRALS_5,

    colorActivityTooltipBackground: COLOR_NEUTRALS_7,
    colorActivityTooltipTextColor: COLOR_NEUTRALS_3,

    ...colorsFollowingCardLight,
    ...colorsNftDetailsModalsLight,

    ...nftDetailsCreatorCollectionColors.light,

    ...nftDetailsHistoryColors.light,

    ...nftDetailsBidColors.light,

    ...cardColors.light,
    ...playerColors.light,
  },
  dark: {
    colorTextDefault: COLOR_NEUTRALS_6,
    colorTextBody2: COLOR_NEUTRALS_4,
    colorButtonSecondary: COLOR_NEUTRALS_2,
    colorButtonSecondaryText: COLOR_NEUTRALS_6,
    colorButtonSecondaryTextHover: COLOR_NEUTRALS_6,
    colorButtonSecondaryHover: COLOR_NEUTRALS_2,
    colorButtonSecondaryBorderHover: COLOR_NEUTRALS_4,
    colorTableText: COLOR_WHITE_BASE,
    colorCircleDefault: COLOR_NEUTRALS_4,
    colorCircleHover: COLOR_PRIMARY_2,
    colorArrowsDefault: COLOR_NEUTRALS_4,
    colorArrowsHover: COLOR_PRIMARY_2,
    colorArrowsDeactive: COLOR_NEUTRALS_6,
    colorBurgerDefault: COLOR_NEUTRALS_4,
    colorBurgerHover: COLOR_PRIMARY_2,
    colorBurgerDeactive: COLOR_PRIMARY_2,
    colorDividerDefault: COLOR_NEUTRALS_6,
    colorTabItemTextDefault: COLOR_NEUTRALS_4,
    colorTabItemTextHover: COLOR_NEUTRALS_2,
    colorTabItemTextActive: COLOR_NEUTRALS_8,
    colorTabItemTextDeactive: COLOR_NEUTRALS_5,
    colorIconDefault: COLOR_NEUTRALS_4,
    colorIconDefaultHover: COLOR_PRIMARY_2,
    colorIconActive: COLOR_NEUTRALS_2,
    colorSuccess: COLOR_PRIMARY_4,
    colorError: COLOR_PRIMARY_3,
    colorTextFieldBackground: COLOR_BG,
    colorInputInnerButtonBackground: COLOR_DEEP_DARK,
    colorTextFieldPlaceholderDefault: COLOR_NEUTRALS_4,
    colorTextFieldColor: COLOR_NEUTRALS_6,
    colorTextFieldColorError: COLOR_PRIMARY_3,
    colorTextFieldBorderDefault: COLOR_NEUTRALS_3,
    colorTextFieldBorderHover: COLOR_NEUTRALS_4,
    colorTextFieldBorderFocus: COLOR_NEUTRALS_3,
    colorTextFieldBorderFocusBold: COLOR_NEUTRALS_9,
    colorTextFieldBorderError: COLOR_PRIMARY_3,
    colorTextFieldBorderSuccess: COLOR_PRIMARY_4,
    colorTextFieldCaretColor: COLOR_PRIMARY_2,
    colorSwitchThumb: COLOR_PRIMARY_2,
    colorSwitchTrack: COLOR_BG,
    colorModalBackground: COLOR_NEUTRALS_1,
    colorDialogBackground: COLOR_NEUTRALS_1,
    colorDialogText: COLOR_NEUTRALS_7,
    colorDialogTextChecked: COLOR_NEUTRALS_8,
    colorIconButtonIconDefault: COLOR_NEUTRALS_4,
    colorIconButtonIconHover: COLOR_PRIMARY_1,
    colorIconButtonIconDisabled: COLOR_NEUTRALS_4,
    colorIconButtonBackground: COLOR_NEUTRALS_8,
    colorIconButtonBoxShadow: '0px 8px 16px -8px rgba(15, 15, 15, 0.2)',
    colorIconButtonBorder: COLOR_NEUTRALS_3,
    colorIconButtonSquareBackground: COLOR_NEUTRALS_2,
    colorSliderRail: COLOR_NEUTRALS_3,
    colorSliderThumb: COLOR_PRIMARY_2,
    colorSliderThumbBorder: COLOR_NEUTRALS_7,
    colorSliderTrack: COLOR_GRADIENT_ACCENT,
    colorSliderTooltip: COLOR_NEUTRALS_1,
    colorPriceBoxBg: COLOR_BG,
    colorSelectIcon: COLOR_NEUTRALS_4,
    colorSelectIconBorder: COLOR_NEUTRALS_3,
    colorMenuList: COLOR_NEUTRALS_1,
    colorMenuListBorder: COLOR_NEUTRALS_3,
    colorMenuItemSelected: COLOR_NEUTRALS_2,
    colorMenuItemText: COLOR_NEUTRALS_6,
    colorMenuItemBackgroundHover: COLOR_NEUTRALS_3,
    colorDivider: COLOR_NEUTRALS_3,
    colorDeepDivider: COLOR_NEUTRALS_3,
    colorTrendingCategory: COLOR_PRIMARY_1,
    colorBreadcrumbsActive: COLOR_NEUTRALS_8,
    ...colorsNftCardDark,
    ...colorsLikeButtonDark,
    ...colorsAvatarDark,
    ...colorsCountdownDark,
    ...colorsTopCollectionCardDark,
    ...colorsButtonDark,
    ...colorsSellerCardDark,
    ...colorsShareButtonDark,
    ...colorsFileUploaderDark,
    colorFooterBackground: COLOR_BG,
    colorFooterText: COLOR_NEUTRALS_5,
    colorFooterTitle: COLOR_NEUTRALS_6,
    colorFooterLinkText: COLOR_NEUTRALS_9,
    colorLogoText: COLOR_NEUTRALS_6_LIGHT,
    colorIconsBackground: COLOR_NEUTRALS_3,
    colorIcons: COLOR_NEUTRALS_6_LIGHT,
    ...colorsTopCollectionCardDark,
    colorBackground: COLOR_BG,
    colorBackgroundSecondary: COLOR_NEUTRALS_1,
    ...colorsAdvantageCardDark,
    ...colorsModalsDark,
    colorWhatsNftBackground: 'transparent',
    colorWhatsNftText: COLOR_GRAY_100,
    colorAdvantagesBackground: COLOR_BG,
    colorToast: COLOR_BG,
    colorCollectionSubtitleBackground: COLOR_NEUTRALS_2,
    colorTabBackground: COLOR_NEUTRALS_3,
    ...bannerColors.dark,
    ...notificationMiniColors.dark,
    ...colorsRadioAndCheckboxDark,
    ...colorsFollowingCardDark,
    ...backgroundBoxColors.dark,
    colorLandingHeader: COLOR_BLACK,
    colorHeader: COLOR_NEUTRALS_1,
    colorScrollbarBackground: COLOR_NEUTRALS_1,
    colorStepCardText: COLOR_DARK_GRAY,
    colorChipBackground: COLOR_NEUTRALS_2,

    colorTabOutlinedBackground: COLOR_NEUTRALS_2,
    colorTabOutlinedTextActive: COLOR_NEUTRALS_7,
    colorTabPricingBackground: `${COLOR_BG} !important`,

    colorTextActivityTablePriceDollar: COLOR_NEUTRALS_3,

    colorActivityTooltipBackground: COLOR_BG,
    colorActivityTooltipTextColor: COLOR_NEUTRALS_4,

    ...colorsNftDetailsModalsDark,

    ...nftDetailsCreatorCollectionColors.dark,

    ...nftDetailsHistoryColors.dark,

    ...nftDetailsBidColors.dark,

    ...cardColors.dark,
    ...playerColors.dark,
  },
};
