import {
  COLOR_NEUTRALS_2,
  COLOR_NEUTRALS_3,
  COLOR_NEUTRALS_4,
  COLOR_NEUTRALS_6,
  COLOR_NEUTRALS_7,
} from '../colors.constant';

export const colorsShareButtonLight = {
  colorShareButtonBackground: COLOR_NEUTRALS_6,
  colorShareButtonFill: COLOR_NEUTRALS_4,
  colorShareButtonLink: COLOR_NEUTRALS_4,
  colorShareButtonItem: COLOR_NEUTRALS_6,
  colorShareButtonHover: COLOR_NEUTRALS_7,
};

export const colorsShareButtonDark = {
  colorShareButtonBackground: COLOR_NEUTRALS_2,
  colorShareButtonFill: COLOR_NEUTRALS_4,
  colorShareButtonLink: COLOR_NEUTRALS_4,
  colorShareButtonItem: COLOR_NEUTRALS_3,
  colorShareButtonHover: COLOR_NEUTRALS_2,
};
