import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Bomb: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 16"
    sx={{
      ...sx,
      width: '20px',
      height: '16px',
    }}
  >
    <path
      d="M2.97717 9.45537C2.63562 9.45537 2.36996 9.13617 2.40791 8.77707C2.67357 6.62245 4.30545 4.90674 6.39273 4.58754C6.73429 4.54764 7.03789 4.82694 7.03789 5.18605C7.03789 5.50525 6.81019 5.74465 6.54454 5.78455C5.82347 5.90425 5.14036 6.26335 4.60905 6.78206C4.03979 7.34066 3.66028 8.09876 3.54643 8.93667C3.47053 9.21597 3.24283 9.45537 2.97717 9.45537Z"
      fill="currentColor"
    />
    <path
      d="M16.0709 8.05898L15.8432 7.81958C14.8565 6.78217 14.8565 5.06646 15.8432 3.98915L16.1088 3.70985C16.5263 3.27095 16.6022 2.63255 16.2986 2.11384C15.8811 1.43554 15.0083 1.39564 14.5149 1.91434L12.162 4.38816L11.0234 3.31085L13.3384 0.876935C14.2872 -0.120571 15.8432 -0.320073 16.9437 0.557733C18.2341 1.63504 18.3479 3.63005 17.2094 4.78716L16.9058 5.10636C16.716 5.30586 16.6022 5.58517 16.6022 5.90437C16.6022 6.18367 16.716 6.46297 16.9058 6.70237L17.1335 6.94177C17.4371 7.26098 17.4371 7.73978 17.1335 8.05898C16.8299 8.33828 16.3365 8.33828 16.0709 8.05898Z"
      fill="currentColor"
    />
    <path
      d="M6.73476 15.999C5.10288 15.999 3.47101 15.4005 2.21863 14.1636C0.548804 12.5676 -0.24816 10.2933 0.0933961 7.85938C0.207248 6.98157 0.510853 6.10377 0.928311 5.30576C2.63609 2.35314 6.0137 1.19604 8.9359 2.23344C9.39131 2.39304 9.61901 2.91175 9.39131 3.35065C9.20156 3.70975 8.82205 3.86935 8.44254 3.74965C6.0896 2.91175 3.35715 3.90925 2.10478 6.46287C1.87708 6.90177 1.72527 7.42047 1.64937 7.89928C1.30782 10.014 2.02888 12.009 3.58486 13.2858C5.14083 14.5626 7.34197 14.802 9.0877 13.8444C11.137 12.7272 12.2376 10.4529 11.934 8.13868C11.8581 7.69978 11.7443 7.22097 11.5924 6.82197C11.3647 6.26337 11.6304 6.02397 12.1997 5.46536C12.2376 5.42546 12.2756 5.38556 12.3135 5.34566L10.1883 3.11125C9.88467 2.79205 9.88467 2.31324 10.1883 1.99404C10.4919 1.67484 10.9473 1.67484 11.2509 1.99404L13.8695 4.74716C14.0213 4.90676 14.1351 5.14616 14.0972 5.38556C14.0972 5.62496 13.9833 5.86437 13.8315 6.02397C13.5279 6.30327 13.3002 6.54267 13.1484 6.70227C13.2623 7.10127 13.3761 7.50027 13.4141 7.89928C13.7177 10.2135 12.9587 12.5277 11.3268 14.1237C10.0365 15.3606 8.40459 15.999 6.73476 15.999Z"
      fill="currentColor"
    />
    <path
      d="M14.8178 10.7722C15.0834 10.9717 15.425 10.8919 15.6147 10.6126L15.9563 10.054C16.146 9.77465 16.0701 9.41555 15.8045 9.21605C15.5388 9.01654 15.1973 9.09635 15.0075 9.37565L14.666 9.93425C14.4762 10.2136 14.5521 10.5727 14.8178 10.7722Z"
      fill="currentColor"
    />
    <path
      d="M16.9815 10.1339L17.323 10.9319C17.4748 11.2112 17.7784 11.3708 18.082 11.2112C18.3856 11.0516 18.4995 10.7324 18.3477 10.4132L18.0061 9.61517C17.8543 9.33586 17.5507 9.17626 17.2471 9.33586C16.9815 9.49547 16.8676 9.85457 16.9815 10.1339Z"
      fill="currentColor"
    />
    <path
      d="M18.2333 8.1388C18.1954 8.458 18.3851 8.77721 18.6887 8.81711L19.3339 8.93681C19.6375 8.97671 19.9411 8.77721 19.9791 8.458C20.017 8.1388 19.8273 7.8196 19.5237 7.7797L18.8785 7.66C18.5749 7.5802 18.3092 7.8196 18.2333 8.1388Z"
      fill="currentColor"
    />
  </SvgIcon>
);
