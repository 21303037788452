import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const LightBulb: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 20"
    sx={{
      ...sx,
      width: '20px',
      height: '20px',
    }}
  >
    <g clipPath="url(#clip0_1269_3253)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.33268 15.001V17.501C8.33268 17.9612 8.70578 18.3343 9.16602 18.3343H10.8327C11.2929 18.3343 11.666 17.9612 11.666 17.501V15.001H13.3327V17.501C13.3327 18.8817 12.2134 20.001 10.8327 20.001H9.16602C7.7853 20.001 6.66602 18.8817 6.66602 17.501V15.001H8.33268Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5007 11.0721L13.1249 10.5717C14.2713 9.65265 15.0007 8.24588 15.0007 6.66764C15.0007 3.90622 12.7621 1.66764 10.0007 1.66764C7.23923 1.66764 5.00065 3.90622 5.00065 6.66764C5.00065 8.24588 5.72995 9.65265 6.87643 10.5717L7.50065 11.0721V13.3343C7.50065 13.7945 7.87375 14.1676 8.33398 14.1676H11.6673C12.1276 14.1676 12.5007 13.7945 12.5007 13.3343V11.0721ZM14.1673 11.8721V13.3343C14.1673 14.715 13.048 15.8343 11.6673 15.8343H8.33398C6.95327 15.8343 5.83398 14.715 5.83398 13.3343V11.8721C4.30988 10.6504 3.33398 8.77296 3.33398 6.66764C3.33398 2.98574 6.31875 0.000976562 10.0007 0.000976562C13.6825 0.000976562 16.6673 2.98574 16.6673 6.66764C16.6673 8.77296 15.6914 10.6504 14.1673 11.8721Z"
        fill="currentColor"
      />
      <path
        d="M8.92259 6.9118C8.59715 6.58637 8.06951 6.58637 7.74408 6.9118C7.41864 7.23724 7.41864 7.76488 7.74408 8.09031L9.16667 9.5129V14.1677H10.8333V9.5129L12.2559 8.09031C12.5814 7.76488 12.5814 7.23724 12.2559 6.9118C11.9305 6.58637 11.4028 6.58637 11.0774 6.9118L10 7.98921L8.92259 6.9118Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1269_3253">
        <rect width="20" height="20" fill="white" transform="translate(0 0.000976562)" />
      </clipPath>
    </defs>
  </SvgIcon>
);
