import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Twitter: FC<SvgIconProps> = ({ sx, fill = 'transparent', ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 14 14"
    sx={{
      width: '22px',
      height: '18px',
      ...sx,
    }}
  >
    <g clipPath="url(#clip0_1557_1614)">
      <path
        d="M13.9157 2.56703C13.8393 2.49292 13.7298 2.46848 13.63 2.50315L13.1579 2.66606C13.436 2.32955 13.6412 1.97809 13.7492 1.64491C13.7806 1.54779 13.7521 1.44021 13.6768 1.37109C13.601 1.3015 13.4911 1.28179 13.3956 1.32169C12.7335 1.59932 12.1476 1.84107 11.6817 1.9857C11.6644 1.99117 11.6466 1.98879 11.6288 1.9788C11.2573 1.77076 10.3276 1.29296 9.66718 1.30057C7.99699 1.33119 6.6379 2.75845 6.6379 4.4823V4.57658C4.17856 4.10044 2.81947 3.39298 1.34782 1.84914L1.11795 1.60786L0.964999 1.90398C0.166138 3.44927 0.732527 4.83425 1.66606 5.76707C1.25237 5.70366 0.951946 5.57045 0.699031 5.34982C0.60191 5.26576 0.464151 5.2553 0.355865 5.32418C0.249248 5.3921 0.200564 5.51916 0.23499 5.64026C0.549176 6.75191 1.39056 7.66478 2.27875 8.21026C1.84962 8.20979 1.4965 8.16204 1.14145 7.93384C1.03815 7.86734 0.903727 7.87423 0.807318 7.95045C0.71162 8.02644 0.674813 8.15468 0.715642 8.26986C1.14857 9.48575 1.95813 10.1253 3.29965 10.2846C2.61001 10.6879 1.7152 11.0066 0.3181 11.0358C0.181791 11.0386 0.0611352 11.1272 0.0174595 11.2559C-0.0262163 11.3842 0.013683 11.5219 0.118877 11.6064C0.967873 12.2908 2.8722 12.6996 5.21304 12.6996C9.40337 12.6996 12.8124 8.97089 12.8124 4.38778V4.30727C13.3546 4.04817 13.7682 3.5369 13.9869 2.84939C14.0188 2.74917 13.9915 2.64089 13.9157 2.56703Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1557_1614">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
