import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ImageIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 16 17"
    sx={{
      width: '16px',
      height: '17px',
      ...sx,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00065 3.16762C6.69916 3.16762 5.49943 3.27496 4.53646 3.40214C3.6698 3.5166 3.01629 4.17011 2.90183 5.03676C2.77465 5.99973 2.66732 7.19947 2.66732 8.50096C2.66732 9.80244 2.77465 11.0022 2.90183 11.9652C3.01629 12.8318 3.6698 13.4853 4.53646 13.5998C5.49943 13.727 6.69916 13.8343 8.00065 13.8343C9.30214 13.8343 10.5019 13.727 11.4648 13.5998C12.3315 13.4853 12.985 12.8318 13.0995 11.9652C13.2266 11.0022 13.334 9.80244 13.334 8.50096C13.334 7.19947 13.2266 5.99973 13.0995 5.03676C12.985 4.17011 12.3315 3.5166 11.4648 3.40214C10.5019 3.27496 9.30214 3.16762 8.00065 3.16762ZM4.36188 2.08028C2.89735 2.27371 1.7734 3.39765 1.57998 4.86218C1.44701 5.86901 1.33398 7.12847 1.33398 8.50096C1.33398 9.87344 1.44701 11.1329 1.57998 12.1397C1.7734 13.6043 2.89735 14.7282 4.36188 14.9216C5.36871 15.0546 6.62817 15.1676 8.00065 15.1676C9.37313 15.1676 10.6326 15.0546 11.6394 14.9216C13.104 14.7282 14.2279 13.6043 14.4213 12.1397C14.5543 11.1329 14.6673 9.87344 14.6673 8.50096C14.6673 7.12847 14.5543 5.86901 14.4213 4.86218C14.2279 3.39765 13.104 2.27371 11.6394 2.08028C10.6326 1.94731 9.37314 1.83429 8.00065 1.83429C6.62817 1.83429 5.36871 1.94731 4.36188 2.08028Z"
      fill="currentColor"
    />
    <path
      d="M6.00041 7.83433C6.73679 7.83433 7.33374 7.23738 7.33374 6.501C7.33374 5.76462 6.73679 5.16766 6.00041 5.16766C5.26403 5.16766 4.66707 5.76462 4.66707 6.501C4.66707 7.23738 5.26403 7.83433 6.00041 7.83433Z"
      fill="currentColor"
    />
    <path
      d="M12.0813 8.97253L13.2775 10.1687C13.2362 10.7929 13.176 11.372 13.1095 11.8864L11.1385 9.91534C10.8781 9.65498 10.456 9.65498 10.1957 9.91533L8.74795 11.363C7.9669 12.1441 6.70057 12.1441 5.91952 11.363L5.80514 11.2487C5.54479 10.9883 5.12268 10.9883 4.86233 11.2487L3.25629 12.8547C3.07024 12.6025 2.94576 12.2997 2.90158 11.9652C2.87749 11.7828 2.8541 11.5918 2.83203 11.3934L3.91953 10.3059C4.70057 9.52481 5.9669 9.52481 6.74795 10.3059L6.86233 10.4202C7.12268 10.6806 7.54479 10.6806 7.80514 10.4202L9.25286 8.97252C10.0339 8.19148 11.3002 8.19148 12.0813 8.97253Z"
      fill="currentColor"
    />
  </SvgIcon>
);
