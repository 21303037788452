import { Download, DownloadCloud, Help, Repair } from 'components/Icon/components';

export const advantagesCards = [
  {
    title: 'Free',
    text: 'Only two download',
    Icon: Download,
  },
  {
    title: 'Unlimited',
    text: 'Personal & commercial use',
    Icon: DownloadCloud,
  },
  {
    title: 'Support',
    text: 'Personal data collection',
    Icon: Help,
  },
  {
    title: 'Private',
    text: 'Personal data collection',
    Icon: Repair,
  },
];
