import { FC } from 'react';
import { useShallowSelector } from 'hooks';
import userSelector from 'store/user/selectors';

import IconDark from './imageDark.png';
import IconLight from './imageLight.png';

export const OkxLogo: FC = () => {
  const isLight = useShallowSelector(userSelector.getProp('theme')) === 'light';

  return <img src={isLight ? IconDark : IconLight} alt="okx logo" />;
};
