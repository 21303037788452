import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Refresh: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 14 14"
    sx={{
      ...sx,
      width: '14px',
      height: '14px',
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 0C1.55228 0 2 0.447715 2 1V2.10126C3.27003 0.805019 5.04047 0 7 0C10.5704 0 13.5152 2.67221 13.9459 6.12626C14.0142 6.6743 13.6254 7.17397 13.0773 7.24231C12.5293 7.31065 12.0296 6.92178 11.9613 6.37374C11.6538 3.90832 9.54901 2 7 2C5.48019 2 4.11754 2.67824 3.19995 3.75H4.75C5.30228 3.75 5.75 4.19772 5.75 4.75C5.75 5.30228 5.30228 5.75 4.75 5.75H1C0.447715 5.75 0 5.30228 0 4.75V1C0 0.447715 0.447715 0 1 0ZM0.922681 6.75769C1.47072 6.68935 1.9704 7.07822 2.03874 7.62626C2.34617 10.0917 4.45099 12 7 12C8.51981 12 9.88246 11.3218 10.8 10.25H9.25C8.69772 10.25 8.25 9.80229 8.25 9.25C8.25 8.69772 8.69772 8.25 9.25 8.25H13C13.5523 8.25 14 8.69772 14 9.25V13C14 13.5523 13.5523 14 13 14C12.4477 14 12 13.5523 12 13V11.8987C10.73 13.195 8.95953 14 7 14C3.42958 14 0.484819 11.3278 0.0541058 7.87374C-0.0142338 7.3257 0.37464 6.82603 0.922681 6.75769Z"
      fill="currentColor"
    />
  </SvgIcon>
);
