import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Mounts: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 18"
    sx={{
      ...sx,
      width: '20px',
      height: '18px',
    }}
  >
    <path
      d="M6.50205 17.045C6.35005 16.7555 6.35005 16.4246 6.50205 16.135L6.65405 15.8455H2.55005L5.28605 10.882L6.35005 12.7847C6.65405 13.3224 7.33805 13.3224 7.64205 12.826C7.79405 12.5778 7.79405 12.2056 7.64205 11.9574L6.00805 9.02066C5.85605 8.73112 5.59005 8.56567 5.28605 8.56567C4.98205 8.56567 4.71605 8.73112 4.56405 9.02066L0.612047 16.135C0.460047 16.4246 0.460047 16.7555 0.612047 17.045C0.764047 17.3345 1.03005 17.5 1.33405 17.5H7.22405C6.92005 17.5 6.65405 17.2932 6.50205 17.045Z"
      fill="currentColor"
    />
    <path
      d="M19.3838 16.1351L13.6838 5.83585C13.5318 5.54631 13.2658 5.38086 12.9618 5.38086C12.6578 5.38086 12.3918 5.54631 12.2398 5.83585L6.69178 15.8456L6.53978 16.1351C6.38778 16.4247 6.38778 16.7556 6.53978 17.0451C6.69178 17.3346 6.95778 17.5001 7.26178 17.5001H10.7578H18.6998C19.0038 17.5001 19.2698 17.3346 19.4218 17.0451C19.5358 16.7556 19.5358 16.3833 19.3838 16.1351ZM12.9618 7.73852L17.4458 15.8456H8.43978L12.9618 7.73852Z"
      fill="currentColor"
    />
    <path
      d="M6.5778 6.41484C8.0598 6.41484 9.2758 5.09124 9.2758 3.4781C9.2758 1.8236 8.0598 0.5 6.5778 0.5C5.0578 0.5 3.8418 1.8236 3.8418 3.43674C3.8418 5.09124 5.0578 6.41484 6.5778 6.41484ZM6.5778 2.1545C7.2238 2.1545 7.7558 2.73358 7.7558 3.43674C7.7558 4.1399 7.2238 4.71898 6.5778 4.71898C5.9318 4.71898 5.3998 4.1399 5.3998 3.43674C5.3998 2.73358 5.8938 2.1545 6.5778 2.1545Z"
      fill="currentColor"
    />
    <path
      d="M6.50205 17.045C6.35005 16.7555 6.35005 16.4246 6.50205 16.135L6.65405 15.8455H2.55005L5.28605 10.882L6.35005 12.7847C6.65405 13.3224 7.33805 13.3224 7.64205 12.826C7.79405 12.5778 7.79405 12.2056 7.64205 11.9574L6.00805 9.02066C5.85605 8.73112 5.59005 8.56567 5.28605 8.56567C4.98205 8.56567 4.71605 8.73112 4.56405 9.02066L0.612047 16.135C0.460047 16.4246 0.460047 16.7555 0.612047 17.045C0.764047 17.3345 1.03005 17.5 1.33405 17.5H7.22405C6.92005 17.5 6.65405 17.2932 6.50205 17.045Z"
      fill="currentColor"
    />
    <path
      d="M19.3838 16.1351L13.6838 5.83585C13.5318 5.54631 13.2658 5.38086 12.9618 5.38086C12.6578 5.38086 12.3918 5.54631 12.2398 5.83585L6.69178 15.8456L6.53978 16.1351C6.38778 16.4247 6.38778 16.7556 6.53978 17.0451C6.69178 17.3346 6.95778 17.5001 7.26178 17.5001H10.7578H18.6998C19.0038 17.5001 19.2698 17.3346 19.4218 17.0451C19.5358 16.7556 19.5358 16.3833 19.3838 16.1351ZM12.9618 7.73852L17.4458 15.8456H8.43978L12.9618 7.73852Z"
      fill="currentColor"
    />
    <path
      d="M6.5778 6.41484C8.0598 6.41484 9.2758 5.09124 9.2758 3.4781C9.2758 1.8236 8.0598 0.5 6.5778 0.5C5.0578 0.5 3.8418 1.8236 3.8418 3.43674C3.8418 5.09124 5.0578 6.41484 6.5778 6.41484ZM6.5778 2.1545C7.2238 2.1545 7.7558 2.73358 7.7558 3.43674C7.7558 4.1399 7.2238 4.71898 6.5778 4.71898C5.9318 4.71898 5.3998 4.1399 5.3998 3.43674C5.3998 2.73358 5.8938 2.1545 6.5778 2.1545Z"
      fill="currentColor"
    />
  </SvgIcon>
);
