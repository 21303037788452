import { Fragment, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
// import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Divider,
  Grid,
  MenuItem,
  Stack,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { routes } from 'appConstants';
import { FormButtons } from 'components';
import { FormSkeleton } from 'components/FormSkeleton';
import { useGetQuery, useRedirectHook, useShallowSelector } from 'hooks';
import { socialMediaFormFields } from 'modules/layout/containers';
import apiActions from 'store/api/actions';
import { editGame, getGame } from 'store/games/actions';
import actionTypes from 'store/games/actionTypes';
import gamesSelectors from 'store/games/selectors';
import uiSelector from 'store/ui/selectors';
import { BORDER_NEUTRALS_6, TextFieldLabel } from 'theme/variables';
import { RequestStatus } from 'types';
import { flexHelper } from 'utils';

import { PhotoUploader } from './components/PhotoUploader';
import { VideoInput } from './components/VideoInput';
import {
  backgroundColorsVariants,
  gameOverviewFields,
  gameValidationSchema,
  IEditGameFormInputs,
  videoFields,
} from './EditGame.helper';

export const InputField = styled(TextField)({
  width: '100%',
});

export const InputLabel = styled(TextFieldLabel)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

interface Params {
  id: string;
}

export const EditGame = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: gameName } = useParams<keyof Params>() as Params;
  const currentNetwork = useGetQuery('network');
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const isMobileTablet = useMediaQuery(
    theme.breakpoints.between(theme.breakpoints.values.sm, theme.breakpoints.values.md),
  );

  const { [actionTypes.EDIT_GAME]: editGameRequest, [actionTypes.GET_GAME]: getGameRequest } = useShallowSelector(
    uiSelector.getUI,
  );
  const isStatusRequest = editGameRequest === RequestStatus.REQUEST;
  const isGetGameRequest = getGameRequest === RequestStatus.REQUEST;

  const isStatusSuccess = editGameRequest === RequestStatus.SUCCESS;

  const { game } = useShallowSelector(gamesSelectors.getGames);

  useEffect(() => {
    dispatch(getGame({ gameName, network: currentNetwork }));
  }, [dispatch, gameName]);

  const onSubmitForm = (data: IEditGameFormInputs) => {
    dispatch(
      editGame({
        data: {
          website: data.site,
          ...data,
        } as any,
        id: gameName,
        network: currentNetwork,
      }),
    );
  };

  const handleCancelClick = useCallback(() => {
    if (game?.name) {
      navigate(routes.games.game.root.getPath(game.name, currentNetwork));
    }
  }, [currentNetwork, navigate]);

  const {
    register,
    control,
    watch,
    setValue,
    trigger,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<IEditGameFormInputs>({
    resolver: yupResolver(gameValidationSchema),
    mode: 'onChange',
  });

  const defaultColorValue = backgroundColorsVariants.find(({ label }) => label === 'Dark')?.value;

  useEffect(() => {
    reset({
      name: game?.name || '',
      email: game?.email || '',
      description: game?.description || '',
      backgroundColor: game?.backgroundColor || '',
      twitter: game?.twitter || '',
      telegram: game?.telegram || '',
      site: game?.website || '',
      medium: game?.medium || '',
      instagram: game?.instagram || '',
      discord: game?.discord || '',

      photos: (game?.photos || []).map((url) => ({ url, display: true })),

      video1: game?.videos?.[0] || '',
      video2: game?.videos?.[1] || '',
      video3: game?.videos?.[2] || '',
      videoCover1: game?.videoCovers?.[0],
      videoCover2: game?.videoCovers?.[1],
      videoCover3: game?.videoCovers?.[2],

      developer: game?.developer || '',
      releaseStatus: game?.releaseStatus || '',
      platform: game?.platform || '',
      genre: game?.genre || '',
    });
  }, [game, reset]);

  useEffect(() => {
    if (isStatusSuccess) {
      handleCancelClick();
      dispatch(apiActions.reset(actionTypes.EDIT_GAME));
    }
  }, [dispatch, handleCancelClick, isStatusSuccess]);

  useRedirectHook({ id: Number(game?.user?.id), isEntityLoaded: Boolean(game) });

  return (
    <Box sx={{ pb: 10 }}>
      <Typography sx={{ mb: 7 }} variant="h2">
        Edit game page
      </Typography>
      {isGetGameRequest ? (
        <FormSkeleton withLabels />
      ) : (
        <Box component="form" onSubmit={handleSubmit(onSubmitForm)}>
          <Typography variant="body2" sx={{ color: theme.themeColors.colorTabItemTextActive }}>
            Information
          </Typography>

          <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 4, md: 14 }}>
            <Box sx={{ maxWidth: '443px', width: '100%' }}>
              <InputLabel>{errors.name?.message || 'Name'}</InputLabel>
              <InputField
                error={Boolean(errors.name)}
                placeholder="e. g. “Redeemable Bitcoin Card with logo”"
                {...register('name')}
              />
              <InputLabel>{errors.email?.message || 'Contact email'}</InputLabel>
              <InputField error={Boolean(errors.email)} placeholder="enricocole@gmail.com" {...register('email')} />

              <InputLabel>{errors.backgroundColor?.message || 'Background color'}</InputLabel>
              <TextField
                {...register('backgroundColor')}
                select
                sx={{ width: '100%' }}
                error={Boolean(errors.backgroundColor)}
                value={watch('backgroundColor') || defaultColorValue}
              >
                {backgroundColorsVariants.map((color) => (
                  <MenuItem key={color.label} value={color.value} sx={{ ...flexHelper('flex-start', 'center') }}>
                    <Box
                      sx={{
                        width: 25,
                        height: 25,
                        marginRight: 1,
                        background: color.value,
                        borderRadius: '50%',
                        border: BORDER_NEUTRALS_6,
                      }}
                    />
                    {color.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>

            <Box sx={{ maxWidth: '443px', width: '100%', display: 'flex', flexDirection: 'column' }}>
              <InputLabel>{errors.description?.message || 'Description'}</InputLabel>
              <InputField
                multiline
                rows={5}
                error={Boolean(errors.description)}
                placeholder="e. g. “After purchasing you will able to recived the logo...”"
                {...register('description')}
                sx={{
                  height: '100%',
                  '.MuiInputBase-root': { height: '100%' },
                  textarea: { height: { md: '100% !important' } },
                }}
              />
            </Box>
          </Stack>

          <Divider sx={{ my: 4 }} />

          <Typography variant="body2" sx={{ color: theme.themeColors.colorTabItemTextActive, marginBottom: 1.5 }}>
            Photo
          </Typography>
          <Typography variant="body2" sx={{ marginBottom: 3 }}>
            Up to 15 pictures
          </Typography>
          <PhotoUploader getPhotos={() => watch('photos')} onChange={(newPhotos) => setValue('photos', newPhotos)} />

          <Divider sx={{ my: 4 }} />

          <Typography variant="body2" sx={{ color: theme.themeColors.colorTabItemTextActive }}>
            Video
          </Typography>

          <Grid container spacing={(isMobileTablet && 3) || (isMobile && 1) || 3}>
            {videoFields.map(({ name, placeholder, coverName }) => {
              const videoProps = register(name);
              return (
                <Grid key={name} item xs={(isMobileTablet && 4) || (isMobile && 12) || 4}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <InputLabel sx={{ marginBottom: 1.5 }}>{errors[name]?.message || 'Youtube/vimeo'}</InputLabel>
                    <InputField
                      {...videoProps}
                      onChange={(event) => {
                        videoProps?.onChange(event);
                        trigger(coverName);
                      }}
                      error={!!errors[name]}
                      placeholder={placeholder}
                      sx={{ marginBottom: 3 }}
                    />
                    <VideoInput
                      // {...register(coverName)}
                      value={watch(coverName) as string}
                      error={errors[coverName]?.message}
                      onChange={(newValue) => {
                        setValue(coverName, newValue);
                        // TODO: rewrite using useControl
                        // force trigger validation on unregistered cover and linked video fields
                        trigger([name, coverName]);
                      }}
                    />
                  </Box>
                </Grid>
              );
            })}
          </Grid>

          <Divider sx={{ my: 4 }} />

          <Typography variant="body2" sx={{ color: theme.themeColors.colorTabItemTextActive }}>
            Game Overview
          </Typography>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 0, md: 14 }}>
            <Box sx={{ maxWidth: '443px', width: '100%' }}>
              {gameOverviewFields.slice(0, 2).map(({ name, label }) => (
                <Fragment key={name}>
                  <InputLabel>{errors[name]?.message || label}</InputLabel>
                  <InputField {...register(name)} error={!!errors[name]} />
                </Fragment>
              ))}
            </Box>
            <Box sx={{ maxWidth: '443px', width: '100%' }}>
              {gameOverviewFields.slice(2).map(({ name, label }) => (
                <Fragment key={name}>
                  <InputLabel>{errors[name]?.message || label}</InputLabel>
                  <InputField {...register(name)} error={!!errors[name]} />
                </Fragment>
              ))}
            </Box>
          </Stack>

          <Divider sx={{ my: 4 }} />

          <Typography variant="body2" sx={{ color: theme.themeColors.colorTabItemTextActive }}>
            Social media
          </Typography>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 0, md: 14 }}>
            <Box sx={{ maxWidth: '443px', width: '100%' }}>
              {socialMediaFormFields.slice(0, 3).map(({ name, label, placeholder }) => (
                <Fragment key={name}>
                  <InputLabel>{errors[name]?.message || label}</InputLabel>
                  <InputField {...register(name)} error={!!errors[name]} placeholder={placeholder} />
                </Fragment>
              ))}
            </Box>
            <Box sx={{ maxWidth: '443px', width: '100%' }}>
              {socialMediaFormFields.slice(3).map(({ name, label, placeholder }) => (
                <Fragment key={name}>
                  <InputLabel>{errors[name]?.message || label}</InputLabel>
                  <InputField {...register(name)} error={!!errors[name]} placeholder={placeholder} />
                </Fragment>
              ))}
            </Box>
          </Stack>

          <FormButtons
            isEditPage
            onCancelClick={handleCancelClick}
            isStatusRequest={isStatusRequest}
            sx={{ marginTop: { xs: 4, sm: 7.5, md: 11 } }}
          />

          {/* <DevTool control={control} /> */}
        </Box>
      )}
    </Box>
  );
};
