import { COLOR_NEUTRALS_1, COLOR_NEUTRALS_3, COLOR_NEUTRALS_6, COLOR_NEUTRALS_6_LIGHT } from 'theme/colors';

export const cardColors = {
  light: {
    colorCardBackground: COLOR_NEUTRALS_6_LIGHT,
    colorCardBorder: COLOR_NEUTRALS_6,
    colorCardBoxShadow: 'none',
  },
  dark: {
    colorCardBackground: COLOR_NEUTRALS_1,
    colorCardBorder: COLOR_NEUTRALS_3,
    colorCardBoxShadow: 'none',
  },
};
