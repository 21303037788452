import { COLOR_NEUTRALS_1, COLOR_NEUTRALS_2, COLOR_NEUTRALS_6, COLOR_NEUTRALS_6_LIGHT } from '../colors.constant';

export const colorsFollowingCardLight = {
  colorsFollowingCardBackground: COLOR_NEUTRALS_6_LIGHT,
  colorFollowingCardBackgroundHover: COLOR_NEUTRALS_6,
  colorFollowingCardBackgroundFocus: COLOR_NEUTRALS_6,
};

export const colorsFollowingCardDark = {
  colorsFollowingCardBackground: COLOR_NEUTRALS_1,
  colorFollowingCardBackgroundHover: COLOR_NEUTRALS_2,
  colorFollowingCardBackgroundFocus: COLOR_NEUTRALS_2,
};
