import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const BlueCheck: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 25 25"
    sx={{
      width: '25px',
      height: '25px',
      ...sx,
    }}
  >
    <path
      d="M9.53802 1.95942C10.6026 0.0135253 13.3974 0.0135258 14.462 1.95942C15.0947 3.11599 16.4472 3.67621 17.7124 3.3058C19.8412 2.68261 21.8174 4.65885 21.1942 6.78757C20.8238 8.0528 21.384 9.40528 22.5406 10.038C24.4865 11.1026 24.4865 13.8974 22.5406 14.962C21.384 15.5947 20.8238 16.9472 21.1942 18.2124C21.8174 20.3412 19.8412 22.3174 17.7124 21.6942C16.4472 21.3238 15.0947 21.884 14.462 23.0406C13.3974 24.9865 10.6026 24.9865 9.53802 23.0406C8.90528 21.884 7.5528 21.3238 6.28757 21.6942C4.15885 22.3174 2.18261 20.3412 2.8058 18.2124C3.17621 16.9472 2.61599 15.5947 1.45942 14.962C-0.486474 13.8974 -0.486475 11.1026 1.45942 10.038C2.61599 9.40528 3.17621 8.0528 2.80581 6.78757C2.18261 4.65885 4.15885 2.68261 6.28757 3.30581C7.5528 3.67621 8.90528 3.11599 9.53802 1.95942Z"
      fill="#3772FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6946 8.70503C16.968 8.97839 16.968 9.42161 16.6946 9.69497L11.0946 15.295C10.8212 15.5683 10.378 15.5683 10.1046 15.295L7.30463 12.495C7.03127 12.2216 7.03127 11.7784 7.30463 11.505C7.578 11.2317 8.02122 11.2317 8.29458 11.505L10.5996 13.8101L15.7046 8.70503C15.978 8.43166 16.4212 8.43166 16.6946 8.70503Z"
      fill="white"
    />
  </SvgIcon>
);
