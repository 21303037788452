import { snakeize } from 'utils';

import { TEditCollection } from '.';

export const convertToFormRequestData = (data: TEditCollection & { cover: File }) => {
  const formData = new FormData();
  if (data.description) {
    formData.append('description', String(data?.description));
  }
  if (data.creatorRoyalty) {
    formData.append('creator_royalty', String(data?.creatorRoyalty));
  }
  Object.entries(snakeize(data.socials)).forEach(([key, value]: [string, any]) => {
    formData.append(key, value);
  });
  if (data.avatar) {
    formData.set('avatar', data?.avatar as Blob);
  }
  if (data.cover) {
    formData.set('cover', data?.cover as File);
  }

  return formData;
};
