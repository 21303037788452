import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Close: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    sx={{
      width: '24px',
      height: '24px',
      ...sx,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99935 16.6667C13.6812 16.6667 16.666 13.682 16.666 10.0001C16.666 6.31818 13.6812 3.33341 9.99935 3.33341C6.31745 3.33341 3.33268 6.31818 3.33268 10.0001C3.33268 13.682 6.31745 16.6667 9.99935 16.6667ZM9.99935 18.3334C14.6017 18.3334 18.3327 14.6025 18.3327 10.0001C18.3327 5.39771 14.6017 1.66675 9.99935 1.66675C5.39698 1.66675 1.66602 5.39771 1.66602 10.0001C1.66602 14.6025 5.39698 18.3334 9.99935 18.3334Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.91009 6.91083C7.23553 6.58539 7.76317 6.58539 8.0886 6.91083L9.99935 8.82157L11.9101 6.91083C12.2355 6.58539 12.7632 6.58539 13.0886 6.91083C13.414 7.23626 13.414 7.7639 13.0886 8.08934L11.1779 10.0001L13.0886 11.9108C13.414 12.2363 13.414 12.7639 13.0886 13.0893C12.7632 13.4148 12.2355 13.4148 11.9101 13.0893L9.99935 11.1786L8.0886 13.0893C7.76317 13.4148 7.23553 13.4148 6.91009 13.0893C6.58466 12.7639 6.58466 12.2363 6.91009 11.9108L8.82084 10.0001L6.91009 8.08934C6.58466 7.7639 6.58466 7.23626 6.91009 6.91083Z"
      fill="currentColor"
    />
  </SvgIcon>
);
