import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Facebook: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 8 15"
    sx={{
      width: '8px',
      height: '15px',
      ...sx,
    }}
  >
    <path
      d="M7.49949 0.503401L5.68403 0.500488C3.64441 0.500488 2.32632 1.85282 2.32632 3.94591V5.53448H0.500949C0.343216 5.53448 0.215485 5.66235 0.215485 5.82009V8.12174C0.215485 8.27948 0.343361 8.40721 0.500949 8.40721H2.32632V14.215C2.32632 14.3728 2.45405 14.5005 2.61178 14.5005H4.99338C5.15111 14.5005 5.27884 14.3726 5.27884 14.215V8.40721H7.41313C7.57086 8.40721 7.69859 8.27948 7.69859 8.12174L7.69946 5.82009C7.69946 5.74435 7.66931 5.67182 7.61586 5.61822C7.56241 5.56462 7.48959 5.53448 7.41385 5.53448H5.27884V4.18783C5.27884 3.54057 5.43308 3.21199 6.27622 3.21199L7.4992 3.21156C7.65679 3.21156 7.78452 3.08368 7.78452 2.92609V0.788869C7.78452 0.631425 7.65693 0.503693 7.49949 0.503401Z"
      fill="currentColor"
    />
  </SvgIcon>
);
