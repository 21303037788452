import {
  COLOR_NEUTRALS_1,
  COLOR_NEUTRALS_2,
  COLOR_NEUTRALS_3,
  COLOR_NEUTRALS_4,
  COLOR_NEUTRALS_5,
  COLOR_NEUTRALS_6,
  COLOR_NEUTRALS_7,
  COLOR_NEUTRALS_8,
  COLOR_PRIMARY_1,
} from '../colors.constant';

export const colorsNftCardLight = {
  colorNftCardBackgroundDefault: COLOR_NEUTRALS_7,
  colorNftCardBackgroundHover: COLOR_NEUTRALS_6,
  colorNftCardBackgroundFocus: COLOR_NEUTRALS_6,
  colorNftCardBorderDefault: `1px solid ${COLOR_NEUTRALS_6}`,
  colorNftCardBorderFocus: `1px solid ${COLOR_NEUTRALS_5}`,
  colorNftCardNameText: COLOR_NEUTRALS_3,
  colorNftCardBidText: COLOR_NEUTRALS_4,
  colorNftCardPriceBorder: `2px solid ${COLOR_PRIMARY_1}`,
  colorNftCardIdBidBorder: `2px solid ${COLOR_NEUTRALS_6}`,
  colorNftCardIdText: COLOR_NEUTRALS_3,
  colorNftCardInStockText: COLOR_NEUTRALS_3,
};

export const colorsNftCardDark = {
  colorNftCardBackgroundDefault: COLOR_NEUTRALS_1,
  colorNftCardBackgroundHover: COLOR_NEUTRALS_2,
  colorNftCardBackgroundFocus: COLOR_NEUTRALS_2,
  colorNftCardBorderDefault: 'none',
  colorNftCardBorderFocus: `1px solid ${COLOR_NEUTRALS_4}`,
  colorNftCardNameText: COLOR_NEUTRALS_8,
  colorNftCardBidText: COLOR_NEUTRALS_4,
  colorNftCardPriceBorder: `2px solid ${COLOR_PRIMARY_1}`,
  colorNftCardIdBidBorder: `2px solid ${COLOR_NEUTRALS_3}`,
  colorNftCardIdText: COLOR_NEUTRALS_4,
  colorNftCardInStockText: COLOR_NEUTRALS_6,
};
