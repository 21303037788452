export enum ActivityTabs {
  MyActivity = '',
  Follow = 'follow',
}

export const activityTabs = [
  {
    label: 'My activity',
    value: '',
  },
  {
    label: 'Follows',
    value: 'follow',
  },
];
