import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Donut: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    sx={{
      width: '24px',
      height: '24px',
      ...sx,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12ZM14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
      fill="currentColor"
    />
    <path
      d="M6.05159 4.6838C6.22624 4.15986 6.79256 3.8767 7.3165 4.05134L10.3165 5.05134C10.8404 5.22599 11.1236 5.79231 10.949 6.31625C10.7743 6.8402 10.208 7.12336 9.68404 6.94871L6.68404 5.94871C6.1601 5.77406 5.87694 5.20774 6.05159 4.6838Z"
      fill="currentColor"
    />
    <path
      d="M6.10579 10.5528C6.35278 10.0588 6.95345 9.85856 7.44743 10.1055L9.44743 11.1055C9.94141 11.3525 10.1416 11.9532 9.89465 12.4472C9.64766 12.9412 9.04698 13.1414 8.55301 12.8944L6.55301 11.8944C6.05903 11.6474 5.8588 11.0467 6.10579 10.5528Z"
      fill="currentColor"
    />
    <path
      d="M13.7074 19.7071C14.0979 19.3166 14.0979 18.6834 13.7074 18.2929C13.3168 17.9024 12.6837 17.9024 12.2931 18.2929L10.7931 19.7929C10.4026 20.1834 10.4026 20.8166 10.7931 21.2071C11.1837 21.5976 11.8168 21.5976 12.2074 21.2071L13.7074 19.7071Z"
      fill="currentColor"
    />
    <path
      d="M13.7931 13.7929C14.1837 13.4024 14.8168 13.4024 15.2074 13.7929L15.7074 14.2929C16.0979 14.6834 16.0979 15.3166 15.7074 15.7071C15.3168 16.0976 14.6837 16.0976 14.2931 15.7071L13.7931 15.2071C13.4026 14.8166 13.4026 14.1834 13.7931 13.7929Z"
      fill="currentColor"
    />
    <path
      d="M7.89465 16.4472C8.14164 15.9532 7.94141 15.3525 7.44743 15.1055C6.95345 14.8586 6.35278 15.0588 6.10579 15.5528L5.10579 17.5528C4.8588 18.0467 5.05903 18.6474 5.55301 18.8944C6.04698 19.1414 6.64766 18.9412 6.89465 18.4472L7.89465 16.4472Z"
      fill="currentColor"
    />
    <path
      d="M21.8946 11.5528C22.1416 12.0467 21.9414 12.6474 21.4474 12.8944L19.4474 13.8944C18.9535 14.1414 18.3528 13.9412 18.1058 13.4472C17.8588 12.9532 18.059 12.3525 18.553 12.1055L20.553 11.1055C21.047 10.8586 21.6477 11.0588 21.8946 11.5528Z"
      fill="currentColor"
    />
    <path
      d="M15.949 8.31626C16.1236 7.79231 15.8404 7.22599 15.3165 7.05134C14.7926 6.8767 14.2262 7.15986 14.0516 7.6838L13.5516 9.1838C13.3769 9.70774 13.6601 10.2741 14.184 10.4487C14.708 10.6234 15.2743 10.3402 15.449 9.81625L15.949 8.31626Z"
      fill="currentColor"
    />
  </SvgIcon>
);
