import { COLOR_NEUTRALS_2, COLOR_NEUTRALS_4, COLOR_NEUTRALS_6 } from '../colors.constant';

export const colorsLikeButtonLight = {
  colorLikeButtonBackground: COLOR_NEUTRALS_6,
  colorLikeButtonFill: COLOR_NEUTRALS_4,
};

export const colorsLikeButtonDark = {
  colorLikeButtonBackground: COLOR_NEUTRALS_2,
  colorLikeButtonFill: COLOR_NEUTRALS_4,
};
