import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const HeartIcon: FC<SvgIconProps> = ({ sx, fill, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 18 15"
    sx={{
      width: '18px',
      height: '15px',
      ...sx,
      '& > path': {
        fill,
      },
    }}
  >
    <path
      d="M8.30107 2.25026L9 2.9333L9.69893 2.25026C10.424 1.54169 11.4018 1.11108 12.4773 1.11108C14.6819 1.11108 16.5 2.93618 16.5 5.22605C16.5 7.33829 15.3721 9.03238 13.8699 10.3605C12.3656 11.6904 10.5828 12.5619 9.53024 13.0028C9.18568 13.1472 8.81432 13.1472 8.46976 13.0028C7.41725 12.5619 5.63436 11.6904 4.1301 10.3605C2.6279 9.03239 1.5 7.33829 1.5 5.22605C1.5 2.93618 3.31811 1.11108 5.52273 1.11108C6.59818 1.11108 7.57602 1.54169 8.30107 2.25026Z"
      stroke="currentColor"
      strokeWidth="2"
      fill="transparent"
    />
  </SvgIcon>
);
