import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Mail: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 16 16"
    sx={{
      width: '16px',
      height: '16px',
      ...sx,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8327 3.33333H3.16602C2.79783 3.33333 2.49935 3.63181 2.49935 4V12C2.49935 12.3682 2.79783 12.6667 3.16602 12.6667H13.8327C14.2009 12.6667 14.4993 12.3682 14.4993 12V4C14.4993 3.63181 14.2009 3.33333 13.8327 3.33333ZM3.16602 2C2.06145 2 1.16602 2.89543 1.16602 4V12C1.16602 13.1046 2.06145 14 3.16602 14H13.8327C14.9373 14 15.8327 13.1046 15.8327 12V4C15.8327 2.89543 14.9373 2 13.8327 2H3.16602Z"
      fill="#8E87AF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.98852 4.90664C4.22423 4.62379 4.64461 4.58557 4.92746 4.82128L8.07388 7.4433C8.32111 7.64933 8.68023 7.64933 8.92746 7.4433L12.0739 4.82128C12.3567 4.58557 12.7771 4.62379 13.0128 4.90664C13.2485 5.18949 13.2103 5.60987 12.9275 5.84558L9.78104 8.4676C9.03934 9.08567 7.96199 9.08567 7.2203 8.4676L4.07388 5.84558C3.79103 5.60987 3.75281 5.18949 3.98852 4.90664Z"
      fill="#8E87AF"
    />
  </SvgIcon>
);
