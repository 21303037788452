import { COLOR_LIGHT_WHITE, COLOR_NEUTRALS_3, COLOR_NEUTRALS_4, COLOR_NEUTRALS_6 } from '../colors.constant';

export const colorsAvatarLight = {
  colorAvatarText: COLOR_NEUTRALS_3,
  colorAvatarBorderDefault: COLOR_NEUTRALS_6,
  colorAvatarBorderHover: COLOR_NEUTRALS_4,
};

export const colorsAvatarDark = {
  colorAvatarText: COLOR_LIGHT_WHITE,
  colorAvatarBorderDefault: COLOR_NEUTRALS_3,
  colorAvatarBorderHover: COLOR_NEUTRALS_4,
};
