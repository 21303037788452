import { COLOR_DARK_6, COLOR_NEUTRALS_7 } from 'theme/colors';

export const nftDetailsBidColors = {
  light: {
    colorsNftDetailsBidTimeLeftBackground: COLOR_NEUTRALS_7,
  },
  dark: {
    colorsNftDetailsBidTimeLeftBackground: COLOR_DARK_6,
  },
};
