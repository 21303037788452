import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const FacebookOutlined: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 20"
    sx={{
      width: '24px',
      height: '24px',
      ...sx,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99935 16.6667C13.6813 16.6667 16.666 13.682 16.666 10.0001C16.666 6.31818 13.6813 3.33341 9.99935 3.33341C6.31745 3.33341 3.33268 6.31818 3.33268 10.0001C3.33268 13.682 6.31745 16.6667 9.99935 16.6667ZM9.99935 18.3334C14.6017 18.3334 18.3327 14.6024 18.3327 10.0001C18.3327 5.39771 14.6017 1.66675 9.99935 1.66675C5.39697 1.66675 1.66602 5.39771 1.66602 10.0001C1.66602 14.6024 5.39697 18.3334 9.99935 18.3334Z"
      fill="#777E91"
    />
    <path
      d="M9.99935 8.33325C9.99935 7.87302 10.3724 7.49992 10.8327 7.49992H11.666C12.1263 7.49992 12.4993 7.12683 12.4993 6.66659C12.4993 6.20635 12.1263 5.83325 11.666 5.83325H10.8327C9.45193 5.83325 8.33268 6.95254 8.33268 8.33325V9.99992H7.49935C7.03912 9.99992 6.66602 10.373 6.66602 10.8333C6.66602 11.2935 7.03911 11.6666 7.49935 11.6666H8.33268V16.6666C8.33268 17.1268 8.70577 17.4999 9.16602 17.4999C9.62627 17.4999 9.99935 17.1268 9.99935 16.6666V11.6666H11.666C12.1263 11.6666 12.4993 11.2935 12.4993 10.8333C12.4993 10.373 12.1263 9.99992 11.666 9.99992H9.99935V8.33325Z"
      fill="#777E91"
    />
  </SvgIcon>
);
