const GET_GAMES_DATA = 'GET_GAMES_DATA';
export const EDIT_GAME = 'EDIT_GAME';
export const GET_GAMES_COLLECTIONS = 'GET_GAMES_COLLECTIONS';
export const LIST_GAME = 'LIST_GAME';
export const GET_GAME = 'GET_GAME';
export const UPDATE_GAME = 'UPDATE_GAME';
export const GET_CATEGORY = 'GET_CATEGORY';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const EDIT_CATEGORY = 'EDIT_CATEGORY';
export const ADD_SUBCATEGORY = 'ADD_SUBCATEGORY';
export const GET_SUBCATEGORY = 'GET_SUBCATEGORY';
export const ADD_COLLECTIONS = 'ADD_COLLECTIONS';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_SUBCATEGORY = 'DELETE_SUBCATEGORY';
export const DELETE_COLLECTION = 'DELETE_COLLECTION';
export const GET_OWNED_GAMES = 'GET_OWNED_GAMES';
export const GET_FILTER_GAMES = 'GET_FILTER_GAMES';
export const EDIT_SUBCATEGORY = 'EDIT_SUBCATEGORY';

const gamesActionTypes = {
  GET_GAMES_DATA,
  EDIT_GAME,
  GET_GAMES_COLLECTIONS,
  LIST_GAME,
  GET_GAME,
  UPDATE_GAME,
  GET_CATEGORY,
  ADD_CATEGORY,
  EDIT_CATEGORY,
  ADD_SUBCATEGORY,
  GET_SUBCATEGORY,
  ADD_COLLECTIONS,
  DELETE_CATEGORY,
  DELETE_SUBCATEGORY,
  DELETE_COLLECTION,
  GET_OWNED_GAMES,
  GET_FILTER_GAMES,
  EDIT_SUBCATEGORY,
};

export default gamesActionTypes;
