import { FC, useRef } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const PlayCircle: FC<SvgIconProps> = ({ sx, ...props }) => {
  const id = useRef(Math.floor(Math.random() * 10e4)).current;

  return (
    <SvgIcon
      {...props}
      viewBox="0 0 114 120"
      sx={{
        width: 114,
        height: 120,
        fill: 'none',
        ...sx,
      }}
    >
      <rect x="0.301758" y="0.207031" width="113.532" height="119.084" rx="56.7659" fill="black" fillOpacity="0.62" />
      <path
        d="M46.001 49.6001C46.001 46.4661 49.4416 44.5494 52.1064 46.199L68.507 56.3518C71.0331 57.9155 71.0331 61.5901 68.507 63.1539L52.1064 73.3066C49.4416 74.9562 46.001 73.0396 46.001 69.9056V49.6001Z"
        fill={`url(#paint0_linear_6316_${id})`}
      />
      <defs>
        <linearGradient
          id={`paint0_linear_6316_${id}`}
          x1="51.3934"
          y1="41.8536"
          x2="66.4577"
          y2="46.3695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6345ED" />
          <stop offset="1" stopColor="#E039FD" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};
