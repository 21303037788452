import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const VolumeMute: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    sx={{
      ...sx,
      width: '24px',
      height: '24px',
      path: {
        stroke: 'currentColor',
        fill: 'currentColor',
      },
    }}
  >
    <path
      d="M22.2929 7.29289C22.6834 6.90237 23.3166 6.90237 23.7071 7.29289C24.0976 7.68342 24.0976 8.31658 23.7071 8.70711L20.9142 11.5L23.7071 14.2929C24.0976 14.6834 24.0976 15.3166 23.7071 15.7071C23.3166 16.0976 22.6834 16.0976 22.2929 15.7071L19.5 12.9142L16.7071 15.7071C16.3166 16.0976 15.6834 16.0976 15.2929 15.7071C14.9024 15.3166 14.9024 14.6834 15.2929 14.2929L18.0858 11.5L15.2929 8.70711C14.9024 8.31658 14.9024 7.68342 15.2929 7.29289C15.6834 6.90237 16.3166 6.90237 16.7071 7.29289L19.5 10.0858L22.2929 7.29289Z"
      fill="currentColor"
    />
    <path
      d="M5 9.00005L9.63212 4.65744C10.9094 3.45994 13 4.36563 13 6.11651V17.8836C13 19.6345 10.9095 20.5402 9.63212 19.3427L5 15H2C0.89543 15 0 14.1046 0 13V11.0001C0 9.89548 0.895431 9.00005 2 9.00005H5Z"
      fill="currentColor"
    />
  </SvgIcon>
);
