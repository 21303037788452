import { FC } from 'react';
import { Box, Button, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { mockAva } from 'assets/images';
import { Avatar, Socials } from 'components';
import { EditCoverWrapper } from 'components/EditCoverWrapper';
import { PictureIcon } from 'components/Icon/components/PictureIcon';
import { chains } from 'services/WalletService/config';
import { COLOR_BG_35, COLOR_PRIMARY_1 } from 'theme/colors';
import { Chains } from 'types';
import { Currency } from 'types/api/Currency';
import { flexHelper, shortenPhrase, smartRound } from 'utils';

export type CategoryInfoHeaderProps = {
  name?: string;
  avatar?: string | null;
  subName: string;
  isAbleToEdit?: boolean;
  buttonText?: string;
  onAddMore?: () => void;
  onCoverSet?: (file: File) => void;
  network?: string;
  ownersCount?: number;
  tokensCount?: number;
  volumeTraded?: number;
  floorPrice?: number;
  site?: string;
  twitter?: string;
  instagram?: string;
  discord?: string;
  telegram?: string;
  medium?: string;
  currencyRates?: Currency[];
};

export const CategoryInfoHeader: FC<CategoryInfoHeaderProps> = ({
  name,
  subName,
  buttonText,
  avatar,
  isAbleToEdit = false,
  onAddMore,
  onCoverSet,
  network,
  ownersCount,
  tokensCount,
  volumeTraded,
  floorPrice,
  site,
  twitter,
  instagram,
  discord,
  telegram,
  medium,
  currencyRates,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

  const currencyRate = currencyRates?.find((token) => token.network === network)?.rate ?? 0;
  const currentChain = chains[network as Chains].mainnet;

  const blocks = [
    {
      title: 'Items',
      value: tokensCount ?? 0,
    },
    {
      title: 'Owners',
      value: ownersCount ?? 0,
    },
    {
      title: 'Floor price',
      value: `$${floorPrice && currencyRate ? smartRound(+(floorPrice ?? 0) * +(currencyRate ?? 0)) : 0}`,
    },
    {
      title: 'Total volume',
      value: `$${volumeTraded ?? 0}`,
      subValue: `~${currentChain?.nativeCurrency?.name} ${volumeTraded ?? 0}`,
    },
  ];

  return (
    <Box sx={{ zIndex: 11, marginTop: { xs: 0, md: isAbleToEdit ? -12 : 0 } }}>
      {isAbleToEdit && (
        <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end', marginBottom: 6 }}>
          <EditCoverWrapper onChange={onCoverSet}>
            <Button type="submit" variant="outlined" size="small" endIcon={<PictureIcon />}>
              Edit cover
            </Button>
          </EditCoverWrapper>
        </Box>
      )}

      <Stack
        direction={{ xs: 'column', md: 'row' }}
        sx={{ ...flexHelper('space-between', 'center'), marginBottom: 2.75 }}
        spacing={4}
      >
        <Stack spacing={2.75} direction={{ xs: 'column', md: 'row' }}>
          <Avatar image={avatar || mockAva} size="xxl" isSquare />
          <Stack
            alignItems={{ xs: 'center', md: 'flex-start' }}
            justifyContent="center"
            sx={{ wordBreak: 'break-all', maxWidth: 500 }}
          >
            <Typography variant="h4" textAlign={{ xs: 'center', md: 'start' }} sx={{ marginBottom: 2 }}>
              {name}
            </Typography>
            <Typography variant="body1" className="s" color={COLOR_PRIMARY_1} sx={{ marginBottom: 2 }}>
              {shortenPhrase(subName, 35, 3)}
            </Typography>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box
                component="img"
                src={chains[network as Chains]?.mainnet?.img}
                sx={{ width: 20, height: 20, marginRight: 1 }}
              />
              {network}
            </Typography>
          </Stack>
        </Stack>
        {isAbleToEdit && (
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <EditCoverWrapper onChange={onCoverSet}>
              <Button type="submit" variant="outlined" size="small" endIcon={<PictureIcon />}>
                Edit cover
              </Button>
            </EditCoverWrapper>
          </Box>
        )}
      </Stack>

      <Box sx={{ maxWidth: { xs: 'initial', md: 700 } }}>
        <Grid
          container
          spacing={2}
          sx={{
            border: `1px solid ${theme.themeColors.colorTextFieldBorderDefault}`,
            borderRadius: '8px',
            margin: 0,
            marginBottom: 3,
            '& > .MuiGrid-item': {
              padding: theme.spacing(1.5, 0),
              ...(isMobile
                ? {
                    '&:nth-of-type(1),:nth-of-type(3)': {
                      borderRight: `1px solid ${theme.themeColors.colorTextFieldBorderDefault}`,
                    },
                    '&:nth-of-type(1),:nth-of-type(2)': {
                      borderBottom: `1px solid ${theme.themeColors.colorTextFieldBorderDefault}`,
                    },
                  }
                : {
                    '&:not(:last-child)': {
                      borderRight: `1px solid ${theme.themeColors.colorTextFieldBorderDefault}`,
                    },
                  }),
            },
          }}
        >
          {blocks.map((block, index) => (
            <Grid
              key={block.title}
              item
              xs={(isMobile && 6) || 3}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'center',
                background: COLOR_BG_35,
              }}
            >
              <Typography
                sx={{
                  textAlign: 'center',
                }}
                className="xl"
              >
                {block.value}
              </Typography>
              {block.subValue && (
                <Typography
                  sx={{
                    textAlign: 'center',
                    color: theme.themeColors.colorFooterText,
                    marginBottom: 0.5,
                  }}
                  className="xs"
                >
                  {block.subValue}
                </Typography>
              )}
              <Typography
                sx={{
                  textAlign: 'center',
                  color: theme.themeColors.colorTextBody2,
                }}
                className="s"
              >
                {block.title}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>

      {isAbleToEdit && buttonText && (
        <Box
          sx={{
            // display: { xs: 'none', md: 'flex' },
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'center', md: 'space-between' },
            alignItems: 'center',
            // alignSelf: { xs: 'center', md: 'end' },
          }}
        >
          <Socials {...{ site, twitter, instagram, discord, telegram, medium }} sx={{ marginBottom: { md: 0 } }} />
          <Button onClick={onAddMore} sx={{ minWidth: '170px' }} size="small">
            {buttonText}
          </Button>
        </Box>
      )}
    </Box>
  );
};
