import { ReactNode, useCallback } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { fileValidation, MAX_FILE_SIZE } from 'modules/layout/containers/CreateNftContainer';
import { getToastMessage } from 'utils';

export interface EditCoverWrapperProps {
  children?: ReactNode;
  onChange?: (file: File) => void;
}

export const EditCoverWrapper = ({ children, onChange }: EditCoverWrapperProps) => {
  const onDrop = useCallback(
    (acceptedFiles: File[], FileRejections: Array<FileRejection>) => {
      if (acceptedFiles.length === 0) {
        getToastMessage('error', FileRejections[0].errors[0].message);
        return;
      }

      const currentFile = acceptedFiles[0];
      onChange?.(currentFile);
    },
    [onChange],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/gif': [],
      'image/jpeg': [],
      'image/jpg': [],
      'image/png': [],
    },
    validator: (file) => fileValidation(file, MAX_FILE_SIZE),
    maxFiles: 1,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
};
