import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Compass: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 20"
    sx={{
      ...sx,
      width: '20px',
      height: '20px',
    }}
  >
    <path
      d="M12.5169 2.39917H7.49876C7.09876 2.39917 6.77148 2.08276 6.77148 1.69604C6.77148 1.30933 7.09876 0.99292 7.49876 0.99292H12.5533C12.9533 0.99292 13.2806 1.30933 13.2806 1.69604C13.2442 2.08276 12.9169 2.39917 12.5169 2.39917Z"
      fill="currentColor"
    />
    <path
      d="M9.46156 18.9929C5.49793 18.782 2.11611 16.0046 1.20702 12.2781C0.73429 10.239 0.988835 8.12964 1.97065 6.33667C2.15247 5.98511 2.6252 5.84448 2.98884 6.05542C3.31611 6.26636 3.4252 6.65308 3.24338 6.96948C2.44338 8.51636 2.2252 10.3093 2.66156 12.0671C3.4252 15.1257 6.26156 17.3757 9.53429 17.5867C13.8979 17.8328 17.5343 14.4578 17.5343 10.3093C17.5343 6.30151 14.1525 2.99683 9.97065 2.99683C8.11611 2.99683 6.29793 3.6648 4.91611 4.89526C4.6252 5.14136 4.2252 5.14136 3.93429 4.89526C3.60702 4.61401 3.60702 4.12183 3.93429 3.87573C5.60702 2.39917 7.75247 1.59058 10.007 1.59058C14.9888 1.59058 19.0252 5.49292 19.0252 10.3093C18.9888 15.2664 14.6616 19.2742 9.46156 18.9929Z"
      fill="currentColor"
    />
    <path
      d="M5.89774 15.0555C5.67956 15.0555 5.49774 14.9852 5.31592 14.8094C5.09774 14.5985 5.02501 14.3172 5.1341 14.036L7.09774 8.02426C7.20683 7.70785 7.46138 7.46176 7.78865 7.35629L13.8614 5.56332C14.1523 5.49301 14.4432 5.56332 14.6614 5.7391C14.8796 5.95004 14.9523 6.23129 14.8796 6.51254L13.0614 12.4539C12.9523 12.7704 12.6977 13.0516 12.3705 13.1219L6.15229 15.0204C6.0432 15.0555 5.97047 15.0555 5.89774 15.0555ZM8.3341 8.58676L6.7341 13.5086L11.7886 11.9618L13.2796 7.1102L8.3341 8.58676Z"
      fill="currentColor"
    />
    <path
      d="M10.0069 11.364C10.6094 11.364 11.0978 10.8918 11.0978 10.3093C11.0978 9.72684 10.6094 9.25464 10.0069 9.25464C9.40443 9.25464 8.91602 9.72684 8.91602 10.3093C8.91602 10.8918 9.40443 11.364 10.0069 11.364Z"
      fill="currentColor"
    />
    <path
      d="M10.443 4.08661C10.443 4.33271 10.2249 4.50849 10.0067 4.50849C9.75213 4.50849 9.57031 4.29755 9.57031 4.08661C9.57031 3.84052 9.78849 3.66474 10.0067 3.66474C10.2249 3.62958 10.443 3.84052 10.443 4.08661Z"
      fill="currentColor"
    />
    <path
      d="M10.443 16.5319C10.443 16.778 10.2249 16.9538 10.0067 16.9538C9.75213 16.9538 9.57031 16.7429 9.57031 16.5319C9.57031 16.2858 9.78849 16.11 10.0067 16.11C10.2249 16.0749 10.443 16.2858 10.443 16.5319Z"
      fill="currentColor"
    />
    <path
      d="M3.5712 9.85229C3.82575 9.85229 4.00757 10.0632 4.00757 10.2742C4.00757 10.5203 3.78938 10.696 3.5712 10.696C3.31666 10.696 3.13484 10.4851 3.13484 10.2742C3.09848 10.0632 3.31666 9.85229 3.5712 9.85229Z"
      fill="currentColor"
    />
    <path
      d="M16.4442 9.85229C16.6988 9.85229 16.8806 10.0632 16.8806 10.2742C16.8806 10.5203 16.6624 10.696 16.4442 10.696C16.1897 10.696 16.0079 10.4851 16.0079 10.2742C15.9715 10.0632 16.1897 9.85229 16.4442 9.85229Z"
      fill="currentColor"
    />
    <path
      d="M12.5169 2.39917H7.49876C7.09876 2.39917 6.77148 2.08276 6.77148 1.69604C6.77148 1.30933 7.09876 0.99292 7.49876 0.99292H12.5533C12.9533 0.99292 13.2806 1.30933 13.2806 1.69604C13.2442 2.08276 12.9169 2.39917 12.5169 2.39917Z"
      fill="currentColor"
    />
    <path
      d="M9.46156 18.9929C5.49793 18.782 2.11611 16.0046 1.20702 12.2781C0.73429 10.239 0.988835 8.12964 1.97065 6.33667C2.15247 5.98511 2.6252 5.84448 2.98884 6.05542C3.31611 6.26636 3.4252 6.65308 3.24338 6.96948C2.44338 8.51636 2.2252 10.3093 2.66156 12.0671C3.4252 15.1257 6.26156 17.3757 9.53429 17.5867C13.8979 17.8328 17.5343 14.4578 17.5343 10.3093C17.5343 6.30151 14.1525 2.99683 9.97065 2.99683C8.11611 2.99683 6.29793 3.6648 4.91611 4.89526C4.6252 5.14136 4.2252 5.14136 3.93429 4.89526C3.60702 4.61401 3.60702 4.12183 3.93429 3.87573C5.60702 2.39917 7.75247 1.59058 10.007 1.59058C14.9888 1.59058 19.0252 5.49292 19.0252 10.3093C18.9888 15.2664 14.6616 19.2742 9.46156 18.9929Z"
      fill="currentColor"
    />
    <path
      d="M5.89774 15.0555C5.67956 15.0555 5.49774 14.9852 5.31592 14.8094C5.09774 14.5985 5.02501 14.3172 5.1341 14.036L7.09774 8.02426C7.20683 7.70785 7.46138 7.46176 7.78865 7.35629L13.8614 5.56332C14.1523 5.49301 14.4432 5.56332 14.6614 5.7391C14.8796 5.95004 14.9523 6.23129 14.8796 6.51254L13.0614 12.4539C12.9523 12.7704 12.6977 13.0516 12.3705 13.1219L6.15229 15.0204C6.0432 15.0555 5.97047 15.0555 5.89774 15.0555ZM8.3341 8.58676L6.7341 13.5086L11.7886 11.9618L13.2796 7.1102L8.3341 8.58676Z"
      fill="currentColor"
    />
    <path
      d="M10.0069 11.364C10.6094 11.364 11.0978 10.8918 11.0978 10.3093C11.0978 9.72684 10.6094 9.25464 10.0069 9.25464C9.40443 9.25464 8.91602 9.72684 8.91602 10.3093C8.91602 10.8918 9.40443 11.364 10.0069 11.364Z"
      fill="currentColor"
    />
    <path
      d="M10.443 4.08661C10.443 4.3327 10.2249 4.50849 10.0067 4.50849C9.75213 4.50849 9.57031 4.29755 9.57031 4.08661C9.57031 3.84052 9.78849 3.66474 10.0067 3.66474C10.2249 3.62958 10.443 3.84052 10.443 4.08661Z"
      fill="currentColor"
    />
    <path
      d="M10.443 16.5319C10.443 16.778 10.2249 16.9538 10.0067 16.9538C9.75213 16.9538 9.57031 16.7429 9.57031 16.5319C9.57031 16.2858 9.78849 16.11 10.0067 16.11C10.2249 16.0749 10.443 16.2858 10.443 16.5319Z"
      fill="currentColor"
    />
    <path
      d="M3.5712 9.85229C3.82575 9.85229 4.00757 10.0632 4.00757 10.2742C4.00757 10.5203 3.78938 10.696 3.5712 10.696C3.31666 10.696 3.13484 10.4851 3.13484 10.2742C3.09848 10.0632 3.31666 9.85229 3.5712 9.85229Z"
      fill="currentColor"
    />
    <path
      d="M16.4443 9.85229C16.6988 9.85229 16.8806 10.0632 16.8806 10.2742C16.8806 10.5203 16.6624 10.696 16.4443 10.696C16.1897 10.696 16.0079 10.4851 16.0079 10.2742C15.9715 10.0632 16.1897 9.85229 16.4443 9.85229Z"
      fill="currentColor"
    />
  </SvgIcon>
);
