import { BOX_SHADOW_MAIN } from 'theme/variables';

import { COLOR_NEUTRALS_1, COLOR_NEUTRALS_3, COLOR_NEUTRALS_6, COLOR_NEUTRALS_6_LIGHT } from '../colors.constant';

export const colorsAdvantageCardLight = {
  colorAdvantageCardBackgroundDefault: COLOR_NEUTRALS_6_LIGHT,
  colorAdvantageCardBorderDefault: COLOR_NEUTRALS_6,
  colorAdvantageCardTitle: COLOR_NEUTRALS_3,
  boxShadowAdvantageCard: 'none',
};

export const colorsAdvantageCardDark = {
  colorAdvantageCardBackgroundDefault: COLOR_NEUTRALS_1,
  colorAdvantageCardBorderDefault: COLOR_NEUTRALS_1,
  colorAdvantageCardTitle: COLOR_NEUTRALS_6_LIGHT,
  boxShadowAdvantageCard: BOX_SHADOW_MAIN,
};
