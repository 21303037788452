import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Burger: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 16 8"
    sx={{
      ...sx,
      width: '16px',
      height: '8px',
    }}
  >
    <path
      d="M1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2H15C15.5523 2 16 1.55228 16 1C16 0.447715 15.5523 0 15 0H1Z"
      fill="#777E91"
    />
    <path
      d="M1 6C0.447715 6 0 6.44772 0 7C0 7.55228 0.447715 8 1 8H15C15.5523 8 16 7.55228 16 7C16 6.44772 15.5523 6 15 6H1Z"
      fill="#777E91"
    />
  </SvgIcon>
);
