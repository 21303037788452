/* eslint-disable react/no-array-index-key */
import { FC, useMemo, useState } from 'react';
import { Box, BoxProps, Button, useTheme } from '@mui/material';
import { Card } from 'components';
import { useShallowSelector } from 'hooks';
import userSelector from 'store/user/selectors';
import { COLOR_NEUTRALS_2, COLOR_NEUTRALS_3, COLOR_NEUTRALS_5 } from 'theme/colors';
import { Themes } from 'types';
import { Ownership } from 'types/api/Ownership';
import { checksumAddress } from 'utils';

import { SetBuyMultipleModalCallback } from '../../NftDetails.types';

import { HistoryCard, HistoryCardProps, OwnerHistoryCard } from './components';
import { NftDetailsHistoryTabs } from './NftDetailsHistory.helper';

export interface NftDetailsHistoryProps {
  owners?: Ownership[];
  history: HistoryCardProps[];
  onBuyClick?: SetBuyMultipleModalCallback;
  userAddress: string;
  isMultiple?: boolean;
}

export const NftDetailsHistory: FC<NftDetailsHistoryProps & BoxProps> = ({
  owners,
  history,
  onBuyClick,
  userAddress,
  isMultiple,
  ...boxProps
}) => {
  const theme = useTheme();
  const [activeTab, setactiveTab] = useState(NftDetailsHistoryTabs.owners);

  const currentUserAddress = useShallowSelector(userSelector.getProp('address'));
  const siteTheme = useShallowSelector(userSelector.getProp('theme'));

  const isDarkTheme = useMemo(() => siteTheme === Themes.dark, [siteTheme]);

  const transformedOwners = owners
    ? owners?.map((owner) => {
        const shouldShowButton = Boolean(
          Number(owner.sellingQuantity) > 0 &&
            checksumAddress(userAddress) !== checksumAddress(owner.address) &&
            isMultiple,
        );

        return {
          ...owner,
          shouldShowButton,
        };
      })
    : [];

  const sortedOwners = isMultiple
    ? transformedOwners.sort((a, b) => {
        if (a.shouldShowButton && !b.shouldShowButton) {
          return -1;
        }

        if (!a.shouldShowButton && b.shouldShowButton) {
          return 1;
        }

        return 0;
      })
    : transformedOwners;

  return (
    <Box {...boxProps}>
      <Box
        sx={{
          p: 0.75,
          border: `2px solid ${theme.themeColors.colorNftDetailsHistoryTabsBorder}`,
          borderRadius: '36px',
          mb: 2.5,
        }}
      >
        {Object.entries(NftDetailsHistoryTabs).map(([key, value]) => {
          return (
            <Button
              sx={{
                minWidth: { xs: 0, sm: 100, md: 100 },
                fontSize: { xs: '14px' },
                padding: { xs: theme.spacing(1) },
                height: 28,
                color: theme.themeColors.colorNftDetailsHistoryTabNotActiveText,
                background: 'transparent',
                ...(value === activeTab && {
                  background: COLOR_NEUTRALS_3,
                  color: theme.themeColors.colorNftDetailsHistoryTabActiveText,
                }),
                '&:hover': {
                  background: COLOR_NEUTRALS_2,
                },
                '&:not(:last-child)': {
                  mr: 1,
                },
              }}
              variant="text"
              key={key}
              onClick={() => setactiveTab(value)}
            >
              {value}
            </Button>
          );
        })}
      </Box>
      <Card
        noBorder
        sx={{
          padding: 2,
          paddingRight: 0,
        }}
      >
        <Box
          sx={{
            overflow: 'auto',
            maxHeight: 420,
            '& > *': {
              padding: theme.spacing(2, 0),
              borderBottom: `1px solid ${theme.themeColors.colorDivider}`,
              '&:first-of-type': {
                padding: theme.spacing(0, 0, 2, 0),
              },
            },
            '::-webkit-scrollbar-thumb': {
              background: isDarkTheme ? COLOR_NEUTRALS_3 : COLOR_NEUTRALS_5,
            },
          }}
        >
          {activeTab === NftDetailsHistoryTabs.history &&
            history.map((props, index) => <HistoryCard key={index} {...props} />)}
          {activeTab === NftDetailsHistoryTabs.owners &&
            sortedOwners.map((props, index) => (
              <OwnerHistoryCard
                key={index}
                {...props}
                shouldShowButton={!!currentUserAddress && props.shouldShowButton}
                onBuyClick={onBuyClick}
                userAddress={userAddress}
                sx={{
                  button: {
                    marginRight: 2,
                  },
                }}
              />
            ))}
        </Box>
      </Card>
    </Box>
  );
};
