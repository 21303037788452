import { alpha } from '@mui/material';

// old colors, delete later
export const COLOR_BEIGE = '#F4F0E7';

export const COLOR_RED = '#FF4B23';

export const COLOR_BLUE = '#5697F5';

export const COLOR_GRAY_MEDIUM = '#E9E7E2';
export const COLOR_GRAY_LIGHT = '#B3B3B3';
export const COLOR_GRAY_EXTRALIGHT = '#d3d3d3';

export const COLOR_GREEN = '#3D7C0B';
export const COLOR_LIGHT_GREEN = '#B2D795';
export const COLOR_MAIN_BG_DARK = 'linear-gradient(180deg, #070B24 11.34%, rgba(7, 11, 36, 0) 27.01%), url(i.jpg)';

// new colors
export const COLOR_NEUTRALS_1 = '#1C1931';
export const COLOR_NEUTRALS_2 = '#342D5B';
export const COLOR_NEUTRALS_3 = '#3E3A58';
export const COLOR_NEUTRALS_4 = '#8E87AF';
export const COLOR_NEUTRALS_5 = '#B1B5C4';
export const COLOR_NEUTRALS_6 = '#E6E8EC';
export const COLOR_NEUTRALS_6_LIGHT = '#EEEFF0';
export const COLOR_NEUTRALS_7 = '#F4F5F6';
export const COLOR_NEUTRALS_8 = '#FCFCFD';
export const COLOR_NEUTRALS_9 = '#B1B5C3';
export const COLOR_NEUTRALS_10 = '#B1B5C3';
export const COLOR_PRIMARY_1 = '#E039FD';
export const COLOR_PRIMARY_2 = '#9757D7';
export const COLOR_PRIMARY_3 = '#EF466F';
export const COLOR_PRIMARY_4 = '#45B36B';
export const COLOR_SECONDARY_1 = '#4BC9F0';
export const COLOR_SECONDARY_2 = '#E4D7CF';
export const COLOR_SECONDARY_3 = '#FFD166';
export const COLOR_SECONDARY_4 = '#CDB4DB';
export const COLOR_GRADIENT_ACCENT = 'linear-gradient(109.18deg, #6345ED 12.99%, #E039FD 62.91%)';
export const COLOR_DEPTH_1 = '0px 8px 16px -8px rgba(15, 15, 15, 0.2)';
export const COLOR_DEPTH_2 = '0px 24px 24px -16px rgba(15, 15, 15, 0.2)';
export const COLOR_DEPTH_3 = '0px 40px 32px -24px rgba(15, 15, 15, 0.12)';
export const COLOR_DEPTH_4 = '0px 64px 64px -48px rgba(31, 47, 70, 0.12)';
export const COLOR_DEPTH_5 = '0px 16px 64px -48px rgba(31, 47, 70, 0.4)';
export const COLOR_BG = '#070B24';
export const COLOR_BG_60 = alpha(COLOR_BG, 0.6);
export const COLOR_BG_35 = alpha(COLOR_BG, 0.35);
export const COLOR_DARK_GRAY = '#C9C9C9';
export const COLOR_DEEP_DARK = '#1D1933';
export const COLOR_SUCCESS = '#26DB64';

export const COLOR_BLACK_80 = alpha('#000000', 0.8);
export const COLOR_WHITE_BASE = '#FFFFFF';
export const COLOR_LIGHT_WHITE = '#F0F0F0';

export const COLOR_DARK_WHITE = '#D9D9D9';

export const COLOR_DARK_5 = '#A1A1A9';
export const COLOR_DARK_6 = '#1D1934';
export const COLOR_GRAY = '#EEEFF0';
export const COLOR_GRAY_100 = '#D2D1D1';
export const COLOR_GRAY_200 = '#353945';

export const COLOR_BLACK = '#08090D';

export const COLOR_WHITE = '#F4F9FD';

export const COLOR_GRADIENT_SHADOW = 'linear-gradient(180deg, rgba(24, 21, 44, 5.6) 50%, rgba(7, 11, 36, 5.6) 100%)';
export const COLOR_GRADIENT_PRIMARY = 'linear-gradient(109.18deg, #6345ED 12.99%, #E039FD 62.91%)';

export const COLOR_GREEN_DARK = '#337B36';
export const COLOR_GREEN_DEFAULT = '#449347';
export const COLOR_GOLD = '#EAB63D';
export const COLOR_DARK_PURPLE = '#B064FD';
