import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Note: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    sx={{
      ...sx,
      width: '24px',
      height: '24px',
      path: {
        stroke: 'currentColor',
        fill: 'currentColor',
      },
    }}
  >
    <path
      d="M21 3.19833C21 1.95052 19.8699 1.00738 18.6422 1.23059L9.64223 2.86695C8.69125 3.03986 8 3.86812 8 4.83469V19.5H10V8.83469L19 7.19833V17.5H21V3.19833Z"
      fill="currentColor"
    />
    <path
      d="M17.5 21C19.433 21 21 19.433 21 17.5C21 15.567 19.433 14 17.5 14C15.567 14 14 15.567 14 17.5C14 19.433 15.567 21 17.5 21Z"
      fill="currentColor"
    />
    <path
      d="M6.5 23C8.433 23 10 21.433 10 19.5C10 17.567 8.433 16 6.5 16C4.567 16 3 17.567 3 19.5C3 21.433 4.567 23 6.5 23Z"
      fill="currentColor"
    />
  </SvgIcon>
);
