export enum PARAMS {
  access = 'access',
  status = 'status',
  secondaryStatus = 'secondary-status',
}

export type CategoryParams = {
  gameId: string;
  categoryId: string;
};
