import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Logout: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    width={25}
    height={30}
    viewBox="0 0 20 20"
    sx={{
      width: '20px',
      height: '20px',
      ...sx,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.19058 12.5015C9.65049 12.4841 10.0374 12.8429 10.0548 13.3028C10.0939 14.3396 10.1489 15.0964 10.2029 15.6389C10.2562 16.1732 10.5787 16.4947 11.0284 16.5497C11.5586 16.6145 12.3065 16.6676 13.3329 16.6676C14.3593 16.6676 15.1073 16.6145 15.6375 16.5497C16.0869 16.4948 16.4096 16.1731 16.4629 15.6387C16.5632 14.6316 16.6662 12.8916 16.6662 10.0009C16.6662 7.11021 16.5632 5.37024 16.4629 4.36312C16.4096 3.82867 16.0869 3.50703 15.6375 3.45209C15.1073 3.38728 14.3593 3.33423 13.3329 3.33423C12.3065 3.33423 11.5586 3.38727 11.0284 3.45208C10.5787 3.50705 10.2562 3.82854 10.2029 4.36287C10.1489 4.90544 10.0939 5.66218 10.0548 6.699C10.0374 7.15891 9.65049 7.51766 9.19058 7.5003C8.73067 7.48294 8.37191 7.09603 8.38928 6.63612C8.42945 5.57193 8.48641 4.78042 8.54447 4.19766C8.66651 2.97252 9.52068 1.95731 10.8261 1.79773C11.4338 1.72345 12.2503 1.66756 13.3329 1.66756C14.4156 1.66756 15.232 1.72345 15.8397 1.79774C17.1453 1.95734 17.9993 2.97302 18.1213 4.19792C18.2288 5.27646 18.3329 7.07462 18.3329 10.0009C18.3329 12.9272 18.2288 14.7253 18.1213 15.8039C17.9993 17.0288 17.1453 18.0444 15.8397 18.204C15.232 18.2783 14.4156 18.3342 13.3329 18.3342C12.2503 18.3342 11.4338 18.2783 10.8261 18.2041C9.52069 18.0445 8.66651 17.0293 8.54447 15.8041C8.48641 15.2214 8.42945 14.4299 8.38928 13.3657C8.37191 12.9057 8.73067 12.5188 9.19058 12.5015Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.00527 12.3284C6.33071 12.6538 6.33071 13.1815 6.00527 13.5069C5.67983 13.8323 5.1522 13.8323 4.82676 13.5069L1.91009 10.5902C1.58466 10.2648 1.58466 9.73716 1.91009 9.41172L4.82676 6.49505C5.1522 6.16962 5.67983 6.16962 6.00527 6.49505C6.33071 6.82049 6.33071 7.34813 6.00527 7.67357L4.51119 9.16764H12.4993C12.9596 9.16764 13.3327 9.54074 13.3327 10.001C13.3327 10.4612 12.9596 10.8343 12.4993 10.8343L4.51119 10.8343L6.00527 12.3284Z"
      fill="currentColor"
    />
  </SvgIcon>
);
