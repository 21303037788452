import { URL } from 'appConstants';
import { AxiosRequestConfig } from 'axios';
import { CallEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import {
  AddCategoryReq,
  AddCollectionsReq,
  AddSubcategoryReq,
  DeleteGameItemReq,
  EditCategoryReq,
  EditGameReq,
  EditSubcategoryReq,
  GetCategoryReq,
  GetFilterGames,
  GetGameReq,
  GetSubcategory,
  ListGameReq,
  Pagination,
  UpdateGameReq,
  WithQueryNetwork,
} from 'types';
import { snakeize } from 'utils';

const getResultVideoCover = (videoCover?: string) => {
  if (videoCover === undefined) {
    return [];
  }

  if (videoCover) {
    if (videoCover.startsWith('data')) {
      return [videoCover];
    }
    return [true];
  }

  return [false];
};

export const gamesRequests = (
  ajax: (config: AxiosRequestConfig) => Generator<SelectEffect | CallEffect | PutEffect>,
) => ({
  getGames(params: GetFilterGames) {
    return ajax({
      method: 'get',
      url: URL.getGames,
      params: snakeize(params),
    });
  },
  editGame({ data, id, network }: EditGameReq) {
    const { video1, video2, video3, videoCover1, videoCover2, videoCover3, photos, ...restData } = data;
    return ajax({
      method: 'patch',
      url: URL.editGame(id, network),
      data: snakeize({
        ...restData,
        videos: [...(video1 ? [video1] : []), ...(video2 ? [video2] : []), ...(video3 ? [video3] : [])],
        videoCovers: [
          ...getResultVideoCover(videoCover1),
          ...getResultVideoCover(videoCover2),
          ...getResultVideoCover(videoCover3),
        ],
        photos: photos?.map((photo) => (photo.display === null ? photo.url : photo.display)),
      }),
    });
  },
  listGame(data: ListGameReq) {
    return ajax({
      method: 'post',
      url: URL.listGame,
      data: snakeize(data),
    });
  },
  getGame({ gameName, network }: GetGameReq) {
    return ajax({
      method: 'get',
      url: URL.getGame(gameName, network),
    });
  },
  updateGame({ gameName, data, network }: UpdateGameReq) {
    return ajax({
      method: 'patch',
      url: URL.getGame(gameName, network),
      data,
    });
  },
  getCategory({ categoryId, gameId, network }: GetCategoryReq) {
    return ajax({
      method: 'get',
      url: URL.getCategory({ gameId, categoryId, network }),
    });
  },
  addCategory({ id, data, network }: AddCategoryReq) {
    return ajax({
      method: 'post',
      url: URL.addCategory(id, network),
      data: snakeize(data),
    });
  },
  editCategory({ gameId, categoryId, network, data }: EditCategoryReq) {
    return ajax({
      method: 'patch',
      url: URL.getCategory({ gameId, categoryId, network }),
      data: snakeize(data),
    });
  },
  addSubcategory({ gameId, categoryId, network, data }: AddSubcategoryReq) {
    return ajax({
      method: 'post',
      url: URL.addSubcategory({ gameId, categoryId, network }),
      data: snakeize(data),
    });
  },
  getSubcategory({ categoryId, gameId, subcategoryId, network }: GetSubcategory) {
    return ajax({
      method: 'get',
      url: URL.getSubcategory({ gameId, categoryId, subcategoryId, network }),
    });
  },
  editSubcategory({ categoryId, gameId, subcategoryId, network, data }: EditSubcategoryReq) {
    return ajax({
      method: 'patch',
      url: URL.getSubcategory({ gameId, categoryId, subcategoryId, network }),
      data: snakeize(data),
    });
  },
  addCollections({ categoryId, gameId, subcategoryId, network, data }: AddCollectionsReq) {
    return ajax({
      method: 'post',
      url: URL.addCollections({ gameId, categoryId, subcategoryId, network }),
      data: snakeize(data),
    });
  },
  deleteGameItem({ itemType, id }: DeleteGameItemReq) {
    return ajax({
      method: 'delete',
      url: URL.deleteGameItem({ itemType, id }),
    });
  },
  getOwnedGames(params: Pagination & Partial<WithQueryNetwork>) {
    return ajax({
      method: 'get',
      url: URL.getOwnedGames,
      params: snakeize(params),
    });
  },
});
