import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Cup: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    sx={{
      width: '24px',
      height: '24px',
      ...sx,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 5H8C7.44772 5 7 5.44772 7 6V10C7 12.7614 9.23858 15 12 15C14.7614 15 17 12.7614 17 10V6C17 5.44772 16.5523 5 16 5ZM8 3C6.34315 3 5 4.34315 5 6V10C5 13.866 8.13401 17 12 17C15.866 17 19 13.866 19 10V6C19 4.34315 17.6569 3 16 3H8Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 8C1 6.34315 2.34315 5 4 5H7V13H5C2.79086 13 1 11.2091 1 9V8ZM4 7H5V11C3.89543 11 3 10.1046 3 9V8C3 7.44772 3.44772 7 4 7Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 8C23 6.34315 21.6569 5 20 5H17V13H19C21.2091 13 23 11.2091 23 9V8ZM20 7H19V11C20.1046 11 21 10.1046 21 9V8C21 7.44772 20.5523 7 20 7Z"
      fill="currentColor"
    />
    <path
      d="M12 15C11.4477 15 11 15.4477 11 16V19H9C8.44771 19 8 19.4477 8 20C8 20.5523 8.44771 21 9 21H15C15.5523 21 16 20.5523 16 20C16 19.4477 15.5523 19 15 19H13V16C13 15.4477 12.5523 15 12 15Z"
      fill="currentColor"
    />
  </SvgIcon>
);
