import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Rocket: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 22 24"
    sx={{
      width: '22px',
      height: '24px',
      ...sx,
    }}
  >
    <path
      d="M21.4319 0.539686C21.3327 0.461236 21.2192 0.427505 21.0913 0.43896C18.7068 0.652403 16.767 1.15287 15.272 1.94066C13.7771 2.72853 12.2472 4.0535 10.6825 5.91555C10.018 6.72103 9.29363 7.68383 8.50986 8.80382L3.3406 9.54272C3.19605 9.57438 3.08383 9.65793 3.00393 9.79397L0.405302 15.3314C0.311976 15.5239 0.350873 15.7001 0.521644 15.8598L1.47715 16.6583C1.56678 16.7332 1.67543 16.7649 1.8034 16.7534C1.85826 16.7485 1.89884 16.7402 1.92543 16.7286L5.60359 15.2249L9.79923 18.7313L8.97288 22.6177C8.94206 22.7864 8.99166 22.9292 9.12182 23.0465L10.0774 23.8451C10.1852 23.9181 10.2941 23.9499 10.4036 23.9401C10.486 23.9327 10.5574 23.9078 10.618 23.8656L15.6059 20.3248C15.7256 20.222 15.788 20.0969 15.7932 19.949L15.6023 14.7305C16.5654 13.7601 17.3844 12.8765 18.0588 12.0793C19.5391 10.2981 20.5494 8.54792 21.0905 6.82805C21.632 5.10806 21.8007 3.11056 21.5971 0.835813C21.5863 0.717075 21.5315 0.618442 21.4319 0.539686ZM18.5803 6.02372C18.3476 6.30225 18.0482 6.45813 17.6828 6.49085C17.3173 6.52356 16.9954 6.42343 16.7165 6.19055C16.438 5.95759 16.2821 5.65841 16.2494 5.29289C16.2167 4.92757 16.3169 4.60541 16.5497 4.32668C16.7826 4.0481 17.0818 3.89232 17.4472 3.85961C17.8127 3.82689 18.1348 3.92691 18.4134 4.15985C18.6921 4.3928 18.8479 4.69205 18.8806 5.05737C18.9133 5.42293 18.8134 5.74477 18.5803 6.02372Z"
      fill="white"
    />
  </SvgIcon>
);
