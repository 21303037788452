import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Plus: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 14 14"
    sx={{
      width: '14px',
      height: '14px',
      path: {
        stroke: 'currentColor',
      },
      ...sx,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.676533 7C0.676533 6.63181 0.97501 6.33333 1.3432 6.33333L6.33339 6.33333L6.33339 1.34314C6.33339 0.974955 6.63186 0.676478 7.00005 0.676478C7.36824 0.676478 7.66672 0.974954 7.66672 1.34314L7.66672 6.33333L12.6569 6.33333C13.0251 6.33333 13.3236 6.63181 13.3236 7C13.3236 7.36819 13.0251 7.66666 12.6569 7.66666L7.66672 7.66667L7.66672 12.6569C7.66672 13.025 7.36825 13.3235 7.00005 13.3235C6.63187 13.3235 6.33339 13.025 6.33339 12.6569L6.33339 7.66667L1.3432 7.66667C0.97501 7.66667 0.676533 7.36819 0.676533 7Z"
      fill="currentColor"
    />
  </SvgIcon>
);
