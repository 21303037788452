/* eslint-disable */
/* @ts-ignore */
/**
 * DO NOT MODIFY IT BY HAND.
 * This file was automatically generated.
 */

export enum Standard {
  ERC721 = 'ERC721',
  ERC1155 = 'ERC1155',
}

export enum Status {
  Waitinglist = 'Waiting list',
  Inprogress = 'In progress',
  Finished = 'Finished',
}

export enum Method {
  Transfer = 'Transfer',
  Buy = 'Buy',
  Mint = 'Mint',
  Burn = 'Burn',
  Listing = 'Listing',
  AuctionWin = 'AuctionWin',
  like = 'like',
  follow = 'follow',
  Bet = 'Bet',
}

export enum State {
  Pending = 'Pending',
  Failed = 'Failed',
  Committed = 'Committed',
  Burned = 'Burned',
  Expired = 'Expired',
  Importing = 'Importing',
}
