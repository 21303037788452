import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Box, BoxProps, CardMediaProps } from '@mui/material';
import { CustomCardMedia } from 'components/index';
import { NftFormat } from 'components/NftPreview/NftPreview.helper';
import { flexHelper } from 'utils';

export interface CustomImageProps {
  media: string;
  altImage?: string;
  path?: string;
  sx?: BoxProps['sx'];
  customCardMediaSx?: CardMediaProps['sx'];
  format?: NftFormat | string;
}

export const CustomImage: FC<CustomImageProps & BoxProps> = ({
  media,
  altImage,
  path,
  sx,
  customCardMediaSx,
  format,
}) => {
  return (
    <Box
      component={path ? Link : Box}
      to={path}
      sx={{
        cursor: path ? 'pointer' : '',
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        'img, video': {
          borderRadius: 4,
        },
        ...flexHelper(),
        ...sx,
      }}
    >
      <CustomCardMedia src={media} alt={altImage} sx={customCardMediaSx} format={format} />
    </Box>
  );
};
