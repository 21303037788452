/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Grid, MenuItem, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { ITEMS_PER_PAGE_8, routes } from 'appConstants';
import { BackgroundImageCover } from 'components';
import { EditCover } from 'components/EditCover';
import { GridContainer } from 'components/GridContainer';
import { Edit, ImageIcon, Trash } from 'components/Icon/components';
import { PlayCircle } from 'components/Icon/components/PlayCircle';
import { useGetQuery, useModal, useShallowSelector, useUnmountEffect } from 'hooks';
import { CategoryCard, GameHeaderSkeleton } from 'modules/games/components';
import { CardVariantText } from 'modules/games/components/CategoryCard/CategoryCard.types';
import { CategoryMenuButton, MenuItemButton } from 'modules/games/components/CategoryCard/components';
import { CategoryCardSkeleton } from 'modules/games/components/CategoryCardSkeleton';
import { DeleteItemProps, DeleteSubcategoryModal } from 'modules/games/pages/Category/components';
import { backgroundCardColor } from 'modules/games/pages/EditGame/EditGame.helper';
import { GameHeader } from 'modules/games/pages/Game/components';
import { convertToFormRequestData } from 'modules/games/pages/Game/Game.helper';
import { currentChains } from 'services/WalletService/config';
import { deleteCategory, getGame, updateGame } from 'store/games/actions';
import gamesActionTypes from 'store/games/actionTypes';
import { clearGame } from 'store/games/reducer';
import gamesSelectors from 'store/games/selectors';
import uiSelector from 'store/ui/selectors';
import userSelector from 'store/user/selectors';
import { COLOR_NEUTRALS_6, COLOR_WHITE } from 'theme/colors';
import { FontWeights } from 'theme/Typography';
import { RequestStatus } from 'types';
import { fillArray } from 'utils';

import { PreviewModal } from './components/PreviewModal';

interface Params {
  id: string;
  network?: string;
}

export const Game = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const isMobileTablet = useMediaQuery(
    theme.breakpoints.between(theme.breakpoints.values.sm, theme.breakpoints.values.md),
  );
  const [activeMediaIndex, setActiveMediaIndex] = useState<number | null>(null);
  const mediaRef = useRef<HTMLDivElement | null>(null);
  const overviewRef = useRef<HTMLDivElement | null>(null);

  const { id: gameName } = useParams<keyof Params>() as Params;
  const network = useGetQuery('network');

  const [categoryOnDelete, setCategoryOnDelete] = useState<DeleteItemProps>({ id: 0, name: '' });
  const [isDeleteModalOpen, setDeleteModalOpen, setDeleteModalClose] = useModal(false);

  const {
    user: { id: userId },
  } = useShallowSelector(userSelector.getUser);
  const { [gamesActionTypes.GET_GAME]: getGameDataRequestStatus } = useShallowSelector(uiSelector.getUI);

  const isGameDataLoading = getGameDataRequestStatus === RequestStatus.REQUEST;

  const { game } = useShallowSelector(gamesSelectors.getGames);
  const {
    avatar,
    banner,
    name,
    email,
    description,
    facebook,
    discord,
    categories,
    instagram,
    network: currentNetwork,
    user,
    twitter,
    medium,
    website,
    telegram,
    backgroundColor,

    photos,
    videoCovers,
    videos,

    releaseStatus,
    platform,
    developer,
    genre,
  } = game ?? {};

  const isAbleToEdit = useMemo(() => String(user?.id) === String(userId), [userId, user?.id]);
  const currentChain = useMemo(
    () => currentChains.find((item) => item.id === currentNetwork?.name),
    [currentNetwork?.name],
  );
  const currentNetworkParam = useGetQuery('network');

  const textColor = useMemo(() => (banner ? COLOR_NEUTRALS_6 : theme.themeColors.colorTextDefault), [banner, theme]);

  const cardBackgroundVariant = useMemo(
    () => (backgroundColor ? backgroundCardColor[backgroundColor] : theme.themeColors.colorCardBackground),
    [backgroundColor],
  );

  const handleOpenDeleteModal = ({ id, name: nameToDelete }: DeleteItemProps) => {
    if (id) {
      setDeleteModalOpen();
      setCategoryOnDelete({ id, name: nameToDelete });
    }
  };

  const handleDeleteCategory = useCallback(
    (id?: number | string) => {
      if (id) {
        dispatch(deleteCategory({ id, params: { gameName, network } }));
      }
    },
    [dispatch, gameName, network],
  );

  const handleChangeCover = useCallback(
    (file: File) => {
      const formData = convertToFormRequestData('banner', { banner: file });

      dispatch(updateGame({ gameName, data: formData, network }));
    },
    [dispatch, gameName],
  );
  const handleDeleteCover = useCallback(() => {
    dispatch(updateGame({ gameName, data: { removeBanner: true }, network }));
  }, [dispatch, gameName]);

  const handleDeleteAvatar = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      dispatch(updateGame({ gameName, data: { removeAvatar: true }, network }));
    },
    [dispatch, avatar],
  );

  const handleUploadAvatar = useCallback(
    (file: File | null) => {
      const formData = convertToFormRequestData('avatar', { avatar: file });
      dispatch(updateGame({ gameName, data: formData, network }));
    },
    [dispatch, avatar],
  );

  const handleEditClick = () => {
    navigate(routes.games.game.editGame.root.getPath(gameName, currentNetworkParam));
  };

  useEffect(() => {
    dispatch(getGame({ gameName, network }));
  }, [dispatch, gameName]);

  useUnmountEffect(() => dispatch(clearGame()));

  const media: { image: string; video?: string }[] = [
    ...(videoCovers ?? []).map((videoCover, index) => ({
      image: videoCover,
      video: videos?.[index],
    })),
    ...(photos ?? []).map((photo, index) => ({
      image: photo,
    })),
  ];

  const gameProperties = [
    {
      title: 'Release status',
      value: releaseStatus,
    },
    {
      title: 'Platform',
      value: platform,
    },
    {
      title: 'Chain',
      children: (
        <>
          <Box component="img" src={currentChain?.img} sx={{ width: 20, height: 20, marginRight: 1 }} />
          <Typography fontWeight={FontWeights.fontWeightMedium} fontSize={14} color={textColor}>
            {currentChain?.name}
          </Typography>
        </>
      ),
    },
    {
      title: 'Developer',
      value: developer,
    },
    {
      title: 'Genre',
      value: genre,
    },
  ];

  const playCircleSx = {
    position: 'absolute',
    left: { xs: `calc(50% - ${theme.spacing(1)})`, md: '50%' },
    top: { xs: '50%', md: `calc(50% - ${theme.spacing(1)})` },
    transform: 'translate(-50%, -50%)',
  };

  return (
    <>
      <BackgroundImageCover isPlainBackgroundColor backgroundColor={backgroundColor} sx={{ zIndex: '10', flex: 1 }}>
        <Box
          sx={{
            position: 'relative',
            zIndex: '10',
            '& > div:first-of-type': {
              display: 'flex',
              justifyContent: 'center',
            },
          }}
        >
          {isAbleToEdit && (
            <EditCover
              variantBtn="text"
              variant="outlined"
              // color="secondary"
              onDeleteCover={handleDeleteCover}
              onUpdateCover={handleChangeCover}
              endIcon={<ImageIcon />}
              sx={{
                border: `2px solid ${theme.themeColors.colorButtonSecondary}`,
                '&:hover': {
                  color: COLOR_WHITE,
                  background: theme.themeColors.colorButtonSecondary,
                },
                background: 'transparent',
                position: { xs: 'initial', md: 'absolute' },
                top: 0,
                right: { xs: '50%', sm: '0' },
                transform: { xs: 'none', sm: 'translateY(calc(-100% - 60px))' },
                zIndex: 10,
              }}
            />
          )}

          {isGameDataLoading ? (
            <GameHeaderSkeleton />
          ) : (
            <GameHeader
              chainImg={currentChain?.img}
              cover={banner}
              chainName={currentChain?.name}
              gameId={gameName}
              avatar={avatar}
              name={name}
              email={email}
              site={website}
              socials={{ email, site: website, discord, twitter, instagram, telegram, medium, facebook }}
              description={description}
              mediaRef={mediaRef}
              overviewRef={overviewRef}
              shouldShowMedia={media.length > 0}
              isAbleToEdit={isAbleToEdit}
              onDeleteAvatar={handleDeleteAvatar}
              onUploadAvatar={handleUploadAvatar}
            />
          )}

          <Box>
            <Typography variant="h4" sx={{ marginTop: 3 }}>
              NFT
            </Typography>
            <GridContainer columns={3} spacing={3} mt={5} sx={{ mb: 0, pb: 4 }}>
              {isGameDataLoading
                ? fillArray(ITEMS_PER_PAGE_8).map((_, index) => <CategoryCardSkeleton key={index} />)
                : categories?.map(({ name: categoryName, avatar: categoryAvatar, id }) => (
                    <CategoryCard
                      isAbleToEdit={isAbleToEdit}
                      name={categoryName || ''}
                      media={categoryAvatar || ''}
                      key={categoryName}
                      backgroundColor={cardBackgroundVariant}
                      path={routes.games.game.category.root.getPath({
                        gameId: gameName,
                        categoryId: String(categoryName),
                        network,
                      })}
                    >
                      <CategoryMenuButton>
                        <MenuItem sx={{ px: 1.25 }}>
                          <MenuItemButton
                            component={Link}
                            to={routes.games.game.editCategory.root.getPath({
                              gameName,
                              categoryName: String(categoryName),
                              network,
                            })}
                            startIcon={<Edit />}
                            variant="text"
                          >
                            Edit info
                          </MenuItemButton>
                        </MenuItem>
                        <MenuItem sx={{ px: 1.25 }}>
                          <MenuItemButton
                            onClick={() => handleOpenDeleteModal({ id: id || 0, name: categoryName || '' })}
                            startIcon={<Trash sx={{ fill: 'none', width: 16, height: 16 }} />}
                            variant="text"
                          >
                            {CardVariantText.category}
                          </MenuItemButton>
                        </MenuItem>
                      </CategoryMenuButton>
                    </CategoryCard>
                  ))}
            </GridContainer>
          </Box>

          {media.length > 0 && (
            <Box ref={mediaRef} sx={{ marginTop: '-96px', paddingTop: '96px' }}>
              <Typography variant="h5" sx={{ marginBottom: 3, fontWeight: FontWeights.fontWeightSemiBold }}>
                Media
              </Typography>

              <Box sx={{ maxHeight: { xs: 170, md: 500 }, marginBottom: 3, display: 'flex' }}>
                <Box
                  onClick={() => setActiveMediaIndex(0)}
                  sx={{
                    display: { xs: 'none', md: 'initial' },
                    width: '75%',
                    height: 'auto',
                    marginRight: 3.5,
                    position: 'relative',
                    cursor: 'pointer',
                  }}
                >
                  <Box
                    component="img"
                    src={media[0]?.image}
                    sx={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      objectPosition: 'center',
                      borderRadius: '20px',
                    }}
                  />
                  {media[0]?.video && (
                    <Box
                      sx={{
                        ...playCircleSx,
                        left: '50%',
                        top: '50%',
                      }}
                    >
                      <PlayCircle sx={{ width: 96, height: 96 }} />
                    </Box>
                  )}
                </Box>
                <Box
                  component={Splide}
                  // hasTrack={false}
                  options={{
                    type: 'slide',
                    perPage: (isMobileTablet && 3) || (isMobile && 1) || 3,
                    perMove: 1,
                    // gap: theme.spacing(3.5),
                    pagination: false,
                    direction: isMobile ? 'ltr' : 'ttb',
                    height: '100%',
                    wheeled: true,
                    waitForTransition: true,
                    wheelSleep: 100,
                    drag: 'free',
                    snap: true,
                    arrows: false,
                    padding: {
                      right: isMobile ? 32 : 0,
                      bottom: isMobile ? 0 : 32,
                    },
                    // fixedWidth: isLargeScreen ? 480 : 328,
                  }}
                  sx={{
                    visibility: 'visible',
                    width: { xs: '100%', md: '25%' },

                    '.splide__track': {
                      height: '100% !important',
                    },
                  }}
                >
                  {media.slice(isMobile ? 0 : 1).map((resource, index) => (
                    <Box
                      key={resource.image}
                      component={SplideSlide}
                      onClick={() => setActiveMediaIndex(isMobile ? index : index + 1)}
                      sx={{
                        cursor: 'pointer',
                        position: 'relative',
                        paddingBottom: { md: 3.5 },
                        paddingRight: { xs: 3.5, md: 0 },
                      }}
                    >
                      <Box
                        component="img"
                        src={resource.image}
                        sx={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          borderRadius: '16px',
                        }}
                      />
                      {resource.video && (
                        <Box sx={{ ...playCircleSx }}>
                          <PlayCircle sx={{ width: 48, height: 48 }} />
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          )}

          <Box ref={overviewRef} sx={{ marginTop: '-96px', paddingTop: '96px' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: { xs: 'column-reverse', md: 'row' },
              }}
            >
              <Typography variant="h5" sx={{ marginBottom: 3, fontWeight: FontWeights.fontWeightSemiBold }}>
                Game Overview
              </Typography>
              {isAbleToEdit && (
                <Button
                  onClick={handleEditClick}
                  variant="outlined"
                  color="secondary"
                  size="small"
                  endIcon={<Edit />}
                  sx={{ width: { xs: '100%', md: 'initial' }, marginBottom: { xs: 4, md: 0 } }}
                >
                  Edit info
                </Button>
              )}
            </Box>

            <Box sx={{ maxWidth: 850 }}>
              <Grid container spacing={3} sx={{ marginBottom: 3 }}>
                {gameProperties.map(
                  (gameProperty, index) =>
                    (!!gameProperty.value || !!gameProperty.children) && (
                      <Grid key={gameProperty.title} item xs={(isMobileTablet && 6) || (isMobile && 12) || 4}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: theme.themeColors.colorModalBackground,
                            borderRadius: '8px',
                            paddingY: 1.5,
                            px: 1,
                            height: '100%',
                          }}
                        >
                          {gameProperty.value ? (
                            <Typography
                              sx={{
                                textAlign: 'center',
                                fontSize: 14,
                                fontWeight: FontWeights.fontWeightMedium,
                                color: textColor,
                              }}
                            >
                              <Box
                                component="span"
                                sx={{
                                  color: theme.themeColors.colorTextBody2,
                                  marginRight: 1,
                                  fontSize: 14,
                                }}
                              >
                                {gameProperty.title}:
                              </Box>
                              {gameProperty.value}
                            </Typography>
                          ) : (
                            gameProperty.children
                          )}
                        </Box>
                      </Grid>
                    ),
                )}
              </Grid>
              <Typography className="s" color={textColor}>
                {description}
              </Typography>
            </Box>
          </Box>
        </Box>
      </BackgroundImageCover>

      <PreviewModal media={media} activeMediaIndex={activeMediaIndex} setActiveMediaIndex={setActiveMediaIndex} />

      {isDeleteModalOpen && (
        <DeleteSubcategoryModal
          open={isDeleteModalOpen}
          deletedItemProps={categoryOnDelete}
          onClose={setDeleteModalClose}
          onDelete={handleDeleteCategory}
        />
      )}
    </>
  );
};
