import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Filter: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 17 16"
    sx={{
      ...sx,
      width: '17px',
      height: '16px',
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.83404 9.1314C9.83404 8.99978 9.873 8.87111 9.946 8.7616L13.3184 3.70305C13.6137 3.26002 13.2961 2.66659 12.7637 2.66659H4.23774C3.70527 2.66659 3.38768 3.26002 3.68304 3.70305L7.0554 8.7616C7.12841 8.87111 7.16737 8.99978 7.16737 9.1314V13.0581C7.16737 13.5817 7.74334 13.9009 8.18737 13.6234L9.5207 12.7901C9.71563 12.6683 9.83404 12.4546 9.83404 12.2248V9.1314ZM11.1674 9.5351C11.1674 9.40349 11.2063 9.27481 11.2793 9.1653L14.4278 4.44265C15.3138 3.11354 14.3611 1.33325 12.7637 1.33325H4.23774C2.64035 1.33325 1.68756 3.11354 2.57364 4.44265L5.72207 9.1653C5.79508 9.27481 5.83404 9.40349 5.83404 9.5351V13.0581C5.83404 14.629 7.56194 15.5866 8.89404 14.7541L10.2274 13.9208C10.8121 13.5553 11.1674 12.9143 11.1674 12.2248V9.5351Z"
      fill="currentColor"
    />
  </SvgIcon>
);
