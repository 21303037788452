const CREATE_COLLECTION = 'CREATE_COLLECTION';
const EDIT_COLLECTION = 'EDIT_COLLECTION';
const GET_SINGLE_COLLECTION = 'GET_SINGLE_COLLECTION';
const GET_TOP_COLLECTIONS = 'GET_TOP_COLLECTIONS';
const GET_COLLECTIONS = 'GET_COLLECTIONS';
const GET_MY_COLLECTIONS = 'GET_MY_COLLECTIONS';
const GET_MAX_ROYALTY = 'GET_MAX_ROYALTY';
const GET_TOP_COLLECTIONS_PERIOD = 'GET_TOP_COLLECTIONS_PERIOD';
const GET_COLLECTION_ACTIVITY = 'GET_COLLECTION_ACTIVITY';
const GET_COLLECTION_TRADE_DATA = 'GET_COLLECTION_TRADE_DATA';
const GET_COLLECTION_CHART = 'GET_COLLECTION_CHART';
const SET_COLLECTION_COVER = 'SET_COLLECTION_COVER';

const collectionsActionTypes = {
  CREATE_COLLECTION,
  EDIT_COLLECTION,
  GET_TOP_COLLECTIONS,
  GET_COLLECTIONS,
  GET_SINGLE_COLLECTION,
  GET_MY_COLLECTIONS,
  GET_MAX_ROYALTY,
  GET_TOP_COLLECTIONS_PERIOD,
  GET_COLLECTION_ACTIVITY,
  GET_COLLECTION_TRADE_DATA,
  GET_COLLECTION_CHART,
  SET_COLLECTION_COVER,
};

export default collectionsActionTypes;
