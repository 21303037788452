import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Usdt: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 28 28"
    sx={{
      width: '28px',
      height: '28px',
      ...sx,
    }}
  >
    <path
      d="M14 0C21.7316 0 28 6.26836 28 14C28 21.7316 21.7314 28 14 28C6.26864 28 0 21.7333 0 14C0 6.26668 6.26752 0 14 0Z"
      fill="#53AE94"
    />
    <path
      d="M15.7279 12.1347V10.052H20.4904V6.87878H7.52194V10.052H12.285V12.133C8.41402 12.3108 5.50342 13.0774 5.50342 13.9958C5.50342 14.9142 8.41542 15.6809 12.285 15.8598V22.5308H15.729V15.8592C19.593 15.6809 22.4977 14.9148 22.4977 13.9972C22.4977 13.0797 19.593 12.3136 15.729 12.1352M15.729 15.2942V15.2925C15.6319 15.2987 15.1326 15.3286 14.021 15.3286C13.1323 15.3286 12.5071 15.3034 12.2867 15.2919V15.2947C8.86678 15.1433 6.31402 14.5477 6.31402 13.8351C6.31402 13.1225 8.86706 12.5278 12.2867 12.376V14.7014C12.5107 14.7168 13.1513 14.7546 14.0356 14.7546C15.0976 14.7546 15.6316 14.7104 15.7296 14.7014V12.376C19.1428 12.5281 21.6897 13.1242 21.6897 13.8343C21.6897 14.5443 19.1417 15.1407 15.7296 15.2928"
      fill="white"
    />
  </SvgIcon>
);
