import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const LoadingSimple: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 16 16"
    sx={{
      width: '16px',
      height: '16px',
      ...sx,
    }}
  >
    <path
      d="M7.99967 1.33337C7.63148 1.33337 7.33301 1.63185 7.33301 2.00004V4.00004C7.33301 4.36823 7.63148 4.66671 7.99967 4.66671C8.36786 4.66671 8.66634 4.36823 8.66634 4.00004V2.00004C8.66634 1.63185 8.36786 1.33337 7.99967 1.33337Z"
      fill="currentColor"
    />
    <path
      d="M7.99967 11.3334C7.63148 11.3334 7.33301 11.6319 7.33301 12V14C7.33301 14.3682 7.63148 14.6667 7.99967 14.6667C8.36786 14.6667 8.66634 14.3682 8.66634 14V12C8.66634 11.6319 8.36786 11.3334 7.99967 11.3334Z"
      fill="currentColor"
    />
    <path
      d="M13.9997 7.33337C14.3679 7.33337 14.6663 7.63185 14.6663 8.00004C14.6663 8.36823 14.3679 8.66671 13.9997 8.66671H11.9997C11.6315 8.66671 11.333 8.36823 11.333 8.00004C11.333 7.63185 11.6315 7.33337 11.9997 7.33337H13.9997Z"
      fill="currentColor"
    />
    <path
      d="M4.66634 8.00004C4.66634 7.63185 4.36786 7.33337 3.99967 7.33337H1.99967C1.63148 7.33337 1.33301 7.63185 1.33301 8.00004C1.33301 8.36823 1.63148 8.66671 1.99967 8.66671H3.99967C4.36786 8.66671 4.66634 8.36823 4.66634 8.00004Z"
      fill="currentColor"
    />
    <path
      d="M11.7709 3.28606C12.0313 3.02571 12.4534 3.02571 12.7138 3.28606C12.9741 3.54641 12.9741 3.96852 12.7138 4.22887L11.2995 5.64308C11.0392 5.90343 10.6171 5.90343 10.3567 5.64308C10.0964 5.38273 10.0964 4.96062 10.3567 4.70027L11.7709 3.28606Z"
      fill="currentColor"
    />
    <path
      d="M5.64263 10.357C5.38228 10.0967 4.96017 10.0967 4.69982 10.357L3.28561 11.7712C3.02526 12.0316 3.02526 12.4537 3.28561 12.714C3.54596 12.9744 3.96807 12.9744 4.22841 12.714L5.64263 11.2998C5.90298 11.0395 5.90298 10.6174 5.64263 10.357Z"
      fill="currentColor"
    />
    <path
      d="M12.7137 11.7713C12.9741 12.0317 12.9741 12.4538 12.7137 12.7141C12.4534 12.9745 12.0313 12.9745 11.7709 12.7141L10.3567 11.2999C10.0964 11.0396 10.0964 10.6174 10.3567 10.3571C10.6171 10.0967 11.0392 10.0967 11.2995 10.3571L12.7137 11.7713Z"
      fill="currentColor"
    />
    <path
      d="M5.64269 5.64299C5.90304 5.38265 5.90304 4.96054 5.64269 4.70019L4.22848 3.28597C3.96813 3.02562 3.54602 3.02562 3.28567 3.28597C3.02532 3.54632 3.02532 3.96843 3.28567 4.22878L4.69988 5.64299C4.96023 5.90334 5.38234 5.90334 5.64269 5.64299Z"
      fill="currentColor"
    />
  </SvgIcon>
);
