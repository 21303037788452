import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const GalleryTwoMount: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    sx={{
      ...sx,
      width: '24px',
      height: '24px',
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4C10.0478 4 8.24816 4.161 6.80371 4.35177C5.50372 4.52346 4.52346 5.50372 4.35177 6.80371C4.16101 8.24816 4 10.0478 4 12C4 13.9522 4.161 15.7518 4.35177 17.1963C4.52346 18.4963 5.50372 19.4765 6.80371 19.6482C8.24816 19.839 10.0478 20 12 20C13.9522 20 15.7518 19.839 17.1963 19.6482C18.4963 19.4765 19.4765 18.4963 19.6482 17.1963C19.839 15.7518 20 13.9522 20 12C20 10.0478 19.839 8.24816 19.6482 6.80371C19.4765 5.50372 18.4963 4.52346 17.1963 4.35177C15.7518 4.16101 13.9522 4 12 4ZM6.54184 2.36899C4.34504 2.65912 2.65912 4.34504 2.36899 6.54184C2.16953 8.05208 2 9.94127 2 12C2 14.0587 2.16953 15.9479 2.36899 17.4582C2.65912 19.655 4.34504 21.3409 6.54184 21.631C8.05208 21.8305 9.94127 22 12 22C14.0587 22 15.9479 21.8305 17.4582 21.631C19.655 21.3409 21.3409 19.655 21.631 17.4582C21.8305 15.9479 22 14.0587 22 12C22 9.94127 21.8305 8.05208 21.631 6.54184C21.3409 4.34504 19.655 2.65912 17.4582 2.36899C15.9479 2.16953 14.0587 2 12 2C9.94127 2 8.05208 2.16953 6.54184 2.36899Z"
      fill="currentColor"
    />
    <path
      d="M9.00061 11C10.1052 11 11.0006 10.1046 11.0006 9C11.0006 7.89543 10.1052 7 9.00061 7C7.89604 7 7.00061 7.89543 7.00061 9C7.00061 10.1046 7.89604 11 9.00061 11Z"
      fill="currentColor"
    />
    <path
      d="M18.1219 12.7073L19.9162 14.5016C19.8543 15.4378 19.7639 16.3065 19.6643 17.0781L16.7077 14.1215C16.3172 13.731 15.684 13.731 15.2935 14.1215L13.1219 16.2931C11.9504 17.4647 10.0509 17.4647 8.87929 16.2931L8.70771 16.1215C8.31719 15.731 7.68403 15.731 7.2935 16.1215L4.88443 18.5306C4.60536 18.1522 4.41863 17.6981 4.35237 17.1963C4.31623 16.9227 4.28116 16.6363 4.24805 16.3385L5.87929 14.7073C7.05086 13.5357 8.95035 13.5357 10.1219 14.7073L10.2935 14.8789C10.684 15.2694 11.3172 15.2694 11.7077 14.8789L13.8793 12.7073C15.0509 11.5357 16.9504 11.5357 18.1219 12.7073Z"
      fill="currentColor"
    />
  </SvgIcon>
);
