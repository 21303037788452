const GET_DATA = 'GET_DATA';
const GET_HOME_DATA = 'GET_HOME_DATA';
const GET_TOP_USERS = 'GET_TOP_USERS';
const GET_EXPLORE_DATA = 'GET_EXPLORE_DATA';
const GET_SERVICE_FEE = 'GET_SERVICE_FEE';

const APPROVE = 'APPROVE' as const;
const APPROVE_NFT = 'APPROVE_NFT' as const;
const GET_RATES = 'GET_RATES' as const;
const GET_TOKEN_BALANCE = 'GET_TOKEN_BALANCE' as const;
const GET_RANK_ID = 'GET_RANK_ID' as const;
const GET_USER_DATA = 'GET_USER_DATA' as const;
const LOGIN = 'LOGIN' as const;
const UPDATE_USER_DATA = 'UPDATE_USER_DATA' as const;
const GET_SELF_INFO = 'GET_SELF_INFO' as const;
const FOLLOW = 'FOLLOW' as const;
const CONNECT_TO_WEBSOCKET = 'CONNECT_TO_WEBSOCKET' as const;

const GET_FOLLOWERS = 'GET_FOLLOWERS';
const GET_FOLLOWING = 'GET_FOLLOWING';

const UPDATE_USER_COVER = 'UPDATE_USER_COVER' as const;
const SUBSCRIBE_MAIL = 'SUBSCRIBE_MAIL';

export default {
  GET_DATA,
  GET_HOME_DATA,
  GET_TOP_USERS,
  GET_EXPLORE_DATA,
  GET_SERVICE_FEE,

  APPROVE,
  APPROVE_NFT,
  GET_RATES,
  GET_TOKEN_BALANCE,
  GET_RANK_ID,
  GET_USER_DATA,
  UPDATE_USER_DATA,
  LOGIN,
  GET_SELF_INFO,
  FOLLOW,

  CONNECT_TO_WEBSOCKET,
  GET_FOLLOWERS,
  GET_FOLLOWING,
  UPDATE_USER_COVER,
  SUBSCRIBE_MAIL,
};
