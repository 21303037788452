import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Discord: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 18 20"
    sx={{
      width: '18px',
      height: '20px',
      ...sx,
    }}
  >
    <path
      d="M7.23973 8.38989C6.66973 8.38989 6.21973 8.88989 6.21973 9.49989C6.21973 10.1099 6.67973 10.6099 7.23973 10.6099C7.80973 10.6099 8.25973 10.1099 8.25973 9.49989C8.26973 8.88989 7.80973 8.38989 7.23973 8.38989ZM10.8897 8.38989C10.3197 8.38989 9.86973 8.88989 9.86973 9.49989C9.86973 10.1099 10.3297 10.6099 10.8897 10.6099C11.4597 10.6099 11.9097 10.1099 11.9097 9.49989C11.9097 8.88989 11.4597 8.38989 10.8897 8.38989Z"
      fill="#8E87AF"
    />
    <path
      d="M15.7498 0H2.3498C1.2198 0 0.299805 0.92 0.299805 2.06V15.58C0.299805 16.72 1.2198 17.64 2.3498 17.64H13.6898L13.1598 15.79L14.4398 16.98L15.6498 18.1L17.7998 20V2.06C17.7998 0.92 16.8798 0 15.7498 0ZM11.8898 13.06C11.8898 13.06 11.5298 12.63 11.2298 12.25C12.5398 11.88 13.0398 11.06 13.0398 11.06C12.6298 11.33 12.2398 11.52 11.8898 11.65C11.3898 11.86 10.9098 12 10.4398 12.08C9.4798 12.26 8.5998 12.21 7.8498 12.07C7.2798 11.96 6.7898 11.8 6.37981 11.64C6.14981 11.55 5.8998 11.44 5.6498 11.3C5.6198 11.28 5.5898 11.27 5.5598 11.25C5.5398 11.24 5.5298 11.23 5.5198 11.22C5.3398 11.12 5.2398 11.05 5.2398 11.05C5.2398 11.05 5.7198 11.85 6.9898 12.23C6.68981 12.61 6.3198 13.06 6.3198 13.06C4.1098 12.99 3.2698 11.54 3.2698 11.54C3.2698 8.32 4.7098 5.71 4.7098 5.71C6.1498 4.63 7.5198 4.66 7.5198 4.66L7.6198 4.78C5.8198 5.3 4.9898 6.09 4.9898 6.09C4.9898 6.09 5.20981 5.97 5.5798 5.8C6.64981 5.33 7.4998 5.2 7.8498 5.17C7.9098 5.16 7.9598 5.15 8.0198 5.15C8.6298 5.07 9.3198 5.05 10.0398 5.13C10.9898 5.24 12.0098 5.52 13.0498 6.09C13.0498 6.09 12.2598 5.34 10.5598 4.82L10.6998 4.66C10.6998 4.66 12.0698 4.63 13.5098 5.71C13.5098 5.71 14.9498 8.32 14.9498 11.54C14.9498 11.54 14.0998 12.99 11.8898 13.06Z"
      fill="#8E87AF"
    />
  </SvgIcon>
);
