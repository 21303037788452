import { RequestWithWeb3Provider } from 'types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { TransactionConfig, TransactionReceipt } from 'web3-core';

export type SendTx = {
  rawTx: TransactionConfig;
} & RequestWithWeb3Provider;

export const sendTx = async ({ web3Provider, rawTx }: SendTx): Promise<TransactionReceipt> => {
  try {
    const { data, to, value, from } = rawTx;

    const gasPrice = await web3Provider.eth.getGasPrice();
    const estimatedGas = await web3Provider.eth.estimateGas({
      from,
      data,
      to,
      value,
    });

    const dataTx = await web3Provider.eth.sendTransaction({
      ...rawTx,
      from,
      gas: estimatedGas,
      gasPrice: +gasPrice,
    });

    return dataTx;
  } catch (err: any) {
    console.log(err);
    throw err;
  }
};
