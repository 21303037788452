import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Download: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 22 22"
    sx={{
      ...sx,
      width: '22px',
      height: '22px',
    }}
  >
    <g clipPath="url(#clip0_1410_10877)">
      <path
        d="M11.0001 12.8333C10.8241 12.8333 10.6481 12.7664 10.5143 12.6317L5.70179 7.81917C5.27004 7.38741 5.57713 6.64583 6.18763 6.64583H8.70846V1.14583C8.70846 0.51425 9.22271 0 9.85429 0H12.146C12.7775 0 13.2918 0.51425 13.2918 1.14583V6.64583H15.8126C16.4231 6.64583 16.7302 7.38741 16.2985 7.81917L11.486 12.6317C11.3521 12.7664 11.1761 12.8333 11.0001 12.8333Z"
        fill="currentColor"
      />
      <path
        d="M10.0833 19.426L0 14.8042V16.9584C0 17.1408 0.108167 17.3058 0.275917 17.3782L10.0833 21.6425V19.426Z"
        fill="currentColor"
      />
      <path
        d="M11.916 19.426V21.6434L21.7234 17.3791C21.8912 17.3058 21.9994 17.1408 21.9994 16.9584V14.8042L11.916 19.426Z"
        fill="currentColor"
      />
      <path
        d="M16.1159 10.5947L12.7829 13.9277C12.3062 14.4044 11.6728 14.6666 11 14.6666C10.3272 14.6666 9.69375 14.4044 9.218 13.9277L5.885 10.5947L0 13.2916L11 18.3332L22 13.2916L16.1159 10.5947Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1410_10877">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
