import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const TwitterOutlined: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 20"
    sx={{
      width: '20px',
      height: '20px',
      ...sx,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.65115 11.6665C1.27305 11.6704 0.383457 13.2403 1.23245 14.4298C2.4483 16.133 4.6531 17.5 8.33395 17.5C14.0382 17.5 18.617 12.8514 17.976 7.35925L18.9151 5.48108C19.5664 4.17857 18.4347 2.69685 17.0067 2.98245L15.766 3.23057C15.4371 3.05702 15.0967 2.92606 14.8058 2.83117C14.2388 2.64627 13.5525 2.5 12.9173 2.5C11.7762 2.5 10.7914 2.79276 10.0087 3.37994C9.23495 3.96053 8.79911 4.72354 8.55995 5.42421C8.4497 5.74717 8.37628 6.07112 8.32917 6.38203C7.88645 6.24269 7.43502 6.05522 6.99307 5.82629C5.99041 5.30693 5.17961 4.64852 4.72224 4.07126C3.95488 3.10275 2.32868 3.17456 1.75803 4.43351C0.953746 6.20789 1.17437 8.31122 1.89732 10.0108C2.13964 10.5803 2.45485 11.1434 2.83994 11.6652C2.77401 11.666 2.71095 11.6663 2.65115 11.6665ZM8.33386 15.8333C5.11441 15.8333 3.44969 14.6672 2.5889 13.4614C2.55022 13.4072 2.58922 13.3333 2.65578 13.3332C3.53148 13.3307 5.32898 13.2892 6.51448 12.5954C6.57579 12.5595 6.5623 12.4691 6.49496 12.4465C3.73149 11.5173 2.16063 7.58216 3.27595 5.12158C3.30152 5.06517 3.37735 5.05772 3.41583 5.10628C4.68989 6.71432 7.47555 8.28933 9.8992 8.33242C9.95178 8.33333 9.99153 8.28558 9.98353 8.23361C9.88586 7.60044 9.51295 4.16667 12.9172 4.16667C13.7301 4.16667 14.9396 4.56319 15.3851 4.96942C15.4057 4.98819 15.4336 4.99672 15.4609 4.99125L17.3334 4.61675C17.4014 4.60315 17.4554 4.67371 17.4244 4.73573L16.2629 7.05849C16.2549 7.07462 16.2524 7.09312 16.2554 7.11088C17.0689 11.6833 13.3239 15.8333 8.33386 15.8333Z"
      fill="#777E91"
    />
  </SvgIcon>
);
