import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Tent: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 20"
    sx={{
      ...sx,
      width: '20px',
      height: '20px',
    }}
  >
    <path
      d="M9.04096 1.37957C8.85284 1.0069 8.36373 0.895101 8.02512 1.08144C7.64888 1.30504 7.53601 1.75224 7.72413 2.12492L9.45482 5.18082L10.3202 3.65287L9.04096 1.37957Z"
      fill="currentColor"
    />
    <path
      d="M18.2208 17.5163L11.1852 5.18091L10.5456 6.33619C10.4327 6.55979 10.4327 6.85793 10.5456 7.08153L16.7535 18.0008C17.0545 18.5225 18.0327 18.5225 18.2208 17.5163C18.2208 17.5536 18.2208 17.5536 18.2208 17.5163Z"
      fill="currentColor"
    />
    <path
      d="M12.5024 18.8578H7.61133V15.2801C7.61133 14.3112 8.40143 13.5286 9.37965 13.5286H10.6965C11.6747 13.5286 12.4648 14.3112 12.4648 15.2801V18.8578H12.5024ZM8.81529 17.6279H11.2608V15.2429C11.2608 14.9447 10.9975 14.6838 10.6965 14.6838H9.37965C9.07866 14.6838 8.81529 14.9447 8.81529 15.2429V17.6279Z"
      fill="currentColor"
    />
    <path
      d="M10.3207 10.6963H9.38014C9.1544 10.6963 9.00391 10.5472 9.00391 10.3236C9.00391 10.1 9.1544 9.95093 9.38014 9.95093H10.3207C10.5465 9.95093 10.697 10.1 10.697 10.3236C10.697 10.5472 10.5465 10.6963 10.3207 10.6963Z"
      fill="currentColor"
    />
    <path
      d="M18.7475 19.0068H1.25248C0.838614 19.0068 0.5 18.6714 0.5 18.2615C0.5 17.8515 0.838614 17.5161 1.25248 17.5161H18.7475C19.1614 17.5161 19.5 17.8515 19.5 18.2615C19.5 18.6714 19.1614 19.0068 18.7475 19.0068Z"
      fill="currentColor"
    />
    <path
      d="M2.75824 18.597L1.44141 17.8516L10.8097 1.37957C10.9978 1.0069 11.4869 0.895101 11.8256 1.08144C12.2018 1.26777 12.3147 1.75224 12.1266 2.08765L2.75824 18.597Z"
      fill="currentColor"
    />
    <path
      d="M9.04096 1.37957C8.85284 1.0069 8.36373 0.895101 8.02512 1.08144C7.64888 1.30504 7.53601 1.75224 7.72413 2.12492L9.45482 5.18082L10.3202 3.65287L9.04096 1.37957Z"
      fill="currentColor"
    />
    <path
      d="M18.2208 17.5163L11.1852 5.18091L10.5456 6.33619C10.4327 6.55979 10.4327 6.85793 10.5456 7.08153L16.7535 18.0008C17.0545 18.5225 18.0327 18.5225 18.2208 17.5163C18.2208 17.5536 18.2208 17.5536 18.2208 17.5163Z"
      fill="currentColor"
    />
    <path
      d="M12.5024 18.8578H7.61133V15.2801C7.61133 14.3112 8.40143 13.5286 9.37965 13.5286H10.6965C11.6747 13.5286 12.4648 14.3112 12.4648 15.2801V18.8578H12.5024ZM8.81529 17.6279H11.2608V15.2429C11.2608 14.9447 10.9975 14.6838 10.6965 14.6838H9.37965C9.07866 14.6838 8.81529 14.9447 8.81529 15.2429V17.6279Z"
      fill="currentColor"
    />
    <path
      d="M10.3207 10.6963H9.38014C9.1544 10.6963 9.00391 10.5472 9.00391 10.3236C9.00391 10.1 9.1544 9.95093 9.38014 9.95093H10.3207C10.5465 9.95093 10.697 10.1 10.697 10.3236C10.697 10.5472 10.5465 10.6963 10.3207 10.6963Z"
      fill="currentColor"
    />
    <path
      d="M18.7475 19.0068H1.25248C0.838614 19.0068 0.5 18.6714 0.5 18.2615C0.5 17.8515 0.838614 17.5161 1.25248 17.5161H18.7475C19.1614 17.5161 19.5 17.8515 19.5 18.2615C19.5 18.6714 19.1614 19.0068 18.7475 19.0068Z"
      fill="currentColor"
    />
    <path
      d="M2.75824 18.597L1.44141 17.8516L10.8097 1.37957C10.9978 1.0069 11.4869 0.895101 11.8256 1.08144C12.2018 1.26777 12.3147 1.75224 12.1266 2.08765L2.75824 18.597Z"
      fill="currentColor"
    />
  </SvgIcon>
);
