import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Lightning: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 24 24"
    {...props}
    sx={{
      width: '24px',
      height: '24px',
      ...sx,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.80064 0.0463346C9.21692 0.177555 9.5 0.563602 9.5 1.00007V6.00007L13.5 6.00007C13.8729 6.00007 14.2148 6.20753 14.387 6.53826C14.5592 6.86899 14.5331 7.26806 14.3192 7.57353L7.31924 17.5735C7.06894 17.9311 6.61564 18.085 6.19936 17.9538C5.78309 17.8226 5.5 17.4365 5.5 17.0001L5.5 12.0001H1.5C1.12713 12.0001 0.785222 11.7926 0.613024 11.4619C0.440826 11.1312 0.466941 10.7321 0.680771 10.4266L7.68077 0.42661C7.93107 0.0690398 8.38436 -0.0848856 8.80064 0.0463346Z"
      fill="#E039FD"
    />
  </SvgIcon>
);
