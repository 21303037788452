import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Chrome: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 20"
    sx={{
      ...sx,
      width: '20px',
      height: '20px',
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM16.3198 14.9057C17.3729 13.551 18 11.8488 18 10C18 5.92038 14.9463 2.55399 11 2.06189V6.12602C12.7252 6.57006 14 8.13616 14 10C14 10.7418 13.798 11.4365 13.4461 12.0321L16.3198 14.9057ZM14.9056 16.3199L12.0319 13.4462C11.4364 13.7981 10.7418 14 10 14C9.25823 14 8.56362 13.7981 7.96813 13.4462L5.09444 16.3199C6.44911 17.3729 8.15133 18 10 18C11.8487 18 13.5509 17.3729 14.9056 16.3199ZM6 10C6 10.7418 6.20195 11.4365 6.55388 12.0321L3.68021 14.9057C2.62711 13.551 2 11.8488 2 10C2 5.92038 5.05369 2.55399 9 2.06189V6.12602C7.27477 6.57006 6 8.13616 6 10ZM10 12C11.1046 12 12 11.1046 12 10C12 8.89543 11.1046 8 10 8C8.89543 8 8 8.89543 8 10C8 11.1046 8.89543 12 10 12Z"
      fill="currentColor"
    />
  </SvgIcon>
);
