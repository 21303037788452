import { useCallback, useMemo } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { Box, Grid, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Cross } from 'components/Icon/components';
import { Plus } from 'components/Icon/components/Plus';
import { imagesTypes } from 'modules/layout/containers/FileUploader';
import { COLOR_GRADIENT_ACCENT, COLOR_NEUTRALS_7, COLOR_PRIMARY_3 } from 'theme/colors';
import { getEncodeMedia, getToastMessage } from 'utils';

import { IEditGameFormInputs } from '../../EditGame.helper';

export interface PhotoUploaderProps {
  getPhotos: () => IEditGameFormInputs['photos'];
  onChange?: (newPhotos: IEditGameFormInputs['photos']) => void;
}

const MAX_FILES = 15;

const parentAspectRationSx = {
  position: 'relative',
  '&::before': {
    content: "''",
    display: 'block',
    paddingTop: '100%',
  },
};

const childAspectRationSx = {
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
};

export const PhotoUploader = ({ getPhotos, onChange }: PhotoUploaderProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const isMobileTablet = useMediaQuery(
    theme.breakpoints.between(theme.breakpoints.values.sm, theme.breakpoints.values.md),
  );

  const photos = getPhotos();

  const visiblePhotos = useMemo(() => photos?.map((photo) => photo.display || photo.display === null), [photos]);

  const handleDeletePhoto = useCallback(
    (index: number) => {
      let newPhotos = [...(photos ?? [])];
      if (newPhotos[index].display === null) {
        newPhotos = newPhotos.filter((_, i) => i !== index);
      } else {
        // Mark image to be deleted on backend
        newPhotos[index] = {
          url: photos?.[index]?.url ?? '',
          display: false,
        };
      }
      onChange?.(newPhotos);
    },
    [photos, onChange],
  );

  const handleDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections.length > 0) {
        getToastMessage('error', fileRejections?.[0]?.errors?.map((error) => error.message)?.join(', '));
      }
      if (acceptedFiles?.length > 0) {
        const dataUrls = await Promise.all(acceptedFiles.map((acceptedFile) => getEncodeMedia(acceptedFile)));
        const newPhotos = dataUrls.map((dataUrl) => ({ url: dataUrl, display: null }));
        onChange?.([...(photos ?? []), ...newPhotos]);
      }
    },
    [photos, onChange],
  );

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop: handleDrop,
    accept: imagesTypes,
    multiple: (visiblePhotos?.length ?? 0) < 14,
    maxFiles: MAX_FILES - (visiblePhotos?.length ?? 0),
    disabled: (visiblePhotos?.length ?? 0) > 14,
  });

  const gridItemXs = (isMobileTablet && 4) || (isMobile && 6) || 2;

  return (
    <Grid container spacing={isMobile ? 1 : 3}>
      <Grid item xs={gridItemXs}>
        <Box
          sx={{
            background: theme.themeColors.colorModalBackground,
            border: `1px dashed ${
              fileRejections.length > 0 ? COLOR_PRIMARY_3 : theme.themeColors.colorIconButtonBorder
            }`,
            borderRadius: '16px',
            ...parentAspectRationSx,
          }}
        >
          <Box
            {...getRootProps()}
            sx={{
              ...childAspectRationSx,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: (visiblePhotos?.length ?? 0) > 14 ? 'initial' : 'pointer',
            }}
          >
            <input {...getInputProps()} />
            <Box
              sx={{
                justifyContent: 'center',
                width: 36,
                display: 'flex',
                alignItems: 'center',
                height: 36,
                borderRadius: '50%',
                background: COLOR_GRADIENT_ACCENT,
                marginBottom: 0.5,
              }}
            >
              <Plus sx={{ color: COLOR_NEUTRALS_7 }} />
            </Box>
            <Typography variant="body2" sx={{ color: theme.themeColors.colorTextDefault }}>
              add photos
            </Typography>
          </Box>
        </Box>
      </Grid>
      {photos?.map(
        ({ url, display }, index) =>
          (display || display === null) && (
            <Grid key={url} item xs={gridItemXs}>
              <Box sx={{ ...parentAspectRationSx }}>
                <Box
                  sx={{
                    ...childAspectRationSx,
                    borderRadius: '16px',
                    overflow: 'hidden',
                  }}
                >
                  <Box
                    component="img"
                    key={url}
                    src={url}
                    sx={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center' }}
                  />
                  <IconButton
                    size="medium"
                    onClick={() => handleDeletePhoto(index)}
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      width: 36,
                      height: 36,
                      background: theme.themeColors.colorButtonSecondary,
                    }}
                  >
                    <Cross sx={{ width: 8, color: theme.themeColors.colorCircleDefault }} />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
          ),
      )}
    </Grid>
  );
};
