import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Pinterest: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 16 16"
    sx={{
      ...sx,
      width: '16px',
      height: '16px',
    }}
  >
    <path
      d="M12.6569 1.63958C11.5556 0.582306 10.0314 0 8.36526 0C5.82013 0 4.25475 1.04329 3.38976 1.91845C2.32371 2.99699 1.7124 4.42907 1.7124 5.84757C1.7124 7.6286 2.45737 8.99561 3.70491 9.50417C3.78866 9.53849 3.87293 9.55577 3.95555 9.55577C4.21873 9.55577 4.42727 9.38357 4.49951 9.10733C4.54162 8.94885 4.63921 8.5579 4.68163 8.38817C4.77245 8.053 4.69907 7.89179 4.50101 7.65838C4.14019 7.23145 3.97217 6.72661 3.97217 6.06958C3.97217 4.11799 5.42535 2.04385 8.1187 2.04385C10.2557 2.04385 11.5833 3.25847 11.5833 5.21366C11.5833 6.44748 11.3175 7.5901 10.8348 8.43121C10.4994 9.01563 9.90953 9.71229 9.00403 9.71229C8.61245 9.71229 8.26071 9.55144 8.03876 9.27102C7.82909 9.00593 7.75999 8.66348 7.84432 8.30658C7.93958 7.90334 8.06947 7.48272 8.19518 7.07607C8.42445 6.33338 8.64119 5.63192 8.64119 5.07227C8.64119 4.115 8.0527 3.4718 7.17691 3.4718C6.06391 3.4718 5.19194 4.60225 5.19194 6.04537C5.19194 6.75313 5.38004 7.28249 5.46519 7.48576C5.32498 8.07983 4.49167 11.6117 4.33361 12.2777C4.24221 12.6665 3.69164 15.7372 4.60293 15.9821C5.62682 16.2572 6.54202 13.2665 6.63517 12.9285C6.71067 12.6537 6.97483 11.6144 7.13671 10.9756C7.63098 11.4517 8.42683 11.7735 9.20121 11.7735C10.661 11.7735 11.9739 11.1166 12.898 9.92387C13.7942 8.767 14.2878 7.15456 14.2878 5.3838C14.2878 3.99946 13.6933 2.63472 12.6569 1.63958Z"
      fill="#E5E5E5"
    />
  </SvgIcon>
);
