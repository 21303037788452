import {
  COLOR_NEUTRALS_1,
  COLOR_NEUTRALS_2,
  COLOR_NEUTRALS_3,
  COLOR_NEUTRALS_4,
  COLOR_NEUTRALS_5,
  COLOR_NEUTRALS_6,
  COLOR_NEUTRALS_7,
  COLOR_NEUTRALS_8,
} from '../colors.constant';

export const colorsSellerCardLight = {
  colorSellerCardBackgroundDefault: COLOR_NEUTRALS_7,
  colorSellerCardBackgroundHover: COLOR_NEUTRALS_6,
  colorSellerCardBackgroundFocus: COLOR_NEUTRALS_6,
  colorSellerCardBorderDefault: `1px solid ${COLOR_NEUTRALS_6}`,
  colorSellerCardBorderFocus: `1px solid ${COLOR_NEUTRALS_5}`,
  colorSellerCardIcon: COLOR_NEUTRALS_4,
  colorSellerCardHeaderBorder: COLOR_NEUTRALS_6,
  colorSellerCardText: COLOR_NEUTRALS_3,
  colorSellerCardCurrency: COLOR_NEUTRALS_4,
};

export const colorsSellerCardDark = {
  colorSellerCardBackgroundDefault: COLOR_NEUTRALS_1,
  colorSellerCardBackgroundHover: COLOR_NEUTRALS_2,
  colorSellerCardBackgroundFocus: COLOR_NEUTRALS_2,
  colorSellerCardBorderDefault: 'none',
  colorSellerCardBorderFocus: `1px solid ${COLOR_NEUTRALS_4}`,
  colorSellerCardIcon: COLOR_NEUTRALS_4,
  colorSellerCardHeaderBorder: COLOR_NEUTRALS_3,
  colorSellerCardText: COLOR_NEUTRALS_8,
  colorSellerCardCurrency: COLOR_NEUTRALS_6,
};
