import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Medium: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 20"
    sx={{
      width: '20px',
      height: '20px',
      ...sx,
    }}
  >
    <g clipPath="url(#clip0_2343_20148)">
      <path
        d="M11.2812 9.8565C11.2812 12.9988 8.75578 15.5461 5.6407 15.5461C2.52561 15.5461 0 12.9982 0 9.8565C0 6.71481 2.52542 4.16675 5.6407 4.16675C8.75598 4.16675 11.2812 6.71423 11.2812 9.8565Z"
        fill="#8E87AF"
      />
      <path
        d="M17.4688 9.85646C17.4688 12.8142 16.2061 15.2129 14.6485 15.2129C13.0908 15.2129 11.8281 12.8142 11.8281 9.85646C11.8281 6.8987 13.0906 4.5 14.6483 4.5C16.2059 4.5 17.4686 6.89793 17.4686 9.85646"
        fill="#8E87AF"
      />
      <path
        d="M20.0003 9.8564C20.0003 12.5058 19.5562 14.6549 19.0083 14.6549C18.4604 14.6549 18.0166 12.5064 18.0166 9.8564C18.0166 7.2064 18.4606 5.05786 19.0083 5.05786C19.556 5.05786 20.0003 7.2062 20.0003 9.8564Z"
        fill="#8E87AF"
      />
    </g>
    <defs>
      <clipPath id="clip0_2343_20148">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
