import {
  COLOR_NEUTRALS_1,
  COLOR_NEUTRALS_2,
  COLOR_NEUTRALS_3,
  COLOR_NEUTRALS_4,
  COLOR_NEUTRALS_5,
  COLOR_NEUTRALS_6,
  COLOR_NEUTRALS_6_LIGHT,
  COLOR_NEUTRALS_8,
} from '../colors.constant';

export const colorsTopCollectionCardLight = {
  colorTopCollectionCardBackgroundDefault: COLOR_NEUTRALS_6_LIGHT,
  colorTopCollectionCardBackgroundHover: COLOR_NEUTRALS_6,
  colorTopCollectionCardBackgroundFocus: COLOR_NEUTRALS_6,
  colorTopCollectionCardBorderDefault: `1px solid ${COLOR_NEUTRALS_6}`,
  colorTopCollectionCardBorderFocus: `1px solid ${COLOR_NEUTRALS_5}`,
  colorTopCollectionCardTextDefault: COLOR_NEUTRALS_3,
  colorTopCollectionCardFloorText: COLOR_NEUTRALS_4,
};

export const colorsTopCollectionCardDark = {
  colorTopCollectionCardBackgroundDefault: COLOR_NEUTRALS_1,
  colorTopCollectionCardBackgroundHover: COLOR_NEUTRALS_2,
  colorTopCollectionCardBackgroundFocus: COLOR_NEUTRALS_2,
  colorTopCollectionCardBorderDefault: `1px solid ${COLOR_NEUTRALS_1}`,
  colorTopCollectionCardBorderFocus: `1px solid ${COLOR_NEUTRALS_4}`,
  colorTopCollectionCardTextDefault: COLOR_NEUTRALS_8,
  colorTopCollectionCardFloorText: COLOR_NEUTRALS_4,
};
