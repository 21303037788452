import {
  COLOR_NEUTRALS_1,
  COLOR_NEUTRALS_2,
  COLOR_NEUTRALS_3,
  COLOR_NEUTRALS_4,
  COLOR_NEUTRALS_5,
  COLOR_NEUTRALS_6,
  COLOR_NEUTRALS_7,
  COLOR_NEUTRALS_8,
  COLOR_PRIMARY_4,
} from 'theme/colors';

export const playerColors = {
  dark: {
    colorPlayerBackground: COLOR_NEUTRALS_1,
    colorPlayerBoxShadow: 'none',
    colorPlayerPlayColor: COLOR_NEUTRALS_7,
    colorPlayerPlayColorHover: COLOR_NEUTRALS_4,
    colorPlayerProgressColor: COLOR_PRIMARY_4,
    colorPlayerProgressBackground: COLOR_NEUTRALS_3,
    colorPlayerDurationColor: COLOR_NEUTRALS_5,
    colorPlayerControlColor: COLOR_NEUTRALS_4,
    colorPlayerControlColorHover: COLOR_NEUTRALS_4,
  },
  light: {
    colorPlayerBackground: COLOR_NEUTRALS_8,
    colorPlayerBoxShadow: '0px 8px 16px -8px rgba(15, 15, 15, 0.2)',
    colorPlayerPlayColor: COLOR_NEUTRALS_5,
    colorPlayerPlayColorHover: COLOR_NEUTRALS_4,
    colorPlayerProgressColor: COLOR_PRIMARY_4,
    colorPlayerProgressBackground: COLOR_NEUTRALS_6,
    colorPlayerDurationColor: COLOR_NEUTRALS_2,
    colorPlayerControlColor: COLOR_NEUTRALS_4,
    colorPlayerControlColorHover: COLOR_NEUTRALS_4,
  },
};
