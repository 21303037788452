import {
  COLOR_NEUTRALS_1,
  COLOR_NEUTRALS_3,
  COLOR_NEUTRALS_4,
  COLOR_NEUTRALS_5,
  COLOR_NEUTRALS_6,
  COLOR_NEUTRALS_7,
} from '../colors.constant';

export const colorsRadioAndCheckboxLight = {
  colorRadioAndCheckboxBackground: COLOR_NEUTRALS_7,
  colorRadioAndCheckboxBorder: COLOR_NEUTRALS_6,
  colorRadioAndCheckboxBorderHover: COLOR_NEUTRALS_5,
};

export const colorsRadioAndCheckboxDark = {
  colorRadioAndCheckboxBackground: COLOR_NEUTRALS_1,
  colorRadioAndCheckboxBorder: COLOR_NEUTRALS_3,
  colorRadioAndCheckboxBorderHover: COLOR_NEUTRALS_4,
};
