import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Doughnut: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 16 16"
    sx={{
      width: '16px',
      height: '16px',
      ...sx,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00065 13.6666C10.6825 13.6666 13.6673 10.6818 13.6673 6.99992C13.6673 3.31802 10.6825 0.333252 7.00065 0.333252C3.31875 0.333252 0.333984 3.31802 0.333984 6.99992C0.333984 10.6818 3.31875 13.6666 7.00065 13.6666ZM11.2138 10.2704C11.9159 9.36727 12.334 8.23242 12.334 6.99992C12.334 4.28017 10.2982 2.03591 7.66732 1.70785V4.41726C8.81747 4.71329 9.66732 5.75736 9.66732 6.99992C9.66732 7.49448 9.53268 7.9576 9.29807 8.35463L11.2138 10.2704ZM10.271 11.2132L8.35523 9.29741C7.95824 9.53198 7.49516 9.66659 7.00065 9.66659C6.50614 9.66659 6.04306 9.53198 5.64607 9.29741L3.73028 11.2132C4.63339 11.9152 5.7682 12.3333 7.00065 12.3333C8.2331 12.3333 9.36791 11.9152 10.271 11.2132ZM4.33398 6.99992C4.33398 7.49448 4.46862 7.9576 4.70323 8.35463L2.78746 10.2704C2.08539 9.36727 1.66732 8.23242 1.66732 6.99992C1.66732 4.28017 3.70311 2.03591 6.33398 1.70785V4.41726C5.18383 4.71329 4.33398 5.75736 4.33398 6.99992ZM7.00065 8.33325C7.73703 8.33325 8.33398 7.7363 8.33398 6.99992C8.33398 6.26354 7.73703 5.66659 7.00065 5.66659C6.26427 5.66659 5.66732 6.26354 5.66732 6.99992C5.66732 7.7363 6.26427 8.33325 7.00065 8.33325Z"
      fill="currentColor"
    />
  </SvgIcon>
);
