import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Add: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 14 14"
    sx={{
      width: '14px',
      height: '14px',
      ...sx,
    }}
  >
    <path
      d="M1.113 0.801C1.453 0.504 1.857 0.275 2.303 0.14L2.55 0.0750003L2.71 0.0449996L2.886 0.02L3.066 0.00499965L3.25 1.12008e-08H10.75C11.58 -6.88527e-05 12.3785 0.317401 12.9819 0.887309C13.5853 1.45722 13.9478 2.23637 13.995 3.065L14 3.25V10.75C14.0001 11.5801 13.6824 12.3788 13.1123 12.9822C12.5422 13.5856 11.7628 13.948 10.934 13.995L10.75 14H3.25C2.41986 14.0001 1.62117 13.6824 1.01777 13.1123C0.414367 12.5422 0.0519987 11.7628 0.00500011 10.934L0 10.751V3.251L0.00300026 3.102L0.0170002 2.912L0.0430002 2.721L0.0790005 2.535L0.138 2.312L0.218 2.076L0.305 1.874L0.387 1.712L0.481 1.549L0.627 1.332L0.721 1.209L0.856 1.053L0.964 0.941L1.113 0.801ZM7 3.001C6.81876 3.00101 6.64366 3.06664 6.50707 3.18577C6.37048 3.30489 6.28165 3.46945 6.257 3.649L6.25 3.751V6.25H3.75C3.56876 6.25001 3.39366 6.31564 3.25707 6.43477C3.12048 6.55389 3.03165 6.71845 3.007 6.898L3 7C3 7.38 3.282 7.693 3.648 7.743L3.75 7.75H6.25V10.251C6.25 10.631 6.532 10.945 6.898 10.994L7 11.001C7.18124 11.001 7.35634 10.9354 7.49293 10.8162C7.62952 10.6971 7.71835 10.5326 7.743 10.353L7.75 10.251V7.75H10.25C10.4314 7.74996 10.6066 7.68418 10.7432 7.56486C10.8798 7.44553 10.9686 7.28074 10.993 7.101L11 7.001C11 6.81976 10.9344 6.64466 10.8152 6.50807C10.6961 6.37148 10.5316 6.28265 10.352 6.258L10.25 6.251H7.75V3.751C7.74999 3.56976 7.68436 3.39466 7.56523 3.25807C7.44611 3.12148 7.28155 3.03265 7.102 3.008L7 3.001Z"
      fill="currentColor"
    />
  </SvgIcon>
);
