import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Help: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    sx={{
      ...sx,
      width: '24px',
      height: '24px',
    }}
  >
    <path
      d="M23.9994 16.2421C23.9994 13.2639 22.2908 10.6059 19.7319 9.31299C19.6525 15.0233 15.0228 19.653 9.3125 19.7324C10.6054 22.2913 13.2634 23.9999 16.2416 23.9999C17.6379 23.9999 18.9958 23.628 20.189 22.9214L23.9655 23.966L22.9209 20.1895C23.6275 18.9963 23.9994 17.6384 23.9994 16.2421Z"
      fill="currentColor"
    />
    <path
      d="M18.3281 9.16406C18.3281 4.1109 14.2172 0 9.16406 0C4.1109 0 0 4.1109 0 9.16406C0 10.8109 0.438354 12.4144 1.27075 13.8221L0.0336914 18.2943L4.50604 17.0574C5.91376 17.8898 7.51721 18.3281 9.16406 18.3281C14.2172 18.3281 18.3281 14.2172 18.3281 9.16406ZM7.75781 7.03125H6.35156C6.35156 5.48035 7.61316 4.21875 9.16406 4.21875C10.715 4.21875 11.9766 5.48035 11.9766 7.03125C11.9766 7.81842 11.6433 8.57501 11.062 9.10675L9.86719 10.2003V11.2969H8.46094V9.58099L10.1125 8.06927C10.4077 7.79919 10.5703 7.4306 10.5703 7.03125C10.5703 6.2558 9.93951 5.625 9.16406 5.625C8.38861 5.625 7.75781 6.2558 7.75781 7.03125ZM8.46094 12.7031H9.86719V14.1094H8.46094V12.7031Z"
      fill="currentColor"
    />
  </SvgIcon>
);
