import React, { FC, MutableRefObject, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { Box, Button, Divider, Stack, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { routes } from 'appConstants';
import { AvatarUploader, BackgroundImageCover, Email, Site, Socials, SocialsProps } from 'components';
import { Edit } from 'components/Icon/components';
import { useGetQuery, useStateParam } from 'hooks';
import { COLOR_NEUTRALS_6 } from 'theme/colors';
import { FontWeights } from 'theme/Typography';
import { toTitleCase } from 'utils';

export type GameHeaderProps = {
  chainImg?: string;
  chainName?: string;
  cover?: string;
  socials?: SocialsProps;
  name?: string;
  avatar?: string;
  site?: string;
  email?: string;
  description?: string;
  isAbleToEdit?: boolean;
  gameId: string;
  mediaRef?: MutableRefObject<HTMLDivElement | null>;
  overviewRef?: MutableRefObject<HTMLDivElement | null>;
  shouldShowMedia?: boolean;
  onDeleteAvatar: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onUploadAvatar: (file: File | null) => void;
};

export enum GameTab {
  Nft,
  Media,
}

const gameTabs = [
  { value: GameTab.Nft, label: 'NFT' },
  { value: GameTab.Media, label: 'Media' },
];

export const GameHeader: FC<GameHeaderProps> = ({
  chainName,
  chainImg,
  cover,
  socials,
  name,
  avatar,
  description,
  site,
  email,
  isAbleToEdit,
  gameId,
  mediaRef,
  overviewRef,
  shouldShowMedia,
  onDeleteAvatar,
  onUploadAvatar,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const currentNetwork = useGetQuery('network');

  // const [gameTab, setGameTab] = useStateParam('tab', null);

  // const handleChange = (_, newGameTab: GameTab) => {
  //   setGameTab(newGameTab);
  // };

  const handleAddCategoryClick = () => {
    navigate(routes.games.game.addNewCategory.root.getPath(gameId, currentNetwork));
  };

  const textColor = useMemo(() => (cover ? COLOR_NEUTRALS_6 : theme.themeColors.colorTextDefault), [cover, theme]);

  return (
    <BackgroundImageCover
      cover={cover}
      defaultTransparentBanner
      spacing={3}
      direction="column"
      sx={{
        paddingTop: { xs: 8, md: 0 },
        '&::before': {
          top: { xs: -138, md: -114 },
        },
      }}
    >
      <Stack direction="row" spacing={3} alignItems="center">
        <AvatarUploader
          isAbleToEdit={isAbleToEdit}
          avatar={avatar}
          onUploadAvatar={onUploadAvatar}
          onDeleteAvatar={onDeleteAvatar}
          isSquare
          sx={{ button: { color: textColor } }}
        />
        <Typography
          variant="h2"
          color={textColor}
          sx={{
            transform: 'translateY(-10px)',
            fontSize: { xs: '30px', sm: '48px' },
            maxWidth: 700,
            fontWeight: { xs: FontWeights.fontWeightSemiBold, sm: FontWeights.fontWeightBold },
            wordBreak: 'break-all',
          }}
        >
          {toTitleCase(name)}
        </Typography>
      </Stack>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <Socials {...socials} />
        {isAbleToEdit && (
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => overviewRef?.current?.scrollIntoView({ behavior: 'smooth' })}
            sx={{ padding: theme.spacing(1.75, 5.625), width: { xs: '100%', md: 'auto' } }}
          >
            More info
          </Button>
        )}
      </Box>

      <Stack
        spacing={{ xs: 3, sm: 0 }}
        direction={{ xs: 'column-reverse', sm: 'row' }}
        sx={{ width: '100%', justifyContent: 'space-between', alignItems: { xs: 'flex-start', sm: 'center' } }}
      >
        <Tabs
          value={GameTab.Nft}
          // onChange={handleChange}
          variant="scrollable"
          scrollButtons={false}
          sx={{ minHeight: 0 }}
        >
          <Tab label="NFT" />
          {shouldShowMedia && (
            <Tab
              label="Media"
              onClick={() => mediaRef?.current?.scrollIntoView({ behavior: 'smooth' })}
              sx={{ marginLeft: 1 }}
            />
          )}
        </Tabs>
        {isAbleToEdit ? (
          <Button onClick={handleAddCategoryClick} size="small" sx={{ width: { xs: '100%', sm: 'fit-content' } }}>
            Add new category
          </Button>
        ) : (
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => overviewRef?.current?.scrollIntoView({ behavior: 'smooth' })}
            sx={{ padding: theme.spacing(1.75, 5.625), width: { xs: '100%', md: 'auto' } }}
          >
            More info
          </Button>
        )}
      </Stack>

      <Divider sx={{ my: 3 }} />
    </BackgroundImageCover>
  );
};
