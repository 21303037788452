import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Slippers: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 18"
    sx={{
      ...sx,
      width: '20px',
      height: '18px',
    }}
  >
    <path
      d="M14.3806 16.2484C12.0343 16.2484 10.0914 14.5342 10.0181 12.3354L9.72478 4.69565C9.68812 3.46584 10.128 2.31056 10.9712 1.41615C11.8877 0.521739 13.0975 0 14.3806 0C15.6637 0 16.8735 0.521739 17.7533 1.45342C18.5965 2.34783 19.0364 3.50311 18.9997 4.73292L18.7064 12.3727C18.6331 14.5342 16.7268 16.2484 14.3806 16.2484ZM14.3806 1.49068C13.5007 1.49068 12.6576 1.82609 12.071 2.45963C11.4845 3.0559 11.1912 3.83851 11.2278 4.65839L11.4845 12.2981C11.5211 13.677 12.8409 14.7578 14.3806 14.7578C15.9203 14.7578 17.2034 13.6398 17.2767 12.2981L17.57 4.65839C17.6067 3.83851 17.3134 3.0559 16.7268 2.45963C16.1036 1.82609 15.2604 1.49068 14.3806 1.49068Z"
      fill="currentColor"
    />
    <path
      d="M17.423 8.34779L14.4536 4.73289L11.3375 8.27326H9.87109L14.0137 3.54034C14.1236 3.42854 14.3069 3.354 14.4902 3.354C14.6735 3.354 14.8202 3.42854 14.9302 3.57761L18.8894 8.38506L17.423 8.34779Z"
      fill="currentColor"
    />
    <path
      d="M5.25289 17.9999C3.08996 17.9999 1.33028 16.3975 1.25696 14.385L1.00034 7.49062C0.963684 6.37261 1.36694 5.29186 2.1368 4.47199C2.94332 3.61484 4.04311 3.13037 5.25289 3.13037C6.46267 3.13037 7.56246 3.61484 8.36898 4.47199C9.13884 5.29186 9.5421 6.37261 9.50544 7.49062L9.24882 14.385C9.21216 16.3975 7.45248 17.9999 5.25289 17.9999ZM5.25289 4.62105C4.44637 4.62105 3.71318 4.91919 3.19994 5.4782C2.6867 6.0372 2.43008 6.70801 2.46674 7.45335L2.72336 14.3478C2.76002 15.5403 3.89647 16.5093 5.25289 16.5093C6.60931 16.5093 7.74576 15.5403 7.78242 14.3478L8.03904 7.45335C8.0757 6.74528 7.81908 6.0372 7.30584 5.51546C6.79261 4.91919 6.05941 4.62105 5.25289 4.62105Z"
      fill="currentColor"
    />
    <path
      d="M2.57577 10.7702L1.10938 10.8075L4.70204 6.44723C4.81202 6.29817 4.99532 6.22363 5.14196 6.22363C5.32526 6.22363 5.4719 6.29817 5.61854 6.44723L9.39451 10.6584L7.92811 10.6957L5.17862 7.63978L2.57577 10.7702Z"
      fill="currentColor"
    />
    <path
      d="M14.3806 16.2484C12.0343 16.2484 10.0914 14.5342 10.0181 12.3354L9.72478 4.69565C9.68812 3.46584 10.128 2.31056 10.9712 1.41615C11.8877 0.521739 13.0975 0 14.3806 0C15.6637 0 16.8735 0.521739 17.7533 1.45342C18.5965 2.34783 19.0364 3.50311 18.9997 4.73292L18.7064 12.3727C18.6331 14.5342 16.7268 16.2484 14.3806 16.2484ZM14.3806 1.49068C13.5007 1.49068 12.6576 1.82609 12.071 2.45963C11.4845 3.0559 11.1912 3.83851 11.2278 4.65839L11.4845 12.2981C11.5211 13.677 12.8409 14.7578 14.3806 14.7578C15.9203 14.7578 17.2034 13.6398 17.2767 12.2981L17.57 4.65839C17.6067 3.83851 17.3134 3.0559 16.7268 2.45963C16.1036 1.82609 15.2604 1.49068 14.3806 1.49068Z"
      fill="currentColor"
    />
    <path
      d="M17.423 8.34779L14.4536 4.73289L11.3375 8.27326H9.87109L14.0137 3.54034C14.1236 3.42854 14.3069 3.354 14.4902 3.354C14.6735 3.354 14.8202 3.42854 14.9302 3.57761L18.8894 8.38506L17.423 8.34779Z"
      fill="currentColor"
    />
    <path
      d="M5.25289 17.9999C3.08996 17.9999 1.33028 16.3975 1.25696 14.385L1.00034 7.49062C0.963684 6.37261 1.36694 5.29186 2.1368 4.47199C2.94332 3.61484 4.04311 3.13037 5.25289 3.13037C6.46267 3.13037 7.56246 3.61484 8.36898 4.47199C9.13884 5.29186 9.5421 6.37261 9.50544 7.49062L9.24882 14.385C9.21216 16.3975 7.45248 17.9999 5.25289 17.9999ZM5.25289 4.62105C4.44637 4.62105 3.71318 4.91919 3.19994 5.4782C2.6867 6.0372 2.43008 6.70801 2.46674 7.45335L2.72336 14.3478C2.76002 15.5403 3.89647 16.5093 5.25289 16.5093C6.60931 16.5093 7.74576 15.5403 7.78242 14.3478L8.03904 7.45335C8.0757 6.74528 7.81908 6.0372 7.30584 5.51546C6.79261 4.91919 6.05941 4.62105 5.25289 4.62105Z"
      fill="currentColor"
    />
    <path
      d="M2.57577 10.7702L1.10938 10.8075L4.70204 6.44723C4.81202 6.29817 4.99532 6.22363 5.14196 6.22363C5.32526 6.22363 5.4719 6.29817 5.61854 6.44723L9.39451 10.6584L7.92811 10.6957L5.17862 7.63978L2.57577 10.7702Z"
      fill="currentColor"
    />
  </SvgIcon>
);
