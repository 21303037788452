import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Globe: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 16 16"
    sx={{
      width: '20px',
      height: '20px',
      ...sx,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00065 13.3333C10.9462 13.3333 13.334 10.9454 13.334 7.99992C13.334 5.0544 10.9462 2.66659 8.00065 2.66659C5.05513 2.66659 2.66732 5.0544 2.66732 7.99992C2.66732 10.9454 5.05513 13.3333 8.00065 13.3333ZM8.00065 14.6666C11.6825 14.6666 14.6673 11.6818 14.6673 7.99992C14.6673 4.31802 11.6825 1.33325 8.00065 1.33325C4.31875 1.33325 1.33398 4.31802 1.33398 7.99992C1.33398 11.6818 4.31875 14.6666 8.00065 14.6666Z"
      fill="#8E87AF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.1638 12.1177C9.65627 11.1327 9.99935 9.67876 9.99935 7.99992C9.99935 6.32108 9.65627 4.8671 9.1638 3.88216C8.63146 2.81747 8.14272 2.66659 7.99935 2.66659C7.85598 2.66659 7.36724 2.81747 6.8349 3.88216C6.34243 4.8671 5.99935 6.32108 5.99935 7.99992C5.99935 9.67876 6.34243 11.1327 6.8349 12.1177C7.36724 13.1824 7.85598 13.3333 7.99935 13.3333C8.14272 13.3333 8.63146 13.1824 9.1638 12.1177ZM7.99935 14.6666C9.8403 14.6666 11.3327 11.6818 11.3327 7.99992C11.3327 4.31802 9.8403 1.33325 7.99935 1.33325C6.1584 1.33325 4.66602 4.31802 4.66602 7.99992C4.66602 11.6818 6.1584 14.6666 7.99935 14.6666Z"
      fill="#8E87AF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6344 8.66659C14.6562 8.44731 14.6673 8.22492 14.6673 7.99992C14.6673 7.77492 14.6562 7.55252 14.6344 7.33325H1.3669C1.34513 7.55252 1.33398 7.77492 1.33398 7.99992C1.33398 8.22492 1.34513 8.44731 1.3669 8.66659H14.6344Z"
      fill="#8E87AF"
    />
  </SvgIcon>
);
