import {
  COLOR_GRADIENT_ACCENT,
  COLOR_NEUTRALS_2,
  COLOR_NEUTRALS_3,
  COLOR_NEUTRALS_7,
  COLOR_NEUTRALS_8,
  COLOR_NEUTRALS_10,
  COLOR_PRIMARY_2,
} from '../colors.constant';

export const colorsButtonLight = {
  colorButtonNeutralTextDefault: COLOR_NEUTRALS_8,
  colorButtonNeutralBackgroundDefault: COLOR_GRADIENT_ACCENT,
  colorButtonNeutralBackgroundHover: COLOR_PRIMARY_2,
  colorButtonNeutralBackgroundDisabled: `rgba (${COLOR_PRIMARY_2}, 0.5)`,
  colorButtonTextDefault: COLOR_NEUTRALS_8,
  colorButtonTextOutlined: COLOR_NEUTRALS_3,
  colorButtonTextHover: COLOR_NEUTRALS_7,
  colorButtonIconDefault: COLOR_NEUTRALS_8,
  colorButtonIconHover: COLOR_NEUTRALS_2,
  colorButtonBorderDefault: COLOR_NEUTRALS_10,
  colorButtonBorderHover: COLOR_NEUTRALS_8,
  colorButtonBackgroundDefault: 'transparent',
  colorButtonBackgroundHover: COLOR_NEUTRALS_2,
  colorButtonBackgroundDisabled: `rgba (${COLOR_NEUTRALS_8}, 0.5)`,
};

export const colorsButtonDark = {
  colorButtonNeutralTextDefault: COLOR_NEUTRALS_8,
  colorButtonNeutralBackgroundDefault: COLOR_GRADIENT_ACCENT,
  colorButtonNeutralBackgroundHover: COLOR_PRIMARY_2,
  colorButtonNeutralBackgroundDisabled: `rgba (${COLOR_PRIMARY_2}, 0.5)`,
  colorButtonTextDefault: COLOR_NEUTRALS_2,
  colorButtonTextOutlined: COLOR_NEUTRALS_7,
  colorButtonTextHover: COLOR_NEUTRALS_8,
  colorButtonIconDefault: COLOR_NEUTRALS_2,
  colorButtonIconHover: COLOR_NEUTRALS_8,
  colorButtonBorderDefault: COLOR_NEUTRALS_3,
  colorButtonBorderHover: COLOR_NEUTRALS_2,
  colorButtonBackgroundDefault: 'transparent',
  colorButtonBackgroundHover: COLOR_NEUTRALS_2,
  colorButtonBackgroundDisabled: COLOR_NEUTRALS_8,
};
