import { COLOR_NEUTRALS_4, COLOR_NEUTRALS_5 } from 'theme/colors';

export const nftDetailsCreatorCollectionColors = {
  light: {
    colorNftDetailsCreatorCollectionTitle: COLOR_NEUTRALS_5,
  },
  dark: {
    colorNftDetailsCreatorCollectionTitle: COLOR_NEUTRALS_4,
  },
};
