import { encodedURIString } from 'utils/encodedURIString';

export default {
  getMetamaskMessage: (address: string) => `/account/get_metamask_message/${address}`,
  metamaskLogin: '/account/metamask_login/',
  getSelfInfo: '/account/self/',
  setSelfCover: '/account/self/set_cover/',
  getNftData: (tokenId: string) => `/store/token/${tokenId}`,
  setOnSale: (tokenId: string) => `/store/token/${tokenId}/set_on_sale/`,
  setOnAuction: (tokenId: string) => `/store/token/${tokenId}/set_on_auction/`,
  burn: (tokenId: string) => `/store/token/${tokenId}/burn/`,
  transfer: (tokenId: string) => `/store/token/${tokenId}/transfer/`,
  removeFromSale: (tokenId: string) => `/store/token/${tokenId}/remove_from_sale/`,
  getRelatedTokens: (tokenId: string) => `/store/token/${tokenId}/related/`,
  getMyCollections: '/account/self/collections/',
  rates: '/rates/',
  presearch: '/store/presearch/',
  buy: '/store/buy/',
  bid: '/store/bids/make_bid/',
  endAuction: (tokenId: string) => `/store/token/${tokenId}/end_auction/`,
  buyReject: '/store/buy-reject/',
  trackTransaction: '/store/track_transaction/',
  promote: '/promotion/',
  like: '/account/self/like/',
  getRegistrationStatus: `/registration/`,
  signUp: '/registration/',
  getAirdropData: '/airdrop/',
  search: '/store/search/',
  follow: '/account/self/follow/',
  unFollow: '/account/self/unfollow/',
  getWsToken: '/account/self/get_ws_token/',
  followers: (id: number | string) => encodedURIString`/account/${id}/followers`,
  following: (id: number | string) => encodedURIString`/account/${id}/following`,
  getProfileInfoById: (id: string | number) => encodedURIString`/account/${id}`,

  notifications: 'activity/notification/',
  getUserNotifications: (address: string) => encodedURIString`/activity/${address}`,
  getUserFollowersNotifications: (address: string) => encodedURIString`/activity/${address}/following`,
  getMaxPrice: 'store/max_price/',

  getTopUsers: '/activity/topusers/',
  favorites: '/store/favorites/',
  categories: '/store/categories/',
  trendingTokens: '/store/trending_tokens/',
  topCollections: '/activity/top-collections/',
  createNewCollection: '/store/create_collection/',
  mintReject: '/store/mint-reject/',
  createToken: '/store/create_token/',
  getSingleCollection: (id: number | string) => encodedURIString`/store/collection/${id}/`,
  editCollection: (id: number | string) => encodedURIString`/store/collection/${id}/`,
  getCollectionActivity: (id: number | string) => encodedURIString`/activity/collections/${id}/`,
  getCollectionChart: (id: number | string) => encodedURIString`/store/collection/${id}/chart/`,
  getCollectionTradeData: (id: number | string) => encodedURIString`/store/collection/${id}/trade_data/`,
  setCollectionCover: '/store/set_cover/',

  getConfig: '/config/',
  getNetworkInfo: (network: string) => `/networks/${network}`,
  mailSubscribe: '/mail_subscription/',

  getGames: '/games/',
  getOwnedGames: '/games/owned/',
  editGame: (id: number | string, network: string) => encodedURIString`/games/${id}/${network}/`,
  listGame: '/games/listing/',
  getGame: (id: number | string, network: string) => encodedURIString`/games/${id}/${network}/`,
  getCategory: ({ gameId, categoryId, network }) =>
    encodedURIString`/games/${gameId}/${network}/category/${categoryId}/`,
  addCategory: (gameId: string, network: string) => encodedURIString`/games/${gameId}/${network}/category_add/`,
  addSubcategory: ({ gameId, categoryId, network }) =>
    encodedURIString`/games/${gameId}/${network}/category/${categoryId}/subcategory_add/`,
  getSubcategory: ({ gameId, categoryId, subcategoryId, network }) =>
    encodedURIString`/games/${gameId}/${network}/category/${categoryId}/subcategory/${subcategoryId}/`,
  addCollections: ({ gameId, categoryId, subcategoryId, network }) =>
    encodedURIString`/games/${gameId}/${network}/category/${categoryId}/subcategory/${subcategoryId}/collection_add/`,
  deleteGameItem: ({ itemType, id }) => encodedURIString`/games/${itemType}/${id}`,
};
