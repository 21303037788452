import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const DownloadCloud: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    sx={{
      ...sx,
      width: '24px',
      height: '24px',
    }}
  >
    <path
      d="M13.4062 11.2969H10.5937C10.205 11.2969 9.89054 11.6114 9.89054 12V18.3281H8.48429C8.21786 18.3281 7.97481 18.4785 7.85532 18.7168C7.73654 18.955 7.76195 19.24 7.92193 19.4528L11.4376 23.7185C11.5701 23.8956 11.7788 24 11.9999 24C12.221 24 12.4298 23.8956 12.5623 23.7185L16.0779 19.4528C16.2379 19.24 16.2633 18.955 16.1445 18.7168C16.025 18.4785 15.782 18.3281 15.5155 18.3281H14.1093V12C14.1093 11.6114 13.7948 11.2969 13.4062 11.2969Z"
      fill="currentColor"
    />
    <path
      d="M19.6636 7.07883C19.3746 5.66297 18.2244 4.5547 16.7365 4.3192C16.1226 1.84795 13.8766 0 11.2969 0C8.72264 0 6.50133 1.82048 5.8717 4.27111C5.80575 4.26769 5.73919 4.26562 5.67188 4.26562C2.5703 4.26562 0 6.78905 0 9.89062C0 12.9922 2.5703 15.5156 5.67188 15.5156H8.48438V12C8.48438 10.8368 9.43059 9.89062 10.5938 9.89062H13.4062C14.5694 9.89062 15.5156 10.8368 15.5156 12V15.5156H19.7344C22.0607 15.5156 24 13.6232 24 11.2969C24 8.94717 22.0284 7.02047 19.6636 7.07883Z"
      fill="currentColor"
    />
  </SvgIcon>
);
