export const notifyText = {
  disconnet: {
    info: 'Disconnect from wallet ',
  },
  approve: {
    success: 'Successful approve',
    error: 'Error approve',
  },
  registration: {
    success: 'Wallet address successfully added',
    error: 'This wallet address is already registered',
  },
};
