import { Box, Link, Typography, useTheme } from '@mui/material';
import { COLOR_PRIMARY_1 } from 'theme/colors';

export const TermsOfService = () => {
  const theme = useTheme();
  return (
    <Box sx={{ marginBottom: 8, marginX: { xs: 0, md: 24 } }}>
      <Typography variant="h2" sx={{ marginBottom: 5 }}>
        Terms of service
      </Typography>
      <Typography sx={{ marginBottom: 2 }}>
        Company No.: 2106254
        <br />
        Company Name : Katana Marketplace Ltd
      </Typography>
      <Typography sx={{ color: theme.themeColors.colorTextFieldBorderHover, marginBottom: 5 }}>
        Last Revised on April 12, 2022
      </Typography>
      <Typography sx={{ marginBottom: 8 }}>
        Welcome to the Terms of Service (these “Terms”) for the website https://kainu.io/ (the “Website”), the Katana
        Inu game (the “Game”), and all other applications, content, smart contracts, tools, features, and functionality
        offered on or through our Website and the Game (collectively with the Website and the Game, the “Services”), in
        each case operated by or on behalf of ChainVision Games OÜ (“Company,” “we,” or “us”).
        <br />
        <br />
        These Terms govern your access to and use of the Services. Please read these Terms carefully, as they include
        important information about your legal rights. By accessing and/or using the Services, you are agreeing to these
        Terms. If you do not understand or agree to these Terms, please do not use the Services.
        <br />
        <br />
        For purposes of these Terms, “you” and “your” means you as the user of the Services. If you use the Services on
        behalf of a company or other entity then “you” includes you and that entity, and you represent and warrant that
        (a) you are an authorized representative of the entity with the authority to bind the entity to these Terms, and
        (b) you agree to these Terms on the entity’s behalf.
        <br />
        <br />
        Section 9 contains an arbitration clause and class action waiver. By agreeing to these Terms, you agree (a) to
        resolve all disputes with us related to the Services through binding individual arbitration (unless expressly
        provided otherwise in Section 9), which means that you waive any right to have those disputes decided by a judge
        or jury, and (b) to waive your right to participate in class actions, class arbitrations, or representative
        actions in connection with your use of the Services. You have the right to opt-out of arbitration as explained
        in Section 9.
      </Typography>

      <Typography variant="h2" sx={{ marginBottom: 2 }}>
        Table of contents
      </Typography>
      <Typography sx={{ marginBottom: 8 }}>
        1. SERVICES; WALLETS; ACCOUNTS2
        <br />
        <br />
        2. LOCATION OF OUR PRIVACY POLICY AND OTHER TERMS
        <br />
        <br />
        3. ELIGIBILITY; RIGHTS WE GRANT YOU; USAGE LIMITATIONS
        <br />
        <br />
        4. OWNERSHIP AND CONTENT
        <br />
        <br />
        5. THIRD-PARTY SERVICES AND MATERIALS
        <br />
        <br />
        6. UPDATES AND PATCHES
        <br />
        <br />
        7. TERMINATION
        <br />
        <br />
        8 DISCLAIMERS; LIMITATIONS OF LIABILITY; INDEMNIFICATION
        <br />
        <br />
        9. GOVERNING LAW; ARBITRATION; CLASS ACTION WAIVER
        <br />
        <br />
        10. ADDITIONAL PROVISIONS
      </Typography>
      <Typography variant="h4" sx={{ marginBottom: 2 }}>
        1. Services; wallets; accounts
      </Typography>
      <Typography sx={{ marginBottom: 2 }}>
        1. Description of the Services. The Services offer a unique metaverse gaming experience via the Game and other
        related experiences through the combination of traditional core game and blockchain mechanics via the Etherium
        blockchain (the “Katana Inu Metaverse”). The Katana Inu Metaverse is driven by a token system — “KATA”. KATA
        serves as the native in-Game currency used for in-Game economic interactions, such as purchasing
        resources,weapons, armors, and other in-Game assets. Further details on the Katana Inu Metaverse, including the
        uses of KATA, are set forth in the Katana Inu Economics whitepaper, currently available at our website, as we
        may update from time to time.
        <br />
        <br />
        2. DACs. The Katana Inu Metaverse utilizes a progression system that allows you to create different weapons and
        armors that operate in a decentralized, autonomous way (“Decentralized Autonomous Corporations”). The Services
        may provide you and other players with certain tools that enable the implementation and management of such
        Decentralized Autonomous Corporations. We reserve the right to revoke access from any Decentralized Autonomous
        Corporation, or member player thereof, to such tools in the event that such Decentralized Autonomous Corporation
        or player violates these Terms or for any other reason we determine in our good faith business judgment.
        <br />
        <br />
        3. Game Items. The Services provide a marketplace (the “Marketplace”) for you to purchase, transfer, and trade
        Game-related items such as weapons, armors, resources, and other items (“Game Items”). Game Items can be used in
        the Services in various ways that we or the Katana Inu DAO make available, such as to represent in-Game assets
        that you can use while you play the Game. We make no promises or guarantees regarding the functionality,
        utility, or value of any Game Items, and such Game Items may evolve and change as we or the Katana Inu DAO
        update and modify the Game and the Katana Inu Metaverse.
        <br />
        <br />
        4. Game NFTs. Your purchase of Game Items may be accompanied by non-fungible tokens (“NFTs”), and your ownership
        of these NFTs is tracked via the Etherium blockchain (“Game NFTs”). Your ownership of these Game NFTs will give
        you certain rights and privileges within the Services, for as long as we make the Services and such rights and
        privileges available to you. You are responsible for all payments, fees, and costs when engaging in any
        transactions involving Game NFTs, including, without limitation, the purchase price, transaction fees, fees
        associated with your secondary or subsequent sales of Game NFTs, and all other fees associated with your use of
        the Game NFTs via the Services.
        <br />
        <br />
        5. Marketplace. We provide the Marketplace to be able to sell Game Items and other Services-related offerings
        (collectively, “Offerings”) to you directly from us. The Marketplace will also facilitate peer-to-peer sales
        conducted via decentralized or third-party exchanges or protocols whereby buyers and sellers can buy, sell, and
        trade Game NFTs with each other. We may charge a transaction fee on all peer-to-peer sales that occur via the
        Marketplace or that otherwise involve Game NFTs (which may be calculated as a percentage of the overall sale
        price of the Game Items). We may offer ways to receive a discount on such transaction fees, such as by holding
        or staking certain Services-related tokens and assets. The scope of, and pre-requisitions for receiving any,
        such discount offers are subject to change by us in our sole discretion at any time. To facilitate transactions
        via the Marketplace, we have integrated with one or more third-party exchanges and services, and other
        decentralized smart contract protocols. These protocols facilitate the exchange of cryptocurrency and other
        assets through the Etherium blockchain. We do not own or control these third-party or decentralized services,
        including Etherium blockchain, we do not control the transfer of cryptocurrency using these third-party services
        or decentralized protocols, and we do not have the ability to cancel or reverse certain transactions via the
        Marketplace. Except as may otherwise be provided in these Terms, we do not control or endorse purchases or sales
        of Game NFTs or other Offerings outside of the Services, including the Marketplace. With respect to Game NFTs
        that are not sold directly by us, we are not a party to any agreement entered into between an end user buyer and
        a seller on the Marketplace. Other than with respect to items sold directly by us, we have no control over the
        conduct of buyers, sellers, and any other users of the Marketplace. We accept no liability for any aspect of the
        buyer and seller peer-to-peer interaction on the Marketplace.
        <br />
        <br />
        6. Wallet. To use certain of the Services, you need to link to the Services via a non-custodial digital wallet
        made available by a third party and that is compatible with the Etherium network and the Services (a “Digital
        Wallet”). A list of supported wallets will be displayed by us, such as on the Services website. Linking a
        Digital Wallet to the Services is required to be able to purchase, store, and engage in transactions using the
        Services, and to connect your Game NFTs and KATA tokens with the Services. Assets stored in your Digital Wallet
        are owned and controlled solely by you. Digital Wallets are not associated with, maintained by, controlled by,
        or affiliated with us. We may not be able to help you recover assets stored in your Digital Wallet, as these are
        non-custodial wallets in your control. We accept no responsibility or liability to you in connection with your
        use of a Digital Wallet and make no representations or warranties regarding how the Services will operate with
        any specific Digital Wallet. The private keys, credentials, passwords, seed phrases, or secret recovery
        passphrases necessary to decrypt or gain access to a Digital Wallet are held solely by you, and not by us. If
        you choose to transfer items from one Digital Wallet to another, such transfers will occur on the public
        blockchain and we accept no liability or responsibility for any such transfer, including for any loss, data
        corruption, or other negative impact that may occur to your Offerings or Digital Wallets or other assets when
        attempting to transfer assets between Digital Wallets. We reserve the right, with or without prior notice and in
        our sole and complete discretion, to discontinue, modify, or limit the operation of any Digital Wallet with the
        Services. You represent and warrant that you are the lawful owner of any Digital Wallet and that you are using
        that Digital Wallet under the terms and conditions of the applicable provider of the Digital Wallet. If you have
        any issues with your Digital Wallet, please contact your Digital Wallet provider.
        <br />
        <br />
        7. Promotional Codes. We may offer certain promotional codes, referral codes, discount codes, coupon codes, or
        similar offers (“Promotional Codes”) that may be redeemed for discounts on future Offerings, or other features
        or benefits related to the Services, subject to any additional terms that the Company establishes. You agree
        that Promotional Codes: (a) must be used in a lawful manner; (b) must be used for the intended audience and
        purpose; (c) may not be duplicated, sold, or transferred in any manner, or made available by you to the general
        public (whether posted to a public forum, coupon collecting service, or otherwise), unless expressly permitted
        by the Company; (d) may be disabled or have additional conditions applied to them by the Company at any time for
        any reason without liability to the Company; (e) may only be used pursuant to the specific terms that the
        Company establishes for such Promotional Code; (f) are not valid for cash or other credits or points; and (g)
        may expire prior to your use.
        <br />
        <br />
        8. Changes and Pricing. We may, at any time, revise or change the pricing, availability, specifications,
        content, descriptions, or features of any Offerings sold by us, and to correct pricing errors that may
        inadvertently occur (and to cancel any orders in our sole discretion that were purchased with pricing errors).
        All such changes shall be effective immediately upon posting of such new Offerings prices to the Services. While
        we attempt to be as accurate as we can in our descriptions for the Offerings, we do not warrant that Offerings
        descriptions are accurate, complete, reliable, current, or error-free. The inclusion of any Offerings for
        purchase through the Services at a particular time does not imply or warrant that the Offerings will be
        available at any other time. We reserve the right to change prices for Offerings displayed on the Services at
        any time.
        <br />
        <br />
        9. Purchases Made for Consumptive Use. You acknowledge and affirm that you are acquiring Offerings, whether
        through the Marketplace or otherwise through the Services, for purposes of use in connection with the Services
        and, in the case of the Game NFTs, to play the Game or for other collectible purposes, and in each case not for
        any investment or speculative purposes. Any economic benefit that may be derived from appreciation in the value
        of an Offering or Game NFT is incidental to obtaining it for its use in connection with the Services or its
        collectible purpose.
        <br /> <br />
        10. ALL SALES FINAL. ALL PURCHASES MADE THROUGH THE SERVICES ARE FINAL AND NON-REFUNDABLE, EXCEPT AS REQUIRED BY
        APPLICABLE LAW OR REGULATION (AND IN SUCH INSTANCES WHERE REQUIRED BY APPLICABLE LAW, THE COMPANY’S ABILITY TO
        ISSUE ANY SUCH REFUND IS LIMITED TO WHETHER THE APPLICABLE SMART CONTRACT ENABLES THE COMPANY TO REASONABLY
        REVERSE OR CANCEL A TRANSACTION). NEVERTHELESS, YOU ACKNOWLEDGE THAT THE COMPANY DOES NOT IN MOST CASES HAVE THE
        ABILITY TO REVERSE OR CANCEL A TRANSACTION AND IS NOT REQUIRED OR OBLIGATED TO PROVIDE A REFUND OF ANY PURCHASE
        FOR ANY REASON, AND THAT YOU WILL NOT RECEIVE MONEY OR OTHER COMPENSATION FOR TIME, ITEMS, AMOUNTS, OR VALUES IN
        A GAME ACCOUNT (INCLUDING ANY UNUSED OR PARTIAL USED MEMBERSHIP TIME), EVEN IF AN ACCOUNT IS CLOSED, WHETHER
        SUCH CLOSURE WAS VOLUNTARY OR INVOLUNTARY.
      </Typography>
      <Typography variant="h4" sx={{ marginBottom: 2 }}>
        2. Location of our privacy policy and other terms
      </Typography>
      <Typography sx={{ marginBottom: 4 }}>
        1. Privacy Policy. Our Privacy Policy describes how we handle the information you provide to us when you use the
        Services. For an explanation of our privacy practices, please visit our Privacy Policy located at{' '}
        <Link href="https://katanainu.com/privacy-policy" sx={{ color: COLOR_PRIMARY_1 }}>
          https://katanainu.com/privacy-policy
        </Link>
        .
        <br />
        <br />
        2. Other Terms. In connection with your use of the Services, you also agree to abide by the following other
        Services-related policies: our Discord Rules and our Community Guidelines (as presented to you if you join our
        Discord channel).
      </Typography>
      <Typography variant="h4" sx={{ marginBottom: 2 }}>
        3. Eligibility; rights we grant you; usage limitations
      </Typography>
      <Typography sx={{ marginBottom: 2 }}>
        1. Eligibility. You must be 13 years of age or older to use the Services. Additional aspects of the Services may
        have further age restrictions that will be presented to you in connection with those Services. Minors under the
        age of majority in their jurisdiction but that are at least 13 years of age are only permitted to use the
        Services if the minor’s parent or guardian or other holder of parental authority accepts these Terms on the
        minor’s behalf prior to use of the Services. Children under the age of 13 are not permitted to use the Services.
        You cannot use the Services if (1) the Office of Foreign Assets Control of the Estonia Treasury Department lists
        you as a specially designated national and/or blocked person; (2) the Bureau of Industry and Security of the
        Estonia Department of Commerce lists you on its denied persons list or lists of parties of concern; or (3)
        you’re on any similar list promulgated by an official agency or department of the Estonia government. Further,
        if you use the Services, you may not transfer or provide to us any currency, digital assets, or other items that
        have been derived from any illegal or unlawful activity. By using the Services, you represent and warrant that
        you meet these all requirements.
        <br />
        <br />
        2. User Account. Though your linked Digital Wallet will function as a unique identifier for you in respect of
        your use of the Services, we may additionally require you to create an account in order to use certain portions
        of the Services (to the extent applicable, your “Account”). To the extent that we require you to create an
        Account, we will provide you with instructions on how to do so, and once created, how to access, edit, and
        update your Account. In your creation, editing, or updating of any such Account, you agree to provide us with
        accurate, complete, and updated information. You are solely responsible for any activity on your Account and for
        maintaining the confidentiality and security of your password. We are not liable for any acts or omissions by
        you in connection with your Account.
        <br />
        <br />
        3. License Grant.
      </Typography>
      <Typography sx={{ marginBottom: 2, paddingLeft: 8 }}>
        0. Subject to your compliance with these Terms, the Company hereby grants to you, a personal, worldwide,
        royalty-free, non-assignable, non-sublicensable, non-transferrable, and non-exclusive license to access and use
        the software provided to you as part of the Services (and, to the extent we offer you any software for download
        as part of the Services, to download, install, and execute a single copy of such software onto the equipment or
        device specified by us). This license has the sole purpose of enabling you to use and enjoy the benefit of the
        Services as provided by us, in the manner permitted by these Terms, and subject to the use restrictions
        described below. Your access and use of the Services may be interrupted from time to time for any of several
        reasons, including, without limitation, the malfunction of equipment, periodic updating, maintenance, or repair
        of the Services or other actions that Company, in its sole discretion, may elect to take.
        <br />
        <br />
        1. We understand that part of what makes our community so great is their interest in developing tools and
        services that are helpful for other users of our Services, such as guild directories or Game-related guides
        (“User Services”). This section sets out your permissions to use the Katana Inu name and logo (“Katana Inu
        Mark”) in connection with such User Services, to reproduce and display Katana Inu audiovisual assets, artwork,
        images for Game Items, and other Services-related content and content derived from the Services (“Content”) as
        part of your User Services. Subject to your compliance with these Terms, if you have developed a User Service
        that provides value to users of the Katana Inu Metaverse (as determined in our sole discretion), the Company
        hereby grants to you a personal, worldwide, royalty-free, non-assignable, non-sublicensable, non-transferrable,
        and non-exclusive license to use or reproduce the Katana Inu Mark but solely as needed to identify that your
        User Service is related to the Katana Inu Metaverse, and a right to reproduce Content such as images of ships
        and planets to be able to identify Content to users of User Services, and provided that in all cases, such uses:
        (i) are strictly for non-commercial and not-for-profit purposes; (ii) include a prominent disclaimer that your
        User Service is not affiliated with, or endorsed by, the Company in any way and otherwise ensure that you do not
        use the Katana Inu Mark or Content in a manner that is likely to cause confusion, deception, or mistake about
        the source of the User Service; and (iii) comply in all respects with any quality control guidelines that we may
        implement from time to time in our sole discretion. Notwithstanding the foregoing, we may at any time and in our
        sole discretion revoke the license granted in the preceding sentence and require that you discontinue any use or
        reproduction of any of our Katana Inu Marks, Content, copyrights, trademarks, service marks, trade names,
        slogans, logos, images, or other proprietary notations, and you agree to immediately comply with such
        requirement.
        <br />
        <br />
        2. When you purchase a Game NFT, you own the NFT as a token on the blockchain, but you do not own any
        intellectual property rights in the related Content associated with such NFT except for the license grants
        expressly set forth herein. If you purchase or otherwise lawfully acquire a Game NFT, then subject to your
        compliance with these Terms, the Company hereby grants you a worldwide, non-exclusive, non-transferable,
        royalty-free license to use, copy, and display the Content that is specifically associated with such purchased
        Game NFT, and solely for the following purposes: (a) for your own non-commercial and not-for-profit use within
        or related to the Services and not otherwise in conflict with these Terms or any other Services-related policies
        (e.g., not to create and sell merchandise related to such Content); (b) as part of the Marketplace and any other
        marketplace that permits the purchase and sale of your Game NFTs, provided that the marketplace
        cryptographically verifies that only the actual owner can display the Content in connection with their sale of
        their Game NFT; or (c) as part of a third-party website or application that permits the display, inclusion,
        involvement, or participation of your Game NFT, provided that the website/application cryptographically verifies
        each Game NFT’s ownership of the Game NFT to ensure that only the actual owner can display the Content, and
        provided that the Content is no longer visible once the owner of the Game NFT leaves the website/application.
        This license only lasts as long as you are the valid owner and holder of the Game NFT associated with the
        licensed Content. If you sell or transfer the Game NFT to another person, this license will transfer to such
        other owner or holder of the Game NFT, and you will no longer have the benefits of such license. All rights not
        expressly granted are reserved.
      </Typography>
      <Typography sx={{ marginBottom: 2 }}>
        4. Restrictions On Your Use of the Services. You may not do, or assist others in doing, any of the following,
        unless applicable laws or regulations prohibit these restrictions or you have our written permission to do so:
      </Typography>
      <Typography sx={{ marginBottom: 2, paddingLeft: 8 }}>
        0. Subject to your compliance with these Terms, the Company hereby grants to you, a personal, worldwide,
        royalty-free, non-assignable, non-sublicensable, non-transferrable, and non-exclusive license to access and use
        the software provided to you as part of the Services (and, to the extent we offer you any software for download
        as part of the Services, to download, install, and execute a single copy of such software onto the equipment or
        device specified by us). This license has the sole purpose of enabling you to use and enjoy the benefit of the
        Services as provided by us, in the manner permitted by these Terms, and subject to the use restrictions
        described below. Your access and use of the Services may be interrupted from time to time for any of several
        reasons, including, without limitation, the malfunction of equipment, periodic updating, maintenance, or repair
        of the Services or other actions that Company, in its sole discretion, may elect to take.
        <br />
        <br />
        0. download, modify, copy, distribute, transmit, translate, display, perform, reproduce, duplicate, publish,
        license, create derivative works from, or offer for sale any Content or other materials or information contained
        on, or obtained from or through, the Services, provided, however, that such activities are permitted strictly
        for non-commercial and not-for-profit uses;
        <br />
        <br />
        1. duplicate, decompile, reverse engineer, disassemble, decode or derive source code from the Services
        (including any underlying idea or algorithm), or attempt to do any of the same, except if we have permitted you
        to do so (such as under an open source license we have granted);
        <br />
        <br />
        2. use, reproduce, or remove any copyright, trademark, service mark, trade name, slogan, logo, image, or other
        proprietary notation displayed by Company on or through the Services, including to promote, advertise, market,
        or sell any third-party product or service or merchandise, or for any other purpose not expressly authorized by
        Section 3.3(b) above;
        <br /> <br />
        3. use, offer, promote, advertise, make available, and/or distribute the following or assist therein:
      </Typography>
      <Typography sx={{ marginBottom: 2, paddingLeft: 16 }}>
        0. cheats (e.g., methods, not expressly authorized by the Company to influence and/or facilitate the gameplay in
        an unfair manner, including exploits of any in-Game bugs, and thereby granting you and/or any other user an
        advantage over other players not using such methods),
        <br /> <br />
        1. automation software (bots) (i.e., any code and/or software, not expressly authorized by the Company, that
        allows the automated control of a Game or any other feature of the Services, e.g., the automated control of a
        character in a Game),
        <br /> <br />
        2. hacks (i.e., accessing or modifying the software of the Services in any manner not expressly authorized by
        the Company),
        <br /> <br />
        3. modifications (mods) not expressly authorized by the Company, or
        <br /> <br />
        4. any other code and/or software, not expressly authorized by the Company, including third-party software, that
        can be used in connection with the Services and/or any component or feature thereof which changes and/or
        facilitates the gameplay or other functionality;
      </Typography>
      <Typography sx={{ marginBottom: 2, paddingLeft: 8 }}>
        4. engage in any manipulative activity that violates the integrity of the prices of assets on the Marketplace,
        including (but not limited to) the manipulative tactics commonly known as spoofing and wash trading;
        <br /> <br />
        5. access or use the Services in any manner that could disable, overburden, damage, disrupt, or impair the
        Services or interfere with any other party’s access to or use of the Services or use any device, software, or
        routine that causes the same;
        <br /> <br />
        6. attempt to gain unauthorized access to, interfere with, damage, or disrupt the Services, accounts registered
        to other users, or the computer systems or networks connected to the Services;
        <br /> <br />
        7. circumvent, remove, alter, deactivate, degrade, or thwart any technological measure or content protections of
        the Services;
        <br /> <br />
        8. use any unauthorized robot, spider, crawlers, or other automatic device, process, software, or queries that
        intercepts, “mines,” scrapes, or otherwise accesses the Services to monitor, extract, copy, or collect
        information or data from or through the Services, or engage in any manual process to do the same, provided,
        however, that the Company may, at its sole and absolute discretion, allow the use of certain third-party user
        interfaces;
        <br /> <br />
        9. exploit a design flaw, undocumented problem, or program bug in the Services, including but not limited to
        creating, using, or transacting with any in-Game item created or copied by such exploit;
        <br /> <br />
        10. host, provide, or develop matchmaking services for the Game(s), or intercept, emulate, or redirect the
        communication protocols used by the Company in any way, for any purpose, including without limitation
        unauthorized play over the internet, network play (except as expressly authorized by the Company), or as part of
        content aggregation networks;
        <br /> <br />
        11. facilitate, create, or maintain any unauthorized connection to the Services including without limitation (i)
        any connection to any unauthorized server that emulates, or attempts to emulate, the Services; and (ii) any
        connection using third-party programs or tools not expressly authorized by the Company;
        <br /> <br />
        12. allow another party/person/entity access to your account information or game session or otherwise share your
        account;
        <br /> <br />
        13.attempt to sell, sublicense, rent, lease, grant a security interest in, or otherwise transfer any copy of the
        Services, any component thereof, or your rights to the Services to any other party in any way not expressly
        authorized herein;
        <br /> <br />
        14. introduce any viruses, trojan horses, worms, logic bombs, or other materials that are malicious or
        technologically harmful into our systems;
        <br /> <br />
        15. use the Services for illegal, harassing, unethical, or disruptive purposes, including engaging in any
        conduct intended to disrupt or diminish the game experience for other players or disrupt operation of the
        Company’s Services in any way, including:
      </Typography>
      <Typography sx={{ marginBottom: 2, paddingLeft: 16 }}>
        0. disrupting or assisting in the disruption of any computer used to support the Services or any Game
        environment, or
        <br /> <br />
        1. harassment, “griefing,” abusive behavior or chat, conduct intended to unreasonably undermine or disrupt the
        Game experiences of others, deliberate inactivity, or disconnecting;
      </Typography>
      <Typography sx={{ marginBottom: 2, paddingLeft: 8 }}>
        16. use the Services in such a way as to infringe the privacy, intellectual property rights, or other rights of
        third parties;
        <br /> <br />
        17. violate any applicable law or regulation in connection with your access to or use of the Services; or
        <br /> <br />
        18. access or use the Services in any way not expressly permitted by these Terms.
      </Typography>
      <Typography sx={{ marginBottom: 2 }}>
        5. Use of the Services. You are responsible for providing the computer, mobile device, wireless service plan,
        software, Internet connections, and/or other equipment or services that you need to download, install, and use
        any aspect of the Services. We do not guarantee that any aspect of the Services can be accessed and used on any
        particular device or with any particular service plan. We do not guarantee that any aspect of the Services or
        will be available in, or that orders for Offerings can be placed from, any particular geographic location.
        <br /> <br />
        6. Monitoring.
      </Typography>
      <Typography sx={{ marginBottom: 2, paddingLeft: 8 }}>
        0. WHILE RUNNING, THE SERVICES (INCLUDING THE GAME) AND COMPANY MAY MONITOR YOUR COMPUTER OR MOBILE DEVICE’S
        MEMORY FOR UNAUTHORIZED THIRD-PARTY PROGRAMS RUNNING EITHER CONCURRENTLY WITH A GAME OR OUT OF PROCESS. AN
        “UNAUTHORIZED THIRD-PARTY PROGRAM” AS USED HEREIN SHALL BE DEFINED AS ANY THIRD-PARTY SOFTWARE PROHIBITED BY THE
        “RESTRICTIONS ON YOUR USE OF THE SERVICES” SECTION ABOVE. IN THE EVENT THAT THE SERVICES DETECT AN UNAUTHORIZED
        THIRD-PARTY PROGRAM (a) THE SERVICES MAY COMMUNICATE INFORMATION BACK TO THE COMPANY, INCLUDING WITHOUT
        LIMITATION YOUR ACCOUNT NAME, WALLET ADDRESS, DETAILS ABOUT THE UNAUTHORIZED THIRD-PARTY PROGRAM DETECTED, AND
        THE TIME AND DATE; AND/OR (b) THE COMPANY MAY EXERCISE ANY OR ALL OF ITS RIGHTS UNDER THESE TERMS, WITH OR
        WITHOUT PRIOR NOTICE TO YOU.
        <br /> <br />
        1. Additionally, the Services may include a tool that will allow your computer system to forward information to
        the Company in the event of a crash of any aspect of the Services, including system and driver data, and by
        agreeing hereto you consent to the Company receiving and/or using this data.
        <br /> <br />
        2. If the Services detect the use of an Unauthorized Third-Party Program, your access to the Services may be
        terminated with or without additional notice to you.
      </Typography>
      <Typography variant="h4" sx={{ marginBottom: 4 }}>
        4. Ownership and content
      </Typography>
      <Typography sx={{ marginBottom: 2 }}>
        1. Ownership of the Services. The Services, including their “look and feel” (e.g., text, graphics, user
        interface, design, images, logos, icons, software), Content (including Game audiovisual assets and artwork),
        data, information, and other materials, are protected under copyright, trademark, and other intellectual
        property laws. You agree that the Company and/or its licensors own all right, title, and interest in and to the
        Services (including any and all intellectual property rights therein) and you agree not to take any action(s)
        inconsistent with such ownership interests. We and our licensors reserve all rights in connection with the
        Services and its content (other than Your Content, defined below), including, without limitation, the exclusive
        right to create derivative works.
        <br />
        <br />
        2. Ownership of Trademarks. The Company’s name, Katana Inu, and all related names, logos, product and service
        names, designs, and slogans are trademarks of the Company or its affiliates or licensors. Other names, logos,
        product and service names, designs, and slogans that appear on the Services are the property of their respective
        owners, who may or may not be affiliated with, connected to, or sponsored by us.
        <br />
        <br />
        3. Ownership of Feedback. We welcome feedback, bug reports, comments, and suggestions for improvements to the
        Services (“Feedback”). You acknowledge and expressly agree that any contribution of Feedback does not and will
        not give or grant you any right, title, or interest in the Services or in any such Feedback. All Feedback
        becomes the sole and exclusive property of the Company, and the Company may use and disclose Feedback in any
        manner and for any purpose whatsoever without further notice or compensation to you and without retention by you
        of any proprietary or other right or claim. You hereby assign to the Company any and all right, title, and
        interest (including, but not limited to, any intellectual property right) that you may have in and to any and
        all Feedback.
        <br />
        <br />
        4. Your Content License Grant. In connection with your use of the Services, you may be able to post, upload, or
        submit content to be made available through the Services (“Your Content”), such as user profiles, comments, user
        avatars, sound files, images, music files, game assets, and other user generated content. In order to operate
        the Services, we must obtain from you certain license rights in Your Content so that actions we take in
        operating the Services are not considered legal violations. Accordingly, by using the Services and uploading
        Your Content, you grant us a license to access, use, host, cache, store, reproduce, transmit, display, publish,
        distribute, and modify (for technical purposes, e.g., making sure content is viewable on smartphones as well as
        computers and other devices) Your Content to be able to operate and provide the Services. You agree that these
        rights and licenses are royalty free, transferable, sub-licensable, worldwide, and irrevocable (for so long as
        Your Content is stored with us), and include a right for us to make Your Content available to, and pass these
        rights along to, others with whom we have contractual relationships related to the provision of the Services,
        solely for the purpose of providing such Services, and to otherwise permit access to or disclose Your Content to
        third parties if we determine such access is necessary to comply with our legal obligations. As part of the
        foregoing license grant you agree that the other users of the Services shall have the right to comment on and/or
        tag Your Content and/or to use, publish, display, modify, or include a copy of Your Content as part of their own
        use of the Services; except that the foregoing shall not apply to any of Your Content that you post privately
        for non-public display on the Services. By posting or submitting Your Content through the Services, you
        represent and warrant that you have, or have obtained, all rights, licenses, consents, permissions, power,
        and/or authority necessary to grant the rights granted herein for Your Content. You agree that Your Content will
        not contain material subject to copyright or other proprietary rights, unless you have the necessary permission
        or are otherwise legally entitled to post the material and to grant us the license described above.
        <br />
        <br />
        <u>5. Notice of Infringement – DMCA Policy.</u>
        <br />
        <br />
        If you believe that any text, graphics, photos, audio, videos, or other materials or works uploaded, downloaded
        or appearing on the Services have been copied in a way that constitutes copyright infringement, you may submit a
        notification to our copyright agent in accordance with EUCD (European Copyright Directive) by providing the
        following information in writing:
      </Typography>
      <Typography sx={{ marginBottom: 2, paddingLeft: 8 }}>
        0. identification of the copyrighted work that is claimed to be infringed;
        <br />
        <br />
        1. identification of the allegedly infringing material that is requested to be removed, including a description
        of where it is located on the Services;
        <br />
        <br />
        2. information for our copyright agent to contact you, such as an address, telephone number, and e-mail address;
        <br />
        <br />
        3. a statement that you have a good faith belief that the identified, allegedly infringing use is not authorized
        by the copyright owners, its agent, or the law;
        <br />
        <br />
        4. a statement that the information above is accurate, and under penalty of perjury, that you are the copyright
        owner or the authorized person to act on behalf of the copyright owner; and
        <br />
        <br />
        5. he physical or electronic signature of a person authorized to act on behalf of the owner of the copyright or
        of an exclusive right that is allegedly infringed.
      </Typography>
      <Typography sx={{ marginBottom: 4 }}>
        <b>Notices of copyright infringement claims should be sent by mail to: </b>
        <br />
        ChainVision Games OÜ (16375989) Harju maakond, Tallinn, Lasnamäe linnaosa, Väike-Paala tn 2, 11415 or by e-mail
        to legal@katanainu.com. It is our policy, in appropriate circumstances and at our discretion, to disable or
        terminate the accounts of users who repeatedly infringe copyrights or intellectual property rights of others
        when using the Services.
      </Typography>
      <Typography variant="h4" sx={{ marginBottom: 2 }}>
        5. Third-party services and materials
      </Typography>
      <Typography sx={{ marginBottom: 2 }}>
        1. Use of Third-Party Materials in the Services. Certain Services may display, include, or make available
        content, data, information, applications, or materials from third parties (“Third-Party Materials ”) or provide
        links to certain third-party websites. By using the Services, you acknowledge and agree that the Company is not
        responsible for examining or evaluating the content, accuracy, completeness, availability, timeliness, validity,
        copyright compliance, legality, decency, quality, or any other aspect of such Third-Party Materials or websites.
        We do not warrant or endorse and do not assume and will not have any liability or responsibility to you or any
        other person for any third-party services, Third-Party Materials, or third-party websites, or for any other
        materials, products, or services of third parties. Third-Party Materials and links to other websites are
        provided solely as a convenience to you.
      </Typography>
      <Typography variant="h4" sx={{ marginBottom: 2 }}>
        6. Updates and patches
      </Typography>
      <Typography sx={{ marginBottom: 2 }}>
        1. The Company (or a third party on the Company’s behalf) may deploy or provide patches, updates, and
        modifications to the Services that must be installed for you to continue to use the Services. The Company may
        update the Services remotely, including, without limitation, any aspect of the Services residing on your
        computer or game machine, without your knowledge or consent, and you hereby grant to the Company your consent to
        deploy and apply such patches, updates, and modifications as the Company, in its sole discretion, deems
        necessary or advisable. You also agree that the Company may change, modify, suspend, “nerf,” “buff,”
        discontinue, or restrict your access to any features or parts (including but not limited to Game Items) of the
        Services at any time without notice or liability to you.
      </Typography>
      <Typography variant="h4" sx={{ marginBottom: 2 }}>
        7. Termination
      </Typography>
      <Typography sx={{ marginBottom: 2 }}>
        1. You acknowledge and agree that the Company, in its sole and absolute discretion, may (i) stop providing
        support for or access to the Services at any time, for any reason or no reason, and (ii) terminate your right to
        use the Services and terminate these Terms immediately at any time without notice or liability to you.
        <br />
        <br />
        In the event of termination of these Terms or your rights or license granted hereunder, you must (i) cease to
        use the Services; (ii) immediately and permanently remove from all of your devices all aspects of the Services
        in your possession and control. Upon termination of these Terms for any reason, all licenses granted herein
        immediately shall terminate.
      </Typography>
      <Typography variant="h4" sx={{ marginBottom: 2 }}>
        8. Disclaimers; limitations of liability; indemnification
      </Typography>
      <Typography sx={{ marginBottom: 2 }}>
        1. DISCLAIMERS. YOUR ACCESS TO AND USE OF THE SERVICES ARE AT YOUR OWN RISK. YOU UNDERSTAND AND AGREE THAT THE
        SERVICES ARE PROVIDED TO YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS. WITHOUT LIMITING THE FOREGOING, TO THE
        MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, THE COMPANY, ITS PARENTS, AFFILIATES, RELATED COMPANIES,
        OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, REPRESENTATIVES, PARTNERS, AND LICENSORS (THE “COMPANY ENTITIES”)
        DISCLAIM ALL WARRANTIES AND CONDITIONS, WHETHER EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
        PURPOSE, AND NON-INFRINGEMENT. THE COMPANY ENTITIES MAKE NO WARRANTY OR REPRESENTATION AND DISCLAIM ALL
        RESPONSIBILITY AND LIABILITY FOR: (A) THE COMPLETENESS, ACCURACY, AVAILABILITY, TIMELINESS, SECURITY, OR
        RELIABILITY OF THE SERVICES; (B) ANY HARM TO YOUR COMPUTER SYSTEM, LOSS OF DATA, OR OTHER HARM THAT RESULTS FROM
        YOUR ACCESS TO OR USE OF THE SERVICES; (C) THE OPERATION OR COMPATIBILITY WITH ANY OTHER APPLICATION OR ANY
        PARTICULAR SYSTEM OR DEVICE; (D) WHETHER THE SERVICES WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE ON AN
        UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS; AND (E) THE DELETION OF, OR THE FAILURE TO STORE OR TRANSMIT, YOUR
        CONTENT, DATA, AND OTHER COMMUNICATIONS MAINTAINED BY THE SERVICES. NO ADVICE OR INFORMATION, WHETHER ORAL OR
        WRITTEN, OBTAINED FROM THE COMPANY ENTITIES OR THROUGH THE SERVICES, WILL CREATE ANY WARRANTY OR REPRESENTATION
        NOT EXPRESSLY MADE HEREIN. FOR CLARITY, WE HAVE NO CONTROL OVER AND MAKE NO GUARANTEES OR PROMISES WITH RESPECT
        TO THE FUNCTIONALITY OF THE ETHERIUM BLOCKCHAIN AND/OR THE EXECUTION OF SMART CONTRACTS AND/OR TRANSACTIONS
        THEREIN.
        <br />
        <br />
        2. LIMITATIONS OF LIABILITY. TO THE MAXIMUM EXTENT NOT PROHIBITED BY LAW, YOU AGREE THAT IN NO EVENT WILL THE
        COMPANY ENTITIES BE LIABLE FOR ANY INDIRECT, SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES
        (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, LOSS OF USE, DATA OR PROFITS,
        BUSINESS INTERRUPTION, OR ANY OTHER DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OR INABILITY TO USE
        THE SERVICES), HOWEVER CAUSED AND UNDER ANY THEORY OF LIABILITY, WHETHER UNDER THESE TERMS OR OTHERWISE ARISING
        IN ANY WAY IN CONNECTION WITH THE SERVICES OR THESE TERMS AND WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT
        (INCLUDING NEGLIGENCE OR OTHERWISE) EVEN IF THE COMPANY ENTITIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
        DAMAGE. THE COMPANY ENTITIES’ TOTAL LIABILITY TO YOU FOR ANY DAMAGES FINALLY AWARDED SHALL NOT EXCEED THE
        GREATER OF (I) THE AMOUNT YOU PAID THE COMPANY ENTITIES, IF ANY, IN THE PAST SIX (6) MONTHS FOR THE SERVICES (OR
        OFFERINGS PURCHASED ON THE SERVICES) GIVING RISE TO THE CLAIM, OR (II) $100 USD. THE FOREGOING LIMITATIONS WILL
        APPLY EVEN IF THE ABOVE STATED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
        <br />
        <br />
        3. Indemnification. By entering into these Terms and accessing or using the Services, you agree that you shall
        defend, indemnify, and hold the Company Entities harmless from and against any and all claims, costs, damages,
        losses, liabilities, and expenses (including attorneys’ fees and costs) incurred by the Company Entities arising
        out of or in connection with: (a) your violation or breach of any term of these Terms or any applicable law or
        regulation; (b) your violation of any rights of any third party; (c) your access to or use of the Services; (d)
        Your Content, or (e) your negligence or willful misconduct.
        <br />
        <br />
        4. Assumption of Risks. You acknowledge and agree that there are risks associated with purchasing and holding
        NFTs and using blockchain technology. These include, but are not limited to, risk of losing access to NFTs due
        to loss of a PIN, wallet keys, two-factor authentication devices, or log-in information, user error when
        transferring NFTs, custodial error or purchaser error, smart contract error, risk of blockchain attacks, risk of
        hacking and security weaknesses, risk of unfavorable regulatory intervention in one or more jurisdictions, risks
        related to token taxation, risk of personal information disclosure, risk of uninsured losses, unanticipated
        risks, and volatility risks. You acknowledge that you have obtained sufficient information to make an informed
        decision to purchase a Game NFT, including carefully reviewing the code of the smart contract and the NFT, and
        fully understand and accept the functions of the same. The regulatory regime governing blockchain technologies,
        cryptocurrencies, and tokens is uncertain, and new regulations or policies may materially adversely affect the
        development of the Services, and therefore the potential utility or value of your Game NFTs. Upgrades to any
        blockchain network or hard forks in such networks, or a change in how transactions are confirmed on such
        blockchain networks, may have unintended, adverse effects on all blockchains, including any that are related to
        your Game NFTs.
        <br />
        <br />
        5. Taxes. You are solely responsible to pay any and all sales, use, value-added, and other taxes, duties, and
        assessments (except taxes on our net income) now or hereafter claimed or imposed by any governmental authority
        associated with your use of the Services. Except for income taxes levied on us, you: (a) will be solely
        responsible for reporting any tax obligations when, if ever, such obligations arise as a result of your use of
        the Services or in relation to an Offering; (b) will pay or reimburse Company for all national, federal, state,
        local, or other taxes and assessments of any jurisdiction, including value added taxes and other taxes as may be
        required, and amounts levied in lieu thereof based on charges set, services performed, or payments made
        hereunder, as are now or hereafter may be imposed under the authority of any national, state, local, or any
        other taxing jurisdiction; and (c) will not be entitled to deduct the amount of any such taxes, duties, or
        assessments from payments (including transaction fees) made to us pursuant to these Terms. Neither these Terms
        nor any other communication from the Company constitutes tax advice, and users are solely responsible for
        determining what, if any, taxes apply to their interaction with Offerings and the Services.
        <br />
        <br />
        6. Force Majeure. We will not be liable or responsible to you, nor be deemed to have defaulted under or breached
        these Terms, for any failure or delay in fulfilling or performing any of our obligations under these Terms or in
        operating the Services, when and to the extent such failure or delay is caused by or results from any events
        beyond the Company’s ability to control, including acts of God, flood, fire, earthquake, epidemics, pandemics,
        quarantine restrictions, tsunami, explosion, war, invasion, hostilities (whether war is declared or not),
        terrorist threats or acts, riot or other civil unrest, government order, law, or action, embargoes or blockades,
        strikes, labor stoppages or slowdowns or other industrial disturbances, shortage of adequate or suitable
        Internet connectivity, telecommunication breakdown, shortage of adequate power or electricity, and other similar
        events beyond our control.
      </Typography>
      <Typography variant="h4" sx={{ marginBottom: 2 }}>
        9. Governing law; arbitration; class action waiver
      </Typography>
      <Typography sx={{ marginBottom: 2 }}>
        1.Governing Law. These Terms are governed by the laws of the State of Tallin, without regard to conflict of laws
        rules, and the proper venue for any disputes arising out of or relating to any of the same will be the
        arbitration venue set forth herein, or if arbitration does not apply, then the state and federal courts located
        in Tallin. You and the Company agree that the Estonia Convention on Contracts for the International Sale of
        Goods will not apply to the interpretation or construction of these Terms.
        <br />
        <br />
        2. Informal Process First. You agree that in the event of any dispute between you and the Company Entities, you
        will first contact the Company and make a good faith sustained effort to resolve the dispute before resorting to
        more formal means of resolution, including without limitation, any court action or arbitration.
        <br />
        <br />
        3. Arbitration Agreement and Class Action Waiver. After the informal dispute resolution process, any remaining
        dispute, controversy, or claim (collectively, “Claim”), whether based in contract, tort, statute, fraud, or any
        other legal theory, relating in any way to your use of the Company’s services and/or products, including the
        Services, will be resolved by arbitration, including the resolution of threshold questions as to the
        arbitrability of the Claim. YOU AND THE COMPANY AGREE THAT “Claim” AS DEFINED IN THESE TERMS SHALL NOT INCLUDE
        ANY CLAIM OR CAUSE OF ACTION FOR INTELLECTUAL PROPERTY INFRINGEMENT, MISAPPROPRIATION, DILUTION, OR OTHER MISUSE
        OF INTELLECTUAL PROPERTY RIGHTS. You and the Company agree that any Claim will be settled by final and binding
        arbitration, using the English language, administered by the The Court of Arbitration of the Estonian Chamber of
        Commerce and Industry under its Consumer Arbitration Rules (the “Rules”) then in effect (those rules are deemed
        to be incorporated by reference into this section, and as of the date of these Terms). Any arbitration under
        these Terms will take place on an individual basis in accordance with the Rules. Class arbitrations and class
        actions are not permitted. YOU UNDERSTAND THAT BY AGREEING TO THESE TERMS, YOU AND THE COMPANY ARE EACH WAIVING
        THE RIGHT TO TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION OR CLASS ARBITRATION. You and Company further
        agree: (i) to arbitrate all Claims between the parties pursuant to the provisions in these Terms; (ii) these
        Terms memorialize a transaction in interstate commerce; The arbitrator’s decision(s) shall be made pursuant to
        the Rules and will be final and binding. The arbitrator will have authority to award temporary, interim, or
        permanent injunctive relief or relief providing for specific performance of these Terms as permitted by the
        governing Tallin law and subject to the terms and limitations in these Terms. The award rendered by the
        arbitrator may be entered in any court having jurisdiction. Notwithstanding the foregoing agreement to
        arbitration:
      </Typography>
      <Typography sx={{ marginBottom: 2, paddingLeft: 8 }}>
        0. Injunctive Relief. You and the Company will have the right to bring an action in a court of proper
        jurisdiction for injunctive or other equitable or conservatory relief, pending a final decision by the
        arbitrator.
        <br />
        <br />
        1. Small Claims. Any Claim which is eligible for resolution in the small claims court (or its equivalent) of the
        jurisdiction in which the party bringing the claim is domiciled may be brought in such small claims court or its
        equivalent on an individual, non-representative, and non-class basis. If a party initiating such a Claim is
        domiciled outside of the Estonia or in a jurisdiction which does not have small claims courts or their
        equivalent, any claim for less than $5000 USD may be brought in a court of competent jurisdiction in the
        jurisdiction in which the party initiating the Claim is domiciled.
      </Typography>
      <Typography sx={{ marginBottom: 2 }}>
        4. Confidentiality. You and Company agree that any Claim that has been submitted to arbitration, and all related
        proceedings, including any settlement agreement, shall be kept confidential. In the event you or the Company
        file the arbitration award with any court of competent jurisdiction in order to have that award confirmed in a
        court order or judgment, or any other proceeding under the Federal Arbitration Act, then each party shall take
        measures to the extent permitted by applicable law to redact or file under seal any highly sensitive business or
        personal information that appear in the award and any other supporting documents filed in such a proceeding.
        <br />
        <br />
        5. Conduct of the Arbitration. Any arbitration will be conducted by a single, neutral arbitrator appointed in
        accordance with the Rules, except that, if the amount in dispute exceeds $10 million USD, the arbitration shall
        be conducted by a panel of three neutral arbitrators appointed in accordance with the Rules. The arbitration
        shall have its seat in Tallinn. The arbitrator will conduct hearings, if any, by videoconference (which you may
        join telephonically if you are unable to reasonably access or utilize videoconference technology), rather than
        by personal appearances, during ordinary business hours in the time zone in which the party initiating the
        arbitration resides. The arbitrator may direct that the hearing be held in person upon the request of any party
        if the arbitrator concludes that doing so would be appropriate under the circumstances. Any in-person
        appearances will be held at a location which is reasonably convenient to both parties with due consideration of
        their ability to travel and other pertinent circumstances. If the parties are unable to agree on a location,
        such determination should be made by the arbitrator. For the avoidance of doubt, the arbitration shall be deemed
        to take place in Tallinn, and any resulting award(s) will be rendered in Tallinn, regardless of the location or
        method of any hearings.
        <br />
        <br />
        6. Opt-Out. You have the right to opt-out and not be bound by the arbitration provisions set forth in these
        Terms by sending written notice of your decision to opt-out to legal@katanainu.com or by certified mail to the
        Estonian mailing address listed in the “How to Contact Us” section of these Terms. The notice must be sent to
        the Company within thirty (30) days of your registering to use the Services or agreeing to these Terms,
        whichever is earlier, otherwise you shall be bound to arbitrate disputes in accordance with these Terms. If you
        opt-out of these arbitration provisions, the Company also will not be bound by them.
      </Typography>
      <Typography variant="h4" sx={{ marginBottom: 2 }}>
        10. Additional provisions
      </Typography>
      <Typography sx={{ marginBottom: 2 }}>
        1. Updating These Terms. We may modify these Terms from time to time in which case we will update the “Last
        Revised On” date at the top of these Terms. If we make changes that are material, we will use reasonable efforts
        to attempt to notify you. However, it is your sole responsibility to review these Terms from time to time to
        view any such changes. The updated Terms will be effective as of the time of posting, or such later date as may
        be specified in the updated Terms. Your continued access or use of the Services after the modifications have
        become effective will be deemed your acceptance of the modified Terms.
        <br />
        <br />
        2. Termination of License and Your Account. If you breach any of the provisions of these Terms, all licenses
        granted by the Company will terminate automatically. Additionally, the Company may suspend, disable, or delete
        your Account and/or the Services (or any part of the foregoing) with or without notice, for any or no reason. If
        the Company deletes your Account for any suspected breach of these Terms by you, you are prohibited from
        re-registering for the Services under a different name or wallet address. In the event of Account deletion for
        any reason, the Company may, but is not obligated to, delete any of Your Content. The Company shall not be
        responsible for the deletion of or failure to delete Your Content. All sections which by their nature should
        survive the termination of these Terms shall continue in full force and effect subsequent to and notwithstanding
        any termination of these Terms by the Company or you. Termination will not limit any of the Company’s other
        rights or remedies at law or in equity.
        <br />
        <br />
        3. Injunctive Relief. You agree that a breach of these Terms will cause irreparable injury to the Company for
        which monetary damages would not be an adequate remedy and the Company shall be entitled to equitable relief in
        addition to any remedies it may have hereunder or at law without a bond, other security, or proof of damages. 4.
        Export Laws. You agree that you will not export or re-export, directly or indirectly, the Services and/or other
        information or materials provided by the Company hereunder, to any country for which the Estonia or any other
        relevant jurisdiction requires any export license or other governmental approval at the time of export without
        first obtaining such license or approval. In particular, but without limitation, the Services may not be
        exported or re-exported (a) into any Estonia embargoed countries or any country that has been designated by the
        Estonian Government as a “terrorist supporting” country, or (b) to anyone listed on any Estonia. Government list
        of prohibited or restricted parties, including the Estonia Treasury Department’s list of Specially Designated
        Nationals or the Estonia Department of Commerce Denied Person’s List or Entity List. You warrant and represent
        that you’re not located in, under the control of, or a national or resident of any embargoed country. By using
        the Services, you represent and warrant that you are not located in any such country or on any such list. You
        are responsible for and hereby agree to comply at your sole expense with all applicable Estonia export laws and
        regulations.
        <br />
        <br />
        5. Miscellaneous. If any provision of these Terms shall be unlawful, void, or for any reason unenforceable, then
        that provision shall be deemed severable from these Terms and shall not affect the validity and enforceability
        of any remaining provisions. These Terms and the licenses granted hereunder may be assigned by the Company but
        may not be assigned by you without the prior express written consent of the Company. No waiver by either party
        of any breach or default hereunder shall be deemed to be a waiver of any preceding or subsequent breach or
        default. The section headings used herein are for reference only and shall not be read to have any legal effect.
        The Services are operated by us in Estonia. Those who choose to access the Services from locations outside the
        Estonia do so at their own initiative and are responsible for compliance with applicable local laws.
        <br />
        <br />
        6. How to Contact Us. You may contact us regarding the Services or these Terms at: Chain Vision OU Harju
        maakond, Tallinn, Lasnamäe linnaosa, Väike-Paala tn 2, 11415 or by e-mail at support@katanainu.com.
      </Typography>
    </Box>
  );
};
