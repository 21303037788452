import { FC, SyntheticEvent } from 'react';
import { UploadFile } from '@mui/icons-material';
import { CardMedia, CardMediaProps } from '@mui/material';
import { pricingBackgroundImage } from 'assets/images';
import { CustomCardMediaProps, Proportions } from 'components/CustomCardMedia/CustomCardMedia.types';
import { NftFormat } from 'components/NftPreview/NftPreview.helper';
import { COLOR_NEUTRALS_4 } from 'theme/colors';

export const CustomCardMedia: FC<CustomCardMediaProps & CardMediaProps & { format?: NftFormat | string }> = ({
  width,
  proportions = Proportions.p1to1,
  src,
  alt = 'nftImage',
  className,
  title,
  format,
  ...cardMediaProps
}) => {
  const handleError = (event: SyntheticEvent<HTMLImageElement>): void => {
    // eslint-disable-next-line no-param-reassign
    event.currentTarget.src = pricingBackgroundImage;
  };
  const formatToMui = () => {
    switch (format) {
      case NftFormat.video:
        return 'video';

      default:
        return 'img';
    }
  };

  if (!src) {
    return <UploadFile sx={{ color: COLOR_NEUTRALS_4 }} />;
  }

  return (
    <CardMedia
      sx={{
        maxWidth: width,
        aspectRatio: `${proportions}`,
        ...cardMediaProps.sx,
      }}
      className={className}
      onError={handleError}
      component={formatToMui()}
      src={src}
      alt={alt}
      title={title}
      controls
      loop
    />
  );
};
